import { PreloadingStrategy, Route } from '@angular/router';

import { Observable, of  } from 'rxjs';


/**
 * Routes for the chunk parts
 * Used for preloading Modules
 */
export class AppRoutingPreloadModule implements PreloadingStrategy 
{
	/**
     * Load a Modules (chunk part)
     * 
     * @param {Route} Route of the Module
     * @param { Function } Callback function
     * @return {any}
     */
	preload(route: Route, load: Function): Observable<any> 
	{
		return route.data && route.data.preload ? load() : of(null);
	}
}