<div *ngIf="this.appService.IS_MOBILE == false" >
	<div class="kylii-popup-header" >
		<span i18n>Mot de passe oublié</span>
	</div>
	<div *ngIf="is_code_is_send == false" class="kylii-popup-content" class="content-list" >
		<td-message *ngIf="is_issue == true" i18n-label label="Erreur" i18n-sublabel sublabel="Il y a eu une erreur pour vous envoyer un nouveau mot de passe. Vérifier que le mail que vous avez renseigné est bien celui qui vous sert à vous connecter, si le problème pérdure contactez votre support." color="warn" icon="info"></td-message>
		<p i18n>
			Renseignez votre mail ci-dessous pour que nous puissions vous envoyer votre nouveau mot de passe.
		</p>
		<mat-grid-list cols="1" rowHeight="70px" [formGroup]="registerForm">
			<mat-grid-tile colspan="1" rowspan="1" >
				<mat-form-field color="accent" flex="90">
					<input i18n matInput type="email" i18n-placeholder placeholder="Votre mail"  [(ngModel)]="mail" formControlName="control_mail" (keyup)="on_keyup($event)">
					<mat-error i18n *ngIf="registerForm.hasError('email')">Ceci n'est pas un mail valide</mat-error>
				</mat-form-field>
			</mat-grid-tile>
		</mat-grid-list>
	</div>

	<div *ngIf="is_code_is_send == true" class="kylii-popup-content" class="content-list" >
		<td-message *ngIf="is_ok == true" i18n-label label="Confirmation" i18n-sublabel sublabel="Votre demande à été enregistré, vous allez bientôt recevoir un mail avec votre nouveau mot de passe. Vérifiez vos spam si vous ne le voyez pas" color="accent" icon="info"></td-message>
		<td-message *ngIf="is_issue == true" i18n-label label="Erreur" i18n-sublabel sublabel="Il y a eu une erreur pour vous envoyer un nouveau mot de passe, Vérifier que le code de confirmation est correct, si le problème pérdure contactez votre support." color="warn" icon="info"></td-message>
		<p *ngIf="is_ok == false" i18n>
			Nous venons de vous envoyer un code de confirmation à ce mail <span class="important-words">({{mail}})</span>. Il se peut que le mail soit arrivé dans votre boîte de spam.
			<br>
			<br>
			Veuillez renseigner le champ suivant avec le code que vous avez reçu.		
		</p>
		<mat-grid-list *ngIf="is_ok == false" cols="1" rowHeight="70px" [formGroup]="registerForm">
			<mat-grid-tile colspan="1" rowspan="1" >
				<mat-form-field color="accent" flex="90">
					<input i18n matInput i18n-placeholder placeholder="Votre code de confirmation"  [(ngModel)]="confirmation_code" formControlName="control_code" (keyup)="on_keyup($event)">
					<mat-error i18n *ngIf="registerForm.hasError('required')">Vous devez renseigner votre code de confirmation</mat-error>
				</mat-form-field>
			</mat-grid-tile>
		</mat-grid-list>
	</div>

	<mat-divider></mat-divider>
	<div class="kylii-popup-footer" >
		<button mat-button color="accent"  [disabled]="DISABLE_WHEN_SENDING || !registerForm.valid || is_ok == true" (click)="reset_password()" i18n>Envoyer un nouveau mot de passe</button>
		<button mat-button  (click)="onNoClick()" i18n>Fermer</button>
	</div>
</div>



<div *ngIf="this.appService.IS_MOBILE == true" class="popup-mobile" >
	<div class="kylii-popup-header-mobile" >
		<span i18n>Mot de passe oublié</span>
	</div>
	<div *ngIf="is_code_is_send == false" class="kylii-popup-content" class="content-list" >
		<td-message *ngIf="is_issue == true" i18n-label label="Erreur" i18n-sublabel sublabel="Il y a eu une erreur pour vous envoyer un nouveau mot de passe. Vérifier que le mail que vous avez renseigné est bien celui qui vous sert à vous connecter, si le problème pérdure contactez votre support." color="warn" icon="info"></td-message>
		<p i18n>
			Renseignez votre mail ci-dessus pour que nous puissions vous envoyer votre nouveau mot de passe.
		</p>
		<mat-grid-list cols="1" rowHeight="70px" [formGroup]="registerForm">
			<mat-grid-tile colspan="1" rowspan="1" >
				<mat-form-field color="accent" flex="90">
					<input i18n matInput type="email" i18n-placeholder placeholder="Votre mail"  [(ngModel)]="mail" formControlName="control_mail" (keyup)="on_keyup($event)">
					<mat-error i18n *ngIf="registerForm.hasError('email')">Ceci n'est pas un mail valide</mat-error>
				</mat-form-field>
			</mat-grid-tile>
		</mat-grid-list>
	</div>

	<div *ngIf="is_code_is_send == true" class="kylii-popup-content" class="content-list" >
		<td-message *ngIf="is_ok == true" i18n-label label="Confirmation" i18n-sublabel sublabel="Votre demande à été enregistré, vous allez bientôt recevoir un mail avec votre nouveau mot de passe. Vérifiez vos spam si vous ne le voyez pas" color="accent" icon="info"></td-message>
		<td-message *ngIf="is_issue == true" i18n-label label="Erreur" i18n-sublabel sublabel="Il y a eu une erreur pour vous envoyer un nouveau mot de passe, Vérifier que le code de confirmation est correct, si le problème pérdure contactez votre support." color="warn" icon="info"></td-message>
		<p *ngIf="is_ok == false" i18n>
			Nous venons de vous envoyer un code de confirmation à ce mail <span class="important-words">({{mail}})</span>. Il se peut que le mail soit arrivé dans votre boîte de spam.
			<br>
			<br>
			Veuillez renseigner le champ suivant avec le code que vous avez reçu.		
		</p>
		<mat-grid-list *ngIf="is_ok == false" cols="1" rowHeight="70px" [formGroup]="registerForm">
			<mat-grid-tile colspan="1" rowspan="1" >
				<mat-form-field color="accent" flex="90">
					<input i18n matInput i18n-placeholder placeholder="Votre code de confirmation"  [(ngModel)]="confirmation_code" formControlName="control_code" (keyup)="on_keyup($event)">
					<mat-error i18n *ngIf="registerForm.hasError('required')">Vous devez renseigner votre code de confirmation</mat-error>
				</mat-form-field>
			</mat-grid-tile>
		</mat-grid-list>
	</div>

	<mat-divider></mat-divider>
	<div class="kylii-popup-footer" >
		<button mat-button color="accent"  [disabled]="DISABLE_WHEN_SENDING || !registerForm.valid || is_ok == true" (click)="reset_password()" i18n>Envoyer un nouveau mot de passe</button>
		<button mat-button  (click)="onNoClick()" i18n>Fermer</button>
	</div>
</div>
