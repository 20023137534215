import { catchError, map } from 'rxjs/operators';

import { Injectable } 								from '@angular/core';
import { Observable } 								from 'rxjs';
import { Subject } 									from 'rxjs';
import { HttpClient, HttpHeaders } 					from '@angular/common/http';
import { Router }                					from '@angular/router';

import { AppService }       						from '../../../core/service/app.service';
import { CookieService }    						from '../../../core/service/cookie.service';

import { Module }									from '../../common/vo/module';
import { Language }									from '../../common/vo/language';
import { License } 									from '../vo/license';
import { LicenseZoneInfo }							from '../vo/license.zoneinfo';
import { LicenseInternetTimeSlot }					from '../vo/license.internet.time.slot';
import { LicenseSchedule } from '../vo/license.schedule';
import { LicenseScheduleDay } from '../vo/license.schedule.day';

/**
 * Common Service
 * 
 * Use by the site for all License's datas / communication
 */
@Injectable()
export class LicenseService 
{
	/**
	 * Constructor
	 * 
	 */
	constructor (
		private http: HttpClient,
		private router: Router,
		private appService: AppService,  
		private cookieService: CookieService
		) {}


	/*****************************************************************/
	//
	// List License 
	//
	/*****************************************************************/

	/**
	* List the licenses
	*
	* @param {number} id of the user
	* @return {License[]} List of licenses
	*/
	public list( user_id:number ):Promise<License[]>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiMarketAPI/license/license_list/'; 

		return this.http
		    .post(url, JSON.stringify({id:user_id}), {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__list(response)  )
		    .catch(this.handleError__list);
	}

	/**
	 * Response of list method
	 * 
	 * @param {Response} Response of the Web-Service
	 * @return {License[]} List of licenses
	 */
	private extractData__list(res: any) :License[]
	{
		let body = res;

		if( body.success == true )
		{
			let listLicense: License[] = new Array();

			for( let i:number = 0; i < body.result.length; i++ )
			{
				let license:License 				= new License();
				license.id 							= body.result[i].id;
				license.label 						= body.result[i].label;

				license.last_connexion 				= new Date(body.result[i].last_connexion);

				license.system_report  			  	= body.result[i].system_report;

				license.system_report_last_date 	= new Date( body.result[i].system_report_last_date );

				license.have_to_be_upgraded 	  	= body.result[i].have_to_be_upgraded;
				license.operating_system_version    = parseFloat(body.result[i].operating_system_version);

				license.module						= new Module();
				license.module.id					= body.result[i].module;

				license.enabled 					= body.result[i].enabled;
				license.use_kylii_program 			= body.result[i].use_kylii_program;

				if( license.enabled == true )
				{
					listLicense.push( license );
				}
			}

			return listLicense;
		}
		else
		{
			return new Array();
		}
	}

	/**
	 * Catch error of device_box_type_list method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__list(error: any): Promise<any> 
	{
		console.error('An error occurred when call WS license/list', error); 
    	return Promise.reject(error.message || error);
	}






	/*****************************************************************/
	//
	// Get a License 
	//
	/*****************************************************************/

	/**
	* Get a license
	*	
	* @param {number} License id
	* @return {License} License
	*/
	public get( id:number, user_id:number ):Promise<License>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiMarketAPI/license/license_get/'; 

		return this.http
		    .post(url, JSON.stringify({id:id,uid:user_id,utk: this.cookieService.get( 'KyliiMarket_User_token' )}), {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__get(response)  )
		    .catch(this.handleError__get);
	}

	/**
	 * Response of get method
	 * 
	 * @param {Response} Response of the Web-Service
	 * @return {License} License
	 */
	private extractData__get(res: any) :License
	{
		let body = res;

		if( body.success == true )
		{
			let license:License 				= new License();
			license.id 							= parseInt(body.result.id);
			license.label 						= body.result.label;
			license.use_kylii_program 			= body.result.use_kylii_program;
			
			license.module 						= new Module();
			license.module.id 					= parseInt( body.result.module );
			
			license.activation_date 			= new Date( body.result.activation_date );
			license.desactivation_date 			= new Date( body.result.desactivation_date );
			license.last_connexion 				= new Date( body.result.last_connexion );

			license.boot_hour = body.result.boot_hour;
			if( license.boot_hour == null || license.boot_hour == "NULL" )
			{
				license.boot_hour = "07:00";
			}

			license.halt_hour = body.result.halt_hour;
			if( license.halt_hour == null || license.halt_hour == "NULL" )
			{
				license.halt_hour = "23:59";
			}

			license.logo = "";

			if( body.result.logo )
			{
				license.logo = body.result.logo;
			}

			if( body.result.schedule )
			{
				license.schedule 	= new LicenseSchedule();
				license.schedule.id = parseInt( body.result.schedule );
			}
			
			license.internetTimeSlots = new Array();
			for( let i:number = 0; i < body.result.license_internet_time_slots.length; i++ )
			{
				let slot:LicenseInternetTimeSlot = new LicenseInternetTimeSlot();
				slot.id 	= parseInt( body.result.license_internet_time_slots[i].id );
				slot.start 	= this.convertTime( parseInt( body.result.license_internet_time_slots[i].start ) );
				slot.stop 	= this.convertTime( parseInt( body.result.license_internet_time_slots[i].end ) );
				license.internetTimeSlots.push( slot );
			}

			license.languages = new Array();
			if( body.result.languages )
			{
				for( let i:number = 0; i < body.result.license_languages.length; i++ )
				{
					let language:Language = new Language();
					language.id    = parseInt( body.result.license_languages[i].id );

					license.languages.push( language );
				}
			}

			if( body.result.operating_system_version )
			{
				license.operating_system_version = parseFloat( body.result.operating_system_version );
			}
			
			return license;
		}
		else
		{
			return null;
		}
	}

	/**
	 * Catch error of device_box_type_list method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__get(error: any): Promise<any> 
	{
		console.error('An error occurred when call WS license/get', error); 
    	return Promise.reject(error.message || error);
	}

	/**
	* Convert time in seconds to string
	*
	* @param {number} Time in seconds
	* @return {string} Formated time
	*/
	private convertTime( timeSeconds:number ):string
    {
        let timeH:number = Math.floor( timeSeconds / 3600 );
        let timeHS:string;
        if( timeH < 10 )
        {
            timeHS = "0" + timeH
        }
        else
        {
        	timeHS = "" + timeH
        }

        let timeM:number =  0;
        let timeMS:string;

        if( timeSeconds >= 60 )
        {
            timeMS = "" + Math.floor( (timeSeconds - ( timeH * 3600 )) /60 ).toString();
        }

        if( timeM < 10 )
        {
            timeMS = "0" + timeM
        }
        else
        {
        	timeMS = "" + timeM
        }
        

        var time  = timeHS + ":" + timeMS;
        return time;
    }



    /*****************************************************************/
	//
	// Update a License 
	//
	/*****************************************************************/

	/**
	 * Update a license
	 *
	 * @param {License} License to update
	 * @return {boolean} Success or not
	 */
	public update( license:License ):Promise<boolean>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiMarketAPI/license/license_put/'; 

		return this.http
		    .post(url, JSON.stringify( license ), {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__update(response)  )
		    .catch(this.handleError__update);
	}

	/**
	 * Response of update method
	 * 
	 * @param {Response} Response of the Web-Service
	 * @return {boolean} Success or not
	 */
	private extractData__update(res: any) :boolean
	{
		let body = res;

		if( body.status == "ok" || body.success == true )
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	/**
	 * Catch error of update method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__update(error: any): Promise<any> 
	{
		console.error('An error occurred when call WS license/put', error); 
    	return Promise.reject(error.message || error);
	}



	/*****************************************************************/
	//
	// Sync a License 
	//
	/*****************************************************************/

	/**
	 * Sync a license
	 *
	 * @param {License} License to update
	 * @return {number} Id of the command created
	 */
	public sync( license:License ):Promise<number>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiMarketAPI/license/license_sync/'; 

		return this.http
		    .post(url, JSON.stringify({id:license.id, last_command_id:license.last_command_id,token: this.cookieService.get( 'KyliiMarket_User_id' ) + "." + this.cookieService.get( 'KyliiMarket_User_token' )}), {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__sync(response)  )
		    .catch(this.handleError__sync);
	}

	/**
	 * Response of sync method
	 * 
	 * @param {Response} Response of the Web-Service
	 * @return {number} Id of the command created
	 */
	private extractData__sync(res: any) :number
	{
		let body = res;

		if( body.status == "ok" || body.success == true )
		{
			return parseInt(body.result);
		}
		else
		{
			return -1;
		}
	}

	/**
	 * Catch error of sync method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__sync(error: any): Promise<any> 
	{
		console.error('An error occurred when call WS license/sync', error); 
    	return Promise.reject(error.message || error);
	}



	/*****************************************************************/
	//
	// License schedule day
	//
	/*****************************************************************/


	/**
	* Get License schedule day
	*
	* @param {number} id
	* @return {LicenseScheduleDay} LicenseScheduleDay
	*/
	public get_schedule_day( id:number ):Promise<LicenseScheduleDay>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiSAAS_License/licenseScheduleDay/' + id + '/'; 

		return this.http
		    .get(url, {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__get_schedule_day(response)  )
		    .catch(this.handleError__get_schedule_day);
	}


	/**
	 * Response of get_schedule_day method
	 * 
	 * @param {any} Response of the Web-Service
	 * @return {any} Success or not
	 */
	private extractData__get_schedule_day(res: any) :LicenseScheduleDay
	{
		let body = res;

		let schedule_day:LicenseScheduleDay = new LicenseScheduleDay();

		schedule_day.id 				= parseInt( body.id );
		schedule_day.day 				= parseInt( body.day );
		schedule_day.boot_hour 			= body.boot_hour;
		schedule_day.halt_hour 			= body.halt_hour;
		schedule_day.enabled 			= body.enabled;
		schedule_day.is_schedule_active = body.isScheduleActive;

		return schedule_day;
	}

	/**
	 * Catch error of add_schedule_day method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__get_schedule_day(error: any): Promise<any> 
	{
		console.error('An error occurred when call WS KyliiSAAS_License/licenseScheduleDay/', error); 
    	return Promise.reject(error.message || error);
	}







	/**
	* Add License schedule day
	*
	* @param {LicenseScheduleDay} schedule_day
	* @param {Invoice} LicenseScheduleDay to add
	* @return {number} id LicenseScheduleDay created
	*/
	public add_schedule_day( schedule_day:LicenseScheduleDay ):Promise<number>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiSAAS_License/licenseScheduleDay/'; 

		let datas:any =
		(
			{
				day:schedule_day.day,
				boot_hour:schedule_day.boot_hour,
				halt_hour:schedule_day.halt_hour,
				enabled:schedule_day.enabled,
				isScheduleActive:schedule_day.is_schedule_active,
			}
		);

		return this.http
		    .post(url, JSON.stringify( datas ), {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__add_schedule_day(response)  )
		    .catch(this.handleError__add_schedule_day);
	}


	/**
	 * Response of add_schedule_day method
	 * 
	 * @param {any} Response of the Web-Service
	 * @return {any} Success or not
	 */
	private extractData__add_schedule_day(res: any) :number
	{
		let body = res;

		return parseInt( body.id );
	}

	/**
	 * Catch error of add_schedule_day method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__add_schedule_day(error: any): Promise<any> 
	{
		console.error('An error occurred when call WS KyliiSAAS_License/licenseScheduleDay/', error); 
    	return Promise.reject(error.message || error);
	}


	/**
	* Update License schedule day
	*
	* @param {LicenseScheduleDay} schedule_day
	* @param {Invoice} LicenseScheduleDay to update
	* @return {boolean} sucess or not
	*/
	public update_schedule_day( schedule_day:LicenseScheduleDay ):Promise<any>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiSAAS_License/licenseScheduleDay/' + schedule_day.id + "/"; 

		let datas:any =
		(
			{
				day:schedule_day.day,
				boot_hour:schedule_day.boot_hour,
				halt_hour:schedule_day.halt_hour,
				enabled:schedule_day.enabled,
				isScheduleActive:schedule_day.is_schedule_active,
			}
		);

		return this.http
		    .put(url, JSON.stringify( datas ), {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__update_schedule_day(response)  )
		    .catch(this.handleError__update_schedule_day);
	}


	/**
	 * Response of update_schedule_day method
	 * 
	 * @param {any} Response of the Web-Service
	 * @return {any} Success or not
	 */
	private extractData__update_schedule_day(res: any) :boolean
	{
		return true;
	}

	/**
	 * Catch error of update_schedule_day method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__update_schedule_day(error: any): Promise<any> 
	{
		console.error('An error occurred when call WS KyliiSAAS_License/licenseScheduleDay/', error); 
    	return Promise.reject(error.message || error);
	}










	/*****************************************************************/
	//
	// License schedule
	//
	/*****************************************************************/
	

	/**
	* Get License schedule
	*
	* @param {number} LicenseSchedule id
	* @return {LicenseSchedule} LicenseSchedule
	*/
	public get_schedule( id:number ):Promise<LicenseSchedule>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiSAAS_License/licenseSchedule/' + id + '/'; 

		return this.http
		    .get(url,{headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__get_schedule(response)  )
		    .catch(this.handleError__get_schedule);
	}


	/**
	 * Response of get_schedule method
	 * 
	 * @param {any} Response of the Web-Service
	 * @return {any} Success or not
	 */
	private extractData__get_schedule(res: any) :LicenseSchedule
	{
		let body = res;

		let schedule:LicenseSchedule = new LicenseSchedule();
		
		schedule.id = parseInt( body.id );

		if( body.monday )
		{
			schedule.monday 	= new LicenseScheduleDay();
			schedule.monday.id 	= parseInt( body.monday );
		}

		if( body.tuesday )
		{
			schedule.tuesday 	= new LicenseScheduleDay();
			schedule.tuesday.id = parseInt( body.tuesday );
		}

		if( body.wednesday )
		{
			schedule.wednesday 		= new LicenseScheduleDay();
			schedule.wednesday.id 	= parseInt( body.wednesday );
		}

		if( body.thursday )
		{
			schedule.thursday 		= new LicenseScheduleDay();
			schedule.thursday.id 	= parseInt( body.thursday );
		}

		if( body.friday )
		{
			schedule.friday 	= new LicenseScheduleDay();
			schedule.friday.id 	= parseInt( body.friday );
		}

		if( body.saturday )
		{
			schedule.saturday 		= new LicenseScheduleDay();
			schedule.saturday.id 	= parseInt( body.saturday );
		}

		if( body.sunday )
		{
			schedule.sunday 	= new LicenseScheduleDay();
			schedule.sunday.id 	= parseInt( body.sunday );
		}

		return schedule;
	}

	/**
	 * Catch error of get_schedule method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__get_schedule(error: any): Promise<any> 
	{
		console.error('An error occurred when call WS KyliiSAAS_License/licenseSchedule/', error); 
    	return Promise.reject(error.message || error);
	}





	/**
	* Add License schedule
	*
	* @param {LicenseSchedule} schedule
	* @param {Invoice} LicenseSchedule to add
	* @return {number} id LicenseSchedule created
	*/
	public add_schedule( schedule:LicenseSchedule ):Promise<number>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiSAAS_License/licenseSchedule/'; 

		let datas:any =
		(
			{
				monday:schedule.monday.id,
				tuesday:schedule.tuesday.id,
				wednesday:schedule.wednesday.id,
				thursday:schedule.thursday.id,
				friday:schedule.friday.id,
				saturday:schedule.saturday.id,
				sunday:schedule.sunday.id
			}
		);

		return this.http
		    .post(url, JSON.stringify( datas ), {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__add_schedule(response)  )
		    .catch(this.handleError__add_schedule);
	}


	/**
	 * Response of add_schedule method
	 * 
	 * @param {any} Response of the Web-Service
	 * @return {any} Success or not
	 */
	private extractData__add_schedule(res: any) :number
	{
		let body = res;

		return parseInt( body.id );
	}

	/**
	 * Catch error of add_schedule method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__add_schedule(error: any): Promise<any> 
	{
		console.error('An error occurred when call WS KyliiSAAS_License/licenseSchedule/', error); 
    	return Promise.reject(error.message || error);
	}


	/**
	* Update License schedule
	*
	* @param {LicenseSchedule} schedule
	* @param {Invoice} LicenseSchedule to update
	* @return {boolean} sucess or not
	*/
	public update_schedule( schedule:LicenseSchedule ):Promise<any>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiSAAS_License/licenseSchedule/' + schedule.id + "/"; 

		let datas:any =
		(
			{
				monday:schedule.monday.id,
				tuesday:schedule.tuesday.id,
				wednesday:schedule.wednesday.id,
				thursday:schedule.thursday.id,
				friday:schedule.friday.id,
				saturday:schedule.saturday.id,
				sunday:schedule.sunday.id
			}
		);

		return this.http
		    .put(url, JSON.stringify( datas ), {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__update_schedule(response)  )
		    .catch(this.handleError__update_schedule);
	}


	/**
	 * Response of update_schedule method
	 * 
	 * @param {any} Response of the Web-Service
	 * @return {any} Success or not
	 */
	private extractData__update_schedule(res: any) :boolean
	{
		return true;
	}

	/**
	 * Catch error of update_schedule method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__update_schedule(error: any): Promise<any> 
	{
		console.error('An error occurred when call WS KyliiSAAS_License/licenseSchedule/', error); 
    	return Promise.reject(error.message || error);
	}




	/**
	* Update License schedule
	*
	* @param {LicenseSchedule} schedule
	* @param {Invoice} LicenseSchedule to update
	* @return {boolean} sucess or not
	*/
	public update_schedule_license( license:License, schedule:LicenseSchedule ):Promise<any>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiSAAS_License/license/update_schedule/'; 

		let datas:any =
		(
			{
				license_id:license.id,
				schedule_id:schedule.id}
		);

		return this.http
		    .post(url, JSON.stringify( datas ), {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__update_schedule_license(response)  )
		    .catch(this.handleError__update_schedule_license);
	}


	/**
	 * Response of update_schedule_license method
	 * 
	 * @param {any} Response of the Web-Service
	 * @return {any} Success or not
	 */
	private extractData__update_schedule_license(res: any) :boolean
	{
		return true;
	}

	/**
	 * Catch error of update_schedule_license method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__update_schedule_license(error: any): Promise<any> 
	{
		console.error('An error occurred when call WS KyliiSAAS_License/license/update_schedule/', error); 
    	return Promise.reject(error.message || error);
	}
}
