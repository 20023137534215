import { catchError, map } from 'rxjs/operators';

import { Injectable } 								from '@angular/core';
import { Observable } 								from 'rxjs';
import { Subject } 									from 'rxjs';
import { HttpClient, HttpHeaders } 					from '@angular/common/http';
import { Router }                					from '@angular/router';

import { AppService }       						from '../../../core/service/app.service';
import { Upload }									from '../vo/Upload';

/**
 * Upload Service
 * 
 * Web-Service used for upload file to S3
 */
@Injectable()
export class UploadService 
{

	/**
	 * Constructor
	 * 
	 */
	constructor (
		private http: HttpClient,
		private router: Router,
		private appService: AppService, 
		) {}


	/*****************************************************************/
	//
	// Upload
	//
	/*****************************************************************/


	/**
	 * Call to upload a file in S3
	 * 
	 * @param {File} File to upload
	 * @param {string} Destination
	 * @param {string} New filename
	 * @param {number} Image width needed
	 * @param {number} Image height needed
	 * @return {Upload} Upload Value Object
	 */
	public upload( file:File, dest:string, newName:string, img_width:number, img_height:number ):Promise<Upload>
	{
		let f: File = file;
        let formData:FormData = new FormData();
        formData.append('files', f,f.name);
        formData.append('distFolder',dest);
       
        if( newName != null ) 		{ formData.append('newFileName',	newName); }
        if( img_width != null ) 	{ formData.append('img_width',		img_width.toString() ); }
        if( img_height != null ) 	{ formData.append('img_height',		img_height.toString() ); }


        let datas:any = 
			{
				files:f,
				filename:f.name,
				dist:dest,
				new_filename:newName,
				img_width:img_width,
				img_height:img_height
			}
		;

		let attributes = "?filename=" + f.name + "&dest=" + dest;
		if( newName != null ) 		{ attributes += "&new_filename=" + newName; }
		if( img_width != null ) 		{ attributes += "&img_width=" + img_width; }
		if( img_height != null ) 		{ attributes += "&img_height=" + img_height; }

		let headers = new HttpHeaders({'Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiSAAS/commons/Upload/upload_to_s3/' + attributes; 

		return this.http
		    .post(url, formData, {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__upload(response)  )
		    .catch(this.handleError__upload);
	}

	/**
	 * Response of upload method
	 * 
	 * @param {Response} Response of the Web-Service
	 * @return {Upload} Upload Value Object
	 */
	private extractData__upload(res: any) :Upload
	{
		let body = res;
		let upload: Upload = new Upload();

		if( body.success == true )
		{
			upload.success = true;
			upload.message = body.result;			
		}
		else
		{
			upload.success = false;
			upload.message = body.message;
		}

		return upload;
	}

	/**
	 * Catch error of upload method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__upload(error: any): Promise<any> 
	{
		console.error('An error occurred when call WS ' + this.appService.PATH_TO_UPLOAD + "index.php", error); 
    	return Promise.reject(error.message || error);
	}
}
	