import { Component, OnInit, Input,  Output, EventEmitter  }      from '@angular/core';
import { trigger, state, style, transition, animate}             from '@angular/animations';
import { TdDialogService }                                       from '@covalent/core';
import { UploadService }                                         from '../../upload/service/upload.service';  
import { BrowserModule }                                         from '@angular/platform-browser';
import { BrowserAnimationsModule }                               from '@angular/platform-browser/animations';
import { AppService }                                            from '../../../core/service/app.service';

/**
 * Save button - Component
 * 
 * Used to save any datas
 */
@Component({
   	moduleId: module.id,
  	selector: 'kylii-save',
  	templateUrl: 'save.component.html',
  	styleUrls: [ 'save.component.css' ],
    animations: [
        trigger('animateState', [
            state('normal', style({
                backgroundColor: '#323232'
            })),
            state('success', style({
                backgroundColor: '#00bb5c'
            })),
             state('fail', style({
                backgroundColor: '#d43c3c'
            })),
            transition('normal => success', animate('200ms ease-in')),
            transition('success => normal', animate('200ms ease-out')),
            transition('normal => fail', animate('200ms ease-in')),
            transition('fail => normal', animate('200ms ease-out'))
        ])
    ]
})
export class SaveComponent implements OnInit
{   
    /**
     * Send the click event to the parent component
     */
    @Output() onClick = new EventEmitter();

    /**
     * Get the label from HTML tag
     */
    @Input() label:string             = "Save";

    /**
     * Get the icon from HTML tag
     */
    @Input() icon:string              = "save";

    /**
     * Display the spinner
     */
    @Input() display_loading:boolean  = false;

    /**
     * Condition to disablded the component
     */
    @Input() disabled:string          = "";

    /**
     * State of the comppnent
     */
    public state = "normal";

    /**
     * Constructor
     * 
     */
    constructor
    (
        private _dialogService: TdDialogService,
        private appService: AppService,
    ) {}



    /*******************************/
    //
    // Initialisation
    // 
    /*******************************/

    /**
     * Initialisation
     * 
     */
    ngOnInit(): void 
    {
    }


    /*******************************/
    //
    // Manage Spinner
    // 
    /*******************************/


    /**
     * Stop the loading spinner
     * 
     * @param {boolean} State ot the response success/fail
     */
    public stop_loading( display_success:boolean = false )
    {
       if( display_success == true )
       {
           this.state = "success";  
       }
       else
       {
          this.state = "fail";  
       }

       setTimeout(() => 
        {
            this.reset_loading();
           
        },
        1500);

        this.display_loading = false;
    }

    /**
     * Reset the state of the spinner
     * 
     */
    public reset_loading()
    {
        this.state = "normal";
    }

    /*******************************/
    //
    // Click
    // 
    /*******************************/
    
    /**
     * Called when user click on the button
     * 
     */
    public on_click(): void 
    {
        this.display_loading = true ;
        this.onClick.emit( this );
    }

}