<div *ngIf="this.appService.IS_MOBILE == false" >
	<div class="kylii-popup-header" >
		<div i18n>Informations</div>
	</div>
	<div class="kylii-popup-content" class="content-list" >
		<p i18n>Vous venez de modifier votre programme, il n'est donc plus associé à celui géré par Kylii-Kids.
		<br><br>
		Cela signifie que lorsque Kylii-Kids mettra à jour son programme en ajoutant/retirant des jeux, votre programme ne sera pas modifié. Vous restez donc seul gestionnaire de votre programme.
		<br>
		Si vous avez souscrit à un abonnement illimité, les nouveaux jeux apparaîtront dans votre bibliothèque et vous pourrez les ajouter à votre programme.
		<br><br>
		Vous pouvez à tout moment revenir au programme géré par Kylii-Kids en cliquant sur le bouton "Revenir au programme géré par Kylii-Kids" situé en haut à droite de la page Programme.
		</p>
	</div>
	<mat-divider></mat-divider>
	<div class="kylii-popup-footer" >
		<button mat-button color="accent" (click)="on_close()" ><div i18n>Fermer</div></button>
	</div>
</div>

<div *ngIf="this.appService.IS_MOBILE == true" class="popup-mobile" >
	<div class="kylii-popup-header-mobile" >
		<span i18n>Informations</span>
	</div>
	<div class="kylii-popup-content" class="content-list" >
		<p i18n>Vous venez de modifier votre programme, il n'est donc plus associé à celui géré par Kylii-Kids.
		<br><br>
		Cela signifie que lorsque Kylii-Kids mettra à jour son programme en ajoutant/retirant des jeux, votre programme ne sera pas modifié. Vous restez donc seul gestionnaire de votre programme.
		<br>
		Si vous avez souscrit à un abonnement illimité, les nouveaux jeux apparaîtront dans votre bibliothèque et vous pourrez les ajouter à votre programme.
		<br><br>
		Vous pouvez à tout moment revenir au programme géré par Kylii-Kids en cliquant sur le bouton "Revenir au programme géré par Kylii-Kids" situé en haut à droite de la page Programme.
		</p>
	</div>
	<mat-divider></mat-divider>
	<div class="kylii-popup-footer" >
		<button mat-button color="accent" (click)="on_close()" ><div i18n>Fermer</div></button>
	</div>
</div>
