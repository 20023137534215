/**
* License Internet Time Slot Value Object
*
* Interval of time when the license is allow to access to Internet
*/
export class LicenseInternetTimeSlot
{
	/**
	* id
	*/
	id:number;

	/**
	* Start hout
	*/
	start:string;

	/**
	* Stop hour
	*/
	stop:string;
}