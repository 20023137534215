import { LicenseScheduleDay } from "./license.schedule.day";

/**
* License Schedule Value Object
*
*/
export class LicenseSchedule
{
	/**
	* id
	*/
	id:number;

    /**
	* monday
	*/
    monday:LicenseScheduleDay;
    
    /**
	* tuesday
	*/
    tuesday:LicenseScheduleDay;
    
    /**
	* wednesday
	*/
    wednesday:LicenseScheduleDay;
    
    /**
	* thursday
	*/
    thursday:LicenseScheduleDay;
    
    /**
	* friday
	*/
    friday:LicenseScheduleDay;
    
    /**
	* saturday
	*/
    saturday :LicenseScheduleDay;
    
    /**
	* sunday
	*/
    sunday:LicenseScheduleDay;
}