import { catchError, map } 							from 'rxjs/operators';

import { Injectable } 								from '@angular/core';
import { Observable } 								from 'rxjs';
import { Subject } 									from 'rxjs';
import { HttpClient, HttpHeaders } 					from '@angular/common/http';
import { Router }                					from '@angular/router';

import { AppService }       						from '../../../core/service/app.service';
import { CookieService }    						from '../../../core/service/cookie.service';

import { Module }									from '../../common/vo/module';
import { Language }									from '../../common/vo/language';

import { LicenseContent } 							from '../vo/license.content';
import { ApplicationDetails }						from '../vo/application.details';
import { ApplicationPackage }						from '../vo/application.package';
import { ApplicationPackageType }					from '../vo/application.package.type';
import { ApplicationTime }							from '../vo/application.time'; 
import { Application }								from '../vo/application';

/**
 * Software Service
 * 
 * Use by the site for all Software's datas / communication
 */
@Injectable()
export class SoftwareService 
{
	/**
	 * Constructor
	 * 
	 */
	constructor (
		private http: HttpClient,
		private router: Router,
		private appService: AppService,  
		private cookieService: CookieService
		) {}


	/*****************************************************************/
	//
	// List Application's time
	//
	/*****************************************************************/

	/**
	 * Get the list of Application's times
	 *
	 * @return {ApplicationTime[]} Application's times list
	 */
	public list_time( ):Promise<ApplicationTime[]>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiSAAS_Software/applicationTime/'; 
		return this.http
		    .get(url, {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__list_time(response)  )
		    .catch(this.handleError__list_time);
	}

	/**
	 * Response of list_time method
	 * 
	 * @param {Response} Response of the Web-Service
	 * @return {ApplicationTime[]} Application's times list
	 */
	private extractData__list_time(res: any) :ApplicationTime[]
	{
		let body = res;

		let listApplicationsTime: ApplicationTime[] = new Array();

		for( let i:number = 0; i < body.length; i++ )
		{
			let applicationTime:ApplicationTime 	= new ApplicationTime();
			applicationTime.id 					= body[i].id;
			applicationTime.label				= body[i].label;
			applicationTime.value				= body[i].value;

			listApplicationsTime.push( applicationTime );
		}

		return listApplicationsTime;
	}

	/**
	 * Catch error of list_time method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__list_time(error: any): Promise<any> 
	{
		console.error('An error occurred when call WS program/application/time/get', error); 
    	return Promise.reject(error.message || error);
	}




	/*****************************************************************/
	//
	// Get a program
	//
	/*****************************************************************/

	/**
	 * Get the list of Application's times
	 *
	 * @return {LicenseContent} Content of the license
	 */
	public get( id:number ):Promise<LicenseContent>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiMarketAPI/license/license_program_get/'; 

		return this.http
		    .post(url, JSON.stringify({id:id}), {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__get(response)  )
		    .catch(this.handleError__get);
	}

	/**
	 * Response of list_time method
	 * 
	 * @param {Response} Response of the Web-Service
	 * @return {LicenseContent} Content of the license
	 */
	private extractData__get(res: any) :LicenseContent
	{
		let body = res;

		if( body.success == true )
		{
			let license_content:LicenseContent = new LicenseContent();
			license_content.market_applications = new Array();
			license_content.program_applications = new Array();
			license_content.market_applications_packages = new Array();
			license_content.program_applications_packages = new Array();
			license_content.program = null;
			
			let use_kylii_program:boolean = body.result.use_kylii_program;
			let is_package:boolean = body.result.is_package;


			/**
			*
			* Market
			*
			*/
			if( body.result.program )
			{
				/*
				* Program Market Content
				*/
				for( let i:number = 0; i < body.result.program.market_content.length; i++ )
				{
					if( is_package == true )
					{
						if( body.result.program.market_content[i].application.enable  == true )
						{
							let pkg:ApplicationPackage 	= new ApplicationPackage();
							pkg.id 						= parseInt(body.result.program.market_content[i].application.id);
							pkg.module 					= new Module();
							pkg.module.id 				= parseInt( body.result.program.market_content[i].application.module );
							pkg.label 					= body.result.program.market_content[i].application.label;
							pkg.thumb 					= body.result.program.market_content[i].application.thumb;
							pkg.menu_thumb				= body.result.program.market_content[i].application.menu_thumb;
							pkg.menu_title				= body.result.program.market_content[i].application.menu_title;
							pkg.menu_background			= body.result.program.market_content[i].application.menu_background;
							pkg.sort 					= i;

							pkg.details 		= new Array();
							for( let j:number = 0; j < body.result.program.market_content[i].application.application_package_details.length; j++ )
							{
								let app_details:ApplicationDetails 	= new ApplicationDetails();
								app_details.language 			   	= new Language();
								app_details.language.id 			= parseInt( body.result.program.market_content[i].application.application_package_details[j].language_id );
								app_details.label					= body.result.program.market_content[i].application.application_package_details[j].label;
								app_details.description				= body.result.program.market_content[i].application.application_package_details[j].description;

								pkg.details.push( app_details );
							}

							pkg.applications = new Array();
							for( let j:number = 0; j < body.result.program.market_content[i].application.applications.length; j++ )
							{
								let app:Application = new Application();
								app.id 				= parseInt( body.result.program.market_content[i].application.applications[j].id );
								app.module 			= new Module();
								app.module.id 		= parseInt( body.result.program.market_content[i].application.applications[j].module );
								app.label 			= body.result.program.market_content[i].application.applications[j].label;
								app.thumb 			= body.result.program.market_content[i].application.applications[j].thumb;
								app.visual 			= body.result.program.market_content[i].application.applications[j].visual;
								app.sort 			= i;

								app.details 		= new Array();
								if(body.result.program.market_content[i].application.applications[j].application_details)
								{
									for( let k:number = 0; k < body.result.program.market_content[i].application.applications[j].application_details.length; k++ )
									{
										let app_details:ApplicationDetails 	= new ApplicationDetails();
										app_details.language 			   	= new Language();
										app_details.language.id 			= parseInt( body.result.program.market_content[i].application.applications[j].application_details[k].language );
										app_details.label					= body.result.program.market_content[i].application.applications[j].application_details[k].label;
										app_details.description				= body.result.program.market_content[i].application.applications[j].application_details[k].description;

										app.details.push( app_details );
									}
								}
								

								pkg.applications.push( app );
							}


							license_content.market_applications_packages.push( pkg );
						}
					}
					else
					{
						if( body.result.program.market_content[i].application.enable == true )
						{
							let app:Application = new Application();
							app.id 				= parseInt( body.result.program.market_content[i].application.id );
							app.module 			= new Module();
							app.module.id 		= parseInt( body.result.program.market_content[i].application.module );
							app.label 			= body.result.program.market_content[i].application.label;
							app.thumb 			= body.result.program.market_content[i].application.thumb;
							app.visual 			= body.result.program.market_content[i].application.visual;
							app.sort 			= i;

							app.details 		= new Array();
							for( let j:number = 0; j < body.result.program.market_content[i].application.application_details.length; j++ )
							{
								let app_details:ApplicationDetails 	= new ApplicationDetails();
								app_details.language 			   	= new Language();
								app_details.language.id 			= parseInt( body.result.program.market_content[i].application.application_details[j].language );
								app_details.label					= body.result.program.market_content[i].application.application_details[j].label;
								app_details.description				= body.result.program.market_content[i].application.application_details[j].description;

								app.details.push( app_details );
							}

							license_content.market_applications.push( app );
						}
					}
				}
			}
			else
			{
				/*
				* License Market Content
				*/
				for( let i:number = 0; i < body.result.market_content.length; i++ )
				{
					if( is_package == true )
					{
						if( body.result.market_content[i].application.enable  == true )
						{
							let pkg:ApplicationPackage 	= new ApplicationPackage();
							pkg.id 						= parseInt(body.result.market_content[i].application.id);
							pkg.module 					= new Module();
							pkg.module.id 				= parseInt( body.result.market_content[i].application.module );
							pkg.label 					= body.result.market_content[i].application.label;
							pkg.thumb 					= body.result.market_content[i].application.thumb;
							pkg.menu_thumb				= body.result.market_content[i].application.menu_thumb;
							pkg.menu_title				= body.result.market_content[i].application.menu_title;
							pkg.menu_background			= body.result.market_content[i].application.menu_background;
							pkg.sort 					= i;

							pkg.details 		= new Array();
							for( let j:number = 0; j < body.result.market_content[i].application.application_package_details.length; j++ )
							{
								let app_details:ApplicationDetails 	= new ApplicationDetails();
								app_details.language 			   	= new Language();
								app_details.language.id 			= parseInt( body.result.market_content[i].application.application_package_details[j].language_id );
								app_details.label					= body.result.market_content[i].application.application_package_details[j].label;
								app_details.description				= body.result.market_content[i].application.application_package_details[j].description;

								pkg.details.push( app_details );
							}

							pkg.applications = new Array();
							for( let j:number = 0; j < body.result.market_content[i].application.applications.length; j++ )
							{
								let app:Application = new Application();
								app.id 				= parseInt( body.result.market_content[i].application.applications[j].id );
								app.module 			= new Module();
								app.module.id 		= parseInt( body.result.market_content[i].application.applications[j].module );
								app.label 			= body.result.market_content[i].application.applications[j].label;
								app.thumb 			= body.result.market_content[i].application.applications[j].thumb;
								app.visual 			= body.result.market_content[i].application.applications[j].visual;
								app.sort 			= i;

								app.details 		= new Array();
								for( let k:number = 0; k < body.result.market_content[i].application.applications[j].application_details.length; k++ )
								{
									let app_details:ApplicationDetails 	= new ApplicationDetails();
									app_details.language 			   	= new Language();
									app_details.language.id 			= parseInt( body.result.market_content[i].application.applications[j].application_details[k].language );
									app_details.label					= body.result.market_content[i].application.applications[j].application_details[k].label;
									app_details.description				= body.result.market_content[i].application.applications[j].application_details[k].description;

									app.details.push( app_details );
								}

								pkg.applications.push( app );
							}

							
							license_content.market_applications_packages.push( pkg );
						}
					}
					else
					{
						if( body.result.market_content[i].application.enable == true )
						{
							let app:Application = new Application();
							app.id 				= parseInt( body.result.market_content[i].application.id );
							app.module 			= new Module();
							app.module.id 		= parseInt( body.result.market_content[i].application.module );
							app.label 			= body.result.market_content[i].application.label;
							app.thumb 			= body.result.market_content[i].application.thumb;
							app.visual 			= body.result.market_content[i].application.visual;
							app.sort 			= i;

							app.details 		= new Array();
							if(body.result.market_content[i].application.application_details)
							{
								for( let j:number = 0; j < body.result.market_content[i].application.application_details.length; j++ )
								{
									let app_details:ApplicationDetails 	= new ApplicationDetails();
									app_details.language 			   	= new Language();
									app_details.language.id 			= parseInt( body.result.market_content[i].application.application_details[j].language );
									app_details.label					= body.result.market_content[i].application.application_details[j].label;
									app_details.description				= body.result.market_content[i].application.application_details[j].description;

									app.details.push( app_details );
								}
							}
							

							license_content.market_applications.push( app );
						}
					}
				}
			}


			/**
			*
			* Content
			*
			*/
			if( use_kylii_program == true && body.result.program )
			{
				/*
				* Program Content
				*/
				for( let i:number = 0; i < body.result.program.content.length; i++ )
				{
					if( is_package == true )
					{
						if( body.result.program.content[i].application.enable  == true )
						{
							let pkg:ApplicationPackage 	= new ApplicationPackage();
							pkg.id 						= parseInt(body.result.program.content[i].application.id);
							pkg.module 					= new Module();
							pkg.module.id 				= parseInt( body.result.program.content[i].application.module );
							pkg.label 					= body.result.program.content[i].application.label;
							pkg.thumb 					= body.result.program.content[i].application.thumb;
							pkg.menu_thumb				= body.result.program.content[i].application.menu_thumb;
							pkg.menu_title				= body.result.program.content[i].application.menu_title;
							pkg.menu_background			= body.result.program.content[i].application.menu_background;
							pkg.sort 					= i;

							pkg.details 		= new Array();
							for( let j:number = 0; j < body.result.program.content[i].application.application_package_details.length; j++ )
							{
								let app_details:ApplicationDetails 	= new ApplicationDetails();
								app_details.language 			   	= new Language();
								app_details.language.id 			= parseInt( body.result.program.content[i].application.application_package_details[j].language_id );
								app_details.label					= body.result.program.content[i].application.application_package_details[j].label;
								app_details.description				= body.result.program.content[i].application.application_package_details[j].description;

								pkg.details.push( app_details );
							}

							pkg.applications = new Array();
							
							license_content.program_applications_packages.push( pkg );
						}
					}
					else
					{
						if( body.result.program.content[i].application.enable == true )
						{
							let app:Application = new Application();
							app.id 				= parseInt( body.result.program.content[i].application.id );
							app.module 			= new Module();
							app.module.id 		= parseInt( body.result.program.content[i].application.module );
							app.time 			= new ApplicationTime();
							app.time.value 		= parseInt( body.result.program.content[i].time );
							app.label 			= body.result.program.content[i].application.label;
							app.thumb 			= body.result.program.content[i].application.thumb;
							app.visual 			= body.result.program.content[i].application.visual;
							app.sort 			= i;

							app.details 		= new Array();
							for( let j:number = 0; j < body.result.program.content[i].application.application_details.length; j++ )
							{
								let app_details:ApplicationDetails 	= new ApplicationDetails();
								app_details.language 			   	= new Language();
								app_details.language.id 			= parseInt( body.result.program.content[i].application.application_details[j].language );
								app_details.label					= body.result.program.content[i].application.application_details[j].label;
								app_details.description				= body.result.program.content[i].application.application_details[j].description;

								app.details.push( app_details );
							}

							license_content.program_applications.push( app );
						}
					}
				}
			}
			else
			{
				/*
				* License Content
				*/
				for( let i:number = 0; i < body.result.content.length; i++ )
				{
					if( is_package == true )
					{
						if( body.result.content[i].application.enable  == true )
						{
							let pkg:ApplicationPackage 	= new ApplicationPackage();
							pkg.id 						= parseInt(body.result.content[i].application.id);
							pkg.module 					= new Module();
							pkg.module.id 				= parseInt( body.result.content[i].application.module );
							pkg.label 					= body.result.content[i].application.label;
							pkg.thumb 					= body.result.content[i].application.thumb;
							pkg.menu_thumb				= body.result.content[i].application.menu_thumb;
							pkg.menu_title				= body.result.content[i].application.menu_title;
							pkg.menu_background			= body.result.content[i].application.menu_background;
							pkg.sort 					= i;

							pkg.details 		= new Array();
							for( let j:number = 0; j < body.result.content[i].application.application_package_details.length; j++ )
							{
								let app_details:ApplicationDetails 	= new ApplicationDetails();
								app_details.language 			   	= new Language();
								app_details.language.id 			= parseInt( body.result.content[i].application.application_package_details[j].language_id );
								app_details.label					= body.result.content[i].application.application_package_details[j].label;
								app_details.description				= body.result.content[i].application.application_package_details[j].description;

								pkg.details.push( app_details );
							}

							pkg.applications = new Array();
							
							license_content.program_applications_packages.push( pkg );
						}
					}
					else
					{
						if( body.result.content[i].application.enable == true )
						{
							let app:Application = new Application();
							app.id 				= parseInt( body.result.content[i].application.id );
							app.module 			= new Module();
							app.module.id 		= parseInt( body.result.content[i].application.module );
							app.label 			= body.result.content[i].application.label;
							app.thumb 			= body.result.content[i].application.thumb;
							app.visual 			= body.result.content[i].application.visual;
							app.time 			= new ApplicationTime();
							app.time.value 		= parseInt( body.result.content[i].time );
							app.sort 			= i;

							app.details 		= new Array();

							if( body.result.content[i].application.application_details )
							{
								for( let j:number = 0; j < body.result.content[i].application.application_details.length; j++ )
								{
									let app_details:ApplicationDetails 	= new ApplicationDetails();
									app_details.language 			   	= new Language();
									app_details.language.id 			= parseInt( body.result.content[i].application.application_details[j].language );
									app_details.label					= body.result.content[i].application.application_details[j].label;
									app_details.description				= body.result.content[i].application.application_details[j].description;

									app.details.push( app_details );
								}
							}

							

							license_content.program_applications.push( app );
						}
					}
				}

			}


			/**
			*
			* Base Program
			*
			*/
			if( body.result.program )
			{
				license_content.program = new LicenseContent();

				license_content.program.market_applications = new Array();
				license_content.program.program_applications = new Array();
				license_content.program.market_applications_packages = new Array();
				license_content.program.program_applications_packages = new Array();

				/*
				* Program Content
				*/
				for( let i:number = 0; i < body.result.program.content.length; i++ )
				{
					if( is_package == true )
					{
						if( body.result.program.content[i].application.enable  == true )
						{
							let pkg:ApplicationPackage 	= new ApplicationPackage();
							pkg.id 						= parseInt(body.result.program.content[i].application.id);
							pkg.module 					= new Module();
							pkg.module.id 				= parseInt( body.result.program.content[i].application.module );
							pkg.label 					= body.result.program.content[i].application.label;
							pkg.thumb 					= body.result.program.content[i].application.thumb;
							pkg.menu_thumb				= body.result.program.content[i].application.menu_thumb;
							pkg.menu_title				= body.result.program.content[i].application.menu_title;
							pkg.menu_background			= body.result.program.content[i].application.menu_background;
							pkg.sort 					= i;

							pkg.details 		= new Array();
							for( let j:number = 0; j < body.result.program.content[i].application.application_package_details.length; j++ )
							{
								let app_details:ApplicationDetails 	= new ApplicationDetails();
								app_details.language 			   	= new Language();
								app_details.language.id 			= parseInt( body.result.program.content[i].application.application_package_details[j].language_id );
								app_details.label					= body.result.program.content[i].application.application_package_details[j].label;
								app_details.description				= body.result.program.content[i].application.application_package_details[j].description;

								pkg.details.push( app_details );
							}

							pkg.applications = new Array();
							
							license_content.program.program_applications_packages.push( pkg );
						}
					}
					else
					{
						if( body.result.program.content[i].application.enable == true )
						{
							let app:Application = new Application();
							app.id 				= parseInt( body.result.program.content[i].application.id );
							app.module 			= new Module();
							app.module.id 		= parseInt( body.result.program.content[i].application.module );
							app.time 			= new ApplicationTime();
							app.time.value 		= parseInt( body.result.program.content[i].time );
							app.label 			= body.result.program.content[i].application.label;
							app.thumb 			= body.result.program.content[i].application.thumb;
							app.visual 			= body.result.program.content[i].application.visual;
							app.sort 			= i;

							app.details 		= new Array();
							for( let j:number = 0; j < body.result.program.content[i].application.application_details.length; j++ )
							{
								let app_details:ApplicationDetails 	= new ApplicationDetails();
								app_details.language 			   	= new Language();
								app_details.language.id 			= parseInt( body.result.program.content[i].application.application_details[j].language );
								app_details.label					= body.result.program.content[i].application.application_details[j].label;
								app_details.description				= body.result.program.content[i].application.application_details[j].description;

								app.details.push( app_details );
							}

							license_content.program.program_applications.push( app );
						}
					}
				}
			}

			
			return license_content;
		}
		else
		{
			let license_content:LicenseContent = new LicenseContent();
			license_content.market_applications = new Array();
			license_content.program_applications = new Array();
			license_content.market_applications_packages = new Array();
			license_content.program_applications_packages = new Array();
			return license_content;
		}
	}

	/**
	 * Catch error of list_time method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__get(error: any): Promise<any> 
	{
		console.error('An error occurred when call WS program/get', error); 
    	return Promise.reject(error.message || error);
	}



	/*****************************************************************/
	//
	// Update a Program 
	//
	/*****************************************************************/

	/**
	 * Update a Program
	 *
	 * @param {number} License id
	 * @param {LicenseContent} Program to update
	 * @return {boolean} Success or not
	 */
	public update( license_id:number, license_content:LicenseContent ):Promise<boolean>
	{
		license_content.license_id = license_id;
		license_content.token = this.cookieService.get( 'KyliiMarket_User_id' ) + "." + this.cookieService.get( 'KyliiMarket_User_token' );
		
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiMarketAPI/license/license_program_put/'; 

		return this.http
		    .post(url, JSON.stringify( license_content ), {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__update(response)  )
		    .catch(this.handleError__update);
	}

	/**
	 * Response of update method
	 * 
	 * @param {Response} Response of the Web-Service
	 * @return {boolean} Success or not
	 */
	private extractData__update(res: any) :boolean
	{
		let body = res;

		if( body.status == "ok" || body.success == true )
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	/**
	 * Catch error of update method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__update(error: any): Promise<any> 
	{
		console.error('An error occurred when call WS program/put', error); 
    	return Promise.reject(error.message || error);
	}
}
