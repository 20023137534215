<div class="km-loading">
	<div class="km-loading-content">
		<!--
		<mat-card>
			<mat-card-title>
				Loading...
			</mat-card-title>
			<mat-card-content>
				<mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>	
			</mat-card-content>
		</mat-card>
		-->
		<div class="spinner">
			<div class="rect0"></div>
			<div class="rect1"></div>
			<div class="rect2"></div>
			<div class="rect3"></div>
			<div class="rect4"></div>
			<div class="rect5"></div>
		</div>
	</div>
</div>


