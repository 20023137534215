import { Component, OnInit, ViewContainerRef }             from '@angular/core';
import { Router }                                          from '@angular/router';
        
import { TdDataTableService }                              from '@covalent/core';
import { TdDataTableSortingOrder }                         from '@covalent/core';
import { ITdDataTableSortChangeEvent }                     from '@covalent/core';
import { ITdDataTableColumn }                              from '@covalent/core';
import { IPageChangeEvent }                                from '@covalent/core';
import { TdDialogService }                                 from '@covalent/core';
import { MatDialog }                                       from '@angular/material';
import { MatDialogRef }                                    from '@angular/material';
import { MAT_DIALOG_DATA}                                  from '@angular/material';
        
import { AppService }                                      from '../../../../core/service/app.service';
import { CookieService }                                   from '../../../../core/service/cookie.service';
import { LoadingService }                                  from '../../../../commons/loading/service/loading.service';
import { CommonService }                                   from '../../../common/service/common.service';
import { Module }                                          from '../../../common/vo/module';
import { LicenseService }                                  from '../../../license/service/license.service';
import { License }                                         from '../../../license/vo/license';
import { UserService }                                     from '../../../user/service/user.service';

import { LicensePopupSyncNowComponent }                    from '../popup/popup_sync_now/license.popup.sync.now.component';
import { LicensePopupConnexionInformationsComponent }      from '../popup/popup_connexion_informations/license.popup.connexion.informations.component';
import { LicensePopupSupportComponent }                    from '../popup/popup_support/license.popup.support.component';
/**
 * License List Component
 * 
 * List all license of a customer
 */
@Component({
   	moduleId: module.id,
  	selector: 'license-list',
  	templateUrl: 'license.list.component.html',
  	styleUrls: [ 'license.list.component.css' ]
})
export class LicenseListComponent implements OnInit {

    /**
    * Constructor
    * 
    */
    constructor
    (
        private loadingService: LoadingService,
        private _viewContainerRef: ViewContainerRef,
        private router: Router,
        private commonService:CommonService,
        private licenseService:LicenseService,
        private cookieService:CookieService,
        /**
        * Access to the Main App Service
        */
        public appService: AppService,  
        private userService:UserService,
        private dialog: MatDialog
    ) 
    { }


    /**
    * Index of the loading Component
    */
    private index_loading:number;

    /**
    * List of the modules
    */
    private modules:Module[] = new Array();

    /**
    * List of licenses
    */
    public licenses:License[] = new Array();

    /**
    * Column used to display the licenses List
    */
    public columns: ITdDataTableColumn[] = 
    [
        { name: 'label', label: 'Label' , width: 250  },
        { name: 'module.label', label: 'Product', width: 150  },
        { name: 'last_connexion', label: 'Last Connexion'  }
    ];

    /**
    * Dertermin which item is used to sort the Array - id by default
    */
    public sortBy: string  = 'label';

    /**
    * Determin the sort Order - Descending by default
    */
    public sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;

    /**
    * Initialisation
    * 
    */
    ngOnInit(): void 
    {
        this.index_loading = this.loadingService.open( this._viewContainerRef );

        this.commonService.module_list()
        .then
        (
            res =>
            {
                this.modules = res;

                this.licenseService.list( Number( this.cookieService.get("KyliiMarket_User_id") ) )
                .then
                (
                    res =>
                    {
                        this.licenses = res;

                        for( let i:number = 0; i < this.licenses.length; i++ )
                        {
                            this.licenses[i].connected = this.determin_if_connected( this.licenses[i] );

                            for( let j:number = 0; j < this.modules.length; j++ )
                            {
                                if( this.licenses[i].module.id == this.modules[j].id )
                                {
                                    this.licenses[i].module = this.modules[j];
                                    
                                    switch( this.licenses[i].module.id )
                                    {
                                        case 1:
                                            this.licenses[i].thumb = this.appService.LOGO_KYLII_AIR;
                                            this.licenses[i].background_color = this.appService.COLOR_KYLII_AIR;
                                            this.licenses[i].custom_class = "product-thumb-air";
                                            break;

                                        case 2:
                                            this.licenses[i].thumb = this.appService.LOGO_KYLII_FLOOR;
                                            this.licenses[i].background_color = this.appService.COLOR_KYLII_FLOOR;
                                            this.licenses[i].custom_class = "product-thumb-floor";
                                            break;

                                        case 3:
                                            this.licenses[i].thumb = this.appService.LOGO_KYLII_MEDIA;
                                            this.licenses[i].background_color = this.appService.COLOR_KYLII_MEDIA;
                                            this.licenses[i].custom_class = "product-thumb-media";
                                            break;

                                        case 4:
                                            this.licenses[i].thumb = this.appService.LOGO_KYLII_TOUCH;
                                            this.licenses[i].background_color = this.appService.COLOR_KYLII_TOUCH;
                                            this.licenses[i].custom_class = "product-thumb-touch";
                                            break;

                                        case 5:
                                            this.licenses[i].thumb = this.appService.LOGO_KYLII_TABLE;
                                            this.licenses[i].background_color = this.appService.COLOR_KYLII_TABLE;
                                            this.licenses[i].custom_class = "product-thumb-table";
                                            break;

                                        case 8:
                                            this.licenses[i].thumb = this.appService.LOGO_KYLII_VIEW;
                                            this.licenses[i].background_color = this.appService.COLOR_KYLII_VIEW;
                                            this.licenses[i].custom_class = "product-thumb-view";
                                            break;
                                        
                                        case 9:
                                            this.licenses[i].thumb = this.appService.LOGO_KYLII_QUEST;
                                            this.licenses[i].background_color = this.appService.COLOR_KYLII_QUEST;
                                            this.licenses[i].custom_class = "product-thumb-quest";
                                            break;

                                        case 10:
                                            this.licenses[i].thumb = this.appService.LOGO_KYLII_ROOM;
                                            this.licenses[i].background_color = this.appService.COLOR_KYLII_ROOM;
                                            this.licenses[i].custom_class = "product-thumb-room";
                                            break;

                                        case 11:
                                            this.licenses[i].thumb = this.appService.LOGO_KYLII_WALL;
                                            this.licenses[i].background_color = this.appService.COLOR_KYLII_WALL;
                                            this.licenses[i].custom_class = "product-thumb-wall";
                                            break;
                                        
                                        case 17:
                                            this.licenses[i].thumb = this.appService.LOGO_KYLII_VIEW_MONEY;
                                            this.licenses[i].background_color = this.appService.COLOR_KYLII_VIEW_MONEY;
                                            this.licenses[i].custom_class = "product-thumb-view-money";
                                            break;
                                    }

                                    break;
                                }
                            }
                        }

                        this.loadingService.close( this._viewContainerRef, this.index_loading );
                    }
                );
            }
        );
    }

    /**
    * Detmin if the license is connected or not
    *
    * @param {License} License to test
    * @return {boolean} connected or not
    */
    private determin_if_connected(license:License):boolean
    {    
        let result:boolean = true;

        if( license.last_connexion && license.last_connexion.getTime())
        {
            let delay:number = new Date().getTime() -  license.last_connexion.getTime();
            delay = delay / 1000 / 60;

            if( delay < 3600 )
            {
                result = true;
            }
            else
            {
                result = false;
            }
        }
        else
        {
            result = false;
        }

        return result;
    }


    /**
    * When the user click on is connection information
    *
    * @param {License} License to get infos
    */
    public on_click_connection( license:License )
    {
        let dialogRef = this.dialog.open
        (
            LicensePopupConnexionInformationsComponent, 
            {
                width:  '650px',
                data: { license: license }
            }
        );   
    }


    /**
    * When the user click to acces to his support
    *
    */
    public on_click_support()
    {
        let dialogRef = this.dialog.open
        (
            LicensePopupSupportComponent, 
            {
                width:  '950px'
            }
        );   
    }


    /**
    * Go to a page
    * 
    * @param {string} Route
    */
    public goto_page( route:string )
    {
        this.router.navigate( [ route ] );
    }
}