import { Injectable } 	from '@angular/core';


/**
 * Application Service
 * 
 * Contains Main constants
 */
@Injectable()
export class AppService 
{
	/**
	* Use New KyliiSAAS API
	*/
	USE_KYLIISAAS_API=true;

	/**
	* Authorization Token for KyliiSAAS API
	*/
	TOKEN = "90f61300eed7b844f7826e170abfcca6cc977843";

	/**
	 * Path to the Rest API
	 */
	PATH_TO_KYLII_API 	= "https://boxapi.kyliisaas.com:8080/";
	
	/**
	* BACK DOOR for ERP
	*/
	BACK_DOOR = false;

	/**
	 * Path to the Rest API
	 */
	PATH_TO_WEBSERVICE 	= "https://market.kylii-kids.com/KyliiMarketApi/index.php/?/";

	/**
	 * Path to the Web-Service used for uploading
	 */
	PATH_TO_UPLOAD 		= "https://market.kylii-kids.com/KyliiMarketApi/upload/";
	
	/**
	 * Route for the Dashboard
	 */
	DASHBOARD_PAGE 		= "/license/list";

	/**
	 * Route for the Login page
	 */
	LOGIN_PAGE			= "/login";

	/**
	 * Route for the Default pag
	 */
	DEFAULT_PAGE 		= this.DASHBOARD_PAGE;

	/**
	* Logo Kylii Air
	*/
	LOGO_KYLII_AIR = "assets/commons/air.png";

	/**
	* Color Kylii Air
	*/
	COLOR_KYLII_AIR = "rgba(237,82,118,1)";

	/**
	* Logo Kylii Floor
	*/
	LOGO_KYLII_FLOOR = "assets/commons/floor.png";

	/**
	* Color Kylii Floor
	*/
	COLOR_KYLII_FLOOR = "rgba(81,186,198,1)";

	/**
	* Logo Kylii Media
	*/
	LOGO_KYLII_MEDIA = "assets/commons/media.png";

	/**
	* Color Kylii Media
	*/
	COLOR_KYLII_MEDIA = "rgba(147,25,62,1)";

	/**
	* Logo Kylii Touch
	*/
	LOGO_KYLII_TOUCH = "assets/commons/touch.png";

	/**
	* Color Kylii Touch
	*/
	COLOR_KYLII_TOUCH = "rgba(244,159,33,1)";

	/**
	* Logo Kylii Table
	*/
	LOGO_KYLII_TABLE = "assets/commons/table.png";

	/**
	* Color Kylii Table
	*/
	COLOR_KYLII_TABLE = "rgba(244,159,33,1)";

	/**
	* Logo Kylii View
	*/
	LOGO_KYLII_VIEW = "assets/commons/view.png";

	/**
	* Color Kylii View
	*/
	COLOR_KYLII_VIEW = "rgba(249,194,4,1)";


	/**
	* Logo Kylii View money
	*/
	LOGO_KYLII_VIEW_MONEY = "assets/commons/view.png";

	/**
	* Color Kylii View money
	*/
	COLOR_KYLII_VIEW_MONEY = "rgba(249,194,4,1)";


	/**
	* Logo Kylii Quest
	*/
	LOGO_KYLII_QUEST = "assets/commons/quest.png";

	/**
	* Color Kylii Quest
	*/
	COLOR_KYLII_QUEST = "rgba(148,195,41,1)";


	/**
	* Logo Kylii Wall
	*/
	LOGO_KYLII_WALL = "assets/commons/wall.png";

	/**
	* Color Kylii Wall
	*/
	COLOR_KYLII_WALL = "rgba(147,25,62,1)";


	/**
	* Logo Kylii Room
	*/
	LOGO_KYLII_ROOM = "assets/commons/room.png";

	/**
	* Color Kylii Room
	*/
	COLOR_KYLII_ROOM = "rgba(0,47,53,1)";

	/**
	* Current language
	*/
	LANGUAGE_ID = 1;

	/**
	* Determin if the device is a desktop
	*/
	IS_DESKTOP = false;

	/**
	* Determin if the device is a mobile
	*/
	IS_MOBILE = false;

	/**
	* Determin if the device is a tablet
	*/
	IS_TABLET = false;
}
