/**
* License Schedule Day Value Object
*
*/
export class LicenseScheduleDay
{
	/**
	* id
	*/
	id:number;

    /**
     * label
     */
    label:string;

	/**
	* day
	*/
	day:number;

    /**
	* Boot hour
	*/
	boot_hour:string;

    /**
	* Halt hour
	*/
	halt_hour:string;

    /**
	* enabled
	*/
	enabled:boolean;

    /**
	* is_schedule_active
	*/
	is_schedule_active:boolean;
}