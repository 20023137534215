import { Application }					from './application';
import { ApplicationDetails } 			from './application.details';
import { ApplicationPackageType } 		from './application.package.type';
import { Module } 						from '../../common/vo/module';

/**
* Application Package Value Object
*
*/
export class ApplicationPackage
{
	/**
	* Application Package's id
	*/
	id:number;

	/**
	* Application Package's id temp
	*/
	id_temp:number;

	/**
	* Application Package's Module
	*/
	module:Module;

	/**
	* Application Package's Type
	*/
	type:ApplicationPackageType;

	/**
	* Application Package's Details
	*/
	details:ApplicationDetails[];

	/**
	* Current details
	*/
	current_details:ApplicationDetails;

	/**
	* Application Package's application's list
	*/
	applications:Application[];

	/**
	* Application Package's label
	*/
	label:string;

	/**
	* Application Package's description
	*/
	description:string;

	/**
	* Application Package's thumb
	*/
	thumb:string;

	/**
	* Application Package's menu thumb
	*/
	menu_thumb:string;

	/**
	* Application Package's menu title
	*/
	menu_title:string;

	/**
	* Application Package's menu background
	*/
	menu_background:string;

	/**
	* Application Package active or not
	*/
	enable:boolean;

	/**
	* Application Package's sort
	*/
	sort:number;

	/**
	* Total application package's count return by the Web-Service used to list applications
	*/
	packages_total_list:number;

	/**
	* Determin if Application Package is installed or not
	*/
	installed:boolean;
}