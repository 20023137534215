<mat-card class="login-card">
	<div class="mdl-grid">
		<img *ngIf="this.appService.IS_MOBILE == false" class="login-card-image" src="assets/commons/logo.png" >

		<mat-grid-list cols="2" rowHeight="70px">
			<mat-grid-tile colspan="2" rowspan="1" >
				<mat-form-field color="accent" flex="90">
					<input matInput i18n-placeholder  placeholder="Identifiant" [disabled]="DISABLE_WHEN_LOADING" [(ngModel)]="user_login" [ngModelOptions]="{standalone: true}"  (keyup)="on_keyup($event)">
				</mat-form-field>
			</mat-grid-tile>
			<mat-grid-tile colspan="2" rowspan="1" >
				<mat-form-field color="accent" flex="90">
					<input matInput  type="password" i18n-placeholder placeholder="Mot de passe" [disabled]="DISABLE_WHEN_LOADING" [(ngModel)]="user_password" [ngModelOptions]="{standalone: true}"  (keyup)="on_keyup($event)">
				</mat-form-field>
			</mat-grid-tile>
			<mat-grid-tile colspan="1" rowspan="1" >
				<button mat-button color="accent" (click)="login(true)" [disabled]="user_login == '' || user_password == ''" i18n>Se connecter</button>
			</mat-grid-tile>
			<mat-grid-tile colspan="1" rowspan="1" >
				<button mat-button (click)="reset_password()" i18n>Mot de passe oublié ?</button>
			</mat-grid-tile>
		</mat-grid-list>
	</div>
</mat-card>


