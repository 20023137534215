import { Injectable }          from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AppService }          from '../../../core/service/app.service';
import { UserService }    from '../service/user.service';

/**
 * AuthGuard
 * 
 * Service to check if an User is connected or not and Allow or not
 */
@Injectable()
export class AuthGuard implements CanActivate {

    /**
     * Constructor
     * 
     */
    constructor(private router: Router, private AppService:AppService, private UserService:UserService) { }

    /**
     * Check if an User can access to a page
     * 
     * @return {boolean} Allowed or not
     */
    canActivate() 
    {
        return this.UserService.isConnected()
            .then
            ( 
                res => 
                { 
                    if( res == true )
                    {
                        return true;
                    }
                    else
                    {
                        this.router.navigate([this.AppService.LOGIN_PAGE]);
                        return false;
                    }
                } 
            );
    }
}