import { Component, OnInit, ViewChild, enableProdMode }     from '@angular/core';
import { Inject, LOCALE_ID }                                from '@angular/core';
import { ViewContainerRef }                                 from '@angular/core';
import { Router, NavigationEnd, RouteConfigLoadStart  }     from '@angular/router';
import { ViewEncapsulation }                                from '@angular/core';
import { MatSnackBar }                                      from '@angular/material';
import { TdLayoutNavListComponent  }                        from '@covalent/core';
import { MatDialog }                                        from '@angular/material';
import { MatDialogRef }                                     from '@angular/material';
import { MAT_DIALOG_DATA}                                   from '@angular/material';

import { DeviceDetectorService }                            from 'ngx-device-detector';

import { AppService }                                       from './core/service/app.service';
import { CookieService }                                    from './core/service/cookie.service';
import { LoadingService }                                   from './commons/loading/service/loading.service';
import { UserService }                                      from './modules/user/service/user.service';
import { User }                                             from './modules/user/vo/user';
import { CommonService }                                    from './modules/common/service/common.service';
import { Language }                                         from './modules/common/vo/language';
import { LicensePopupSupportComponent }                     from './modules/license/component/popup/popup_support/license.popup.support.component';


/**
 * Enable Production Mode
 * 
 */
enableProdMode();

/**
 * The App Component
 * 
 * Its the base component which contains the HTML menu + sidebar + container
 */
@Component({
    moduleId: module.id,
    selector: 'kylii-erp-app',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.css'],
    encapsulation: ViewEncapsulation.None,
})

/**
 * Export the App Component
 * 
 * Its the base component which contains the HTML menu + sidebar + container
 */
export class AppComponent implements OnInit
{
    /**
     * Constructor
     * 
     */
    constructor( 
        /**
        * Routeur Service
        */
        public router: Router,
        /**
        * Access to the Main App Service
        */
        public appService: AppService ,
        private cookieService: CookieService,
        private userService: UserService, 
        private loadingService: LoadingService,
        private _viewContainerRef: ViewContainerRef,
        private commonService:CommonService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        @Inject(LOCALE_ID) private localeId: string,
        private deviceService: DeviceDetectorService
        ) {}

    /**
    * Define if we display the menu or not
    */
    public DISPLAY_MENU:boolean = false;

    /**
    * Check if the menu is open
    */
    public IS_MENU_OPEN:boolean = true;

    /**
    * Index for the loading component
    */
    private index_loading:number;

    /**
     * Local reference for open/close menu
     */
    public OPEN_SUB_MENU:boolean = false;

    /**
     * Local reference for know if user is log or not
     */
    public isUserLogged:boolean = false;

    /**
     * Main Title
     */
    public mainTitle:string = "";

    /**
     * Page Title
     */
    public pageTitle:string = "";

    /**
    * Forename and lastname of the logged user
    */
    public user_name:string;
    
    /**
    * Languages liste
    */
    public languages:Language[] = new Array();

    /**
    * Current language
    */
    public language:Language = new Language();

    /**
    * Determin if we need to display the dialog to accept cookies
    */
    public display_need_to_accept_cookies:boolean = false;

    /**
     * Get the Side Nav Element from DOM
     */
    @ViewChild('KyliiErpSidnavList', {static: false}) eltSidNav:any; 


    /**
     * Initialisation
     * 
     */
    ngOnInit(): void 
    {
        this.appService.IS_DESKTOP = this.deviceService.isDesktop();
        this.appService.IS_MOBILE  = this.deviceService.isMobile();
        this.appService.IS_TABLET  = this.deviceService.isTablet();
       
        this.index_loading = this.loadingService.open( this._viewContainerRef );

        this.commonService.language_list()
        .then
        (
            res =>
            {
                this.languages = res;
                this.language  = this.languages[0];
                
                for( let i:number = 0; i < this.languages.length; i++ )
                {
                    let folder_language = this.languages[i].value;
                    folder_language = folder_language[0] + folder_language[1];

                    if( folder_language == this.localeId )
                    {
                        this.appService.LANGUAGE_ID = this.languages[i].id;
                        this.language = this.languages[i];
                        break;
                    }
                }

                this.loadingService.close( this._viewContainerRef, this.index_loading );

                if( this.cookieService.get("KyliiMarket_Allow_Cookies") != "1" )
                {
                    this.display_need_to_accept_cookies = true;
                }
            }
        );


        /**
         * Check if user is looged
         */
        this.userService.isConnected()
            .then
            ( 
                res => 
                { 
                    if( res == true )
                    {
                        let user:User                 = new User();
                        user.id                       = Number( this.cookieService.get("KyliiMarket_User_id") ); 
                        user.forename                 = this.cookieService.get("KyliiMarket_User_forename"); 
                        user.lastname                 = this.cookieService.get("KyliiMarket_User_lastname"); 
                        user.mail                     = this.cookieService.get("KyliiMarket_User_mail");
                        user.customer                 = this.cookieService.get("KyliiMarket_User_customer"); 
                        user.token                    = this.cookieService.get("KyliiMarket_User_token"); 
                        user.password_date_creation   = new Date(parseInt( this.cookieService.get("KyliiMarket_User_password_creation_date"))); 

                        this.user_name        = user.customer;
                        this.isUserLogged = true;

                        this.userService.setLogged( user );
                        this.DISPLAY_MENU = true;
                    }
                    else
                    {
                        this.router.navigate([this.appService.LOGIN_PAGE]);
                        this.DISPLAY_MENU = false;
                        this.IS_MENU_OPEN = false;
                        this.OPEN_SUB_MENU = false;
                    }
                }
            )

        


        /**
         * Detect when user is logged
         */
        this.userService.getLogged().subscribe
        (
            (user: User) => 
            {
                if( user != null )
                {
                    this.isUserLogged = true;
                    this.DISPLAY_MENU = true;
                    this.IS_MENU_OPEN = true;

                    this.user_name = this.userService.getUser().customer;
                }
                else
                {
                    this.isUserLogged = false;
                    this.DISPLAY_MENU = false;
                    this.IS_MENU_OPEN = false;
                    this.OPEN_SUB_MENU = false;
                }
            }
        );


        /**
         * Detect when user change page
         *
         * Use for open/close menu
         */
        this.router.events.subscribe
        (
            (val) => 
            {
                if( val instanceof NavigationEnd )
                {
                    //console.log(val ) 

                    this.userService.isConnected()
                    .then
                    ( 
                        res => 
                        { 
                            if( res == false )
                            {
                               this.DISPLAY_MENU = false;
                            }
                        }
                    )
                }
            }
        );


        /**
         * Detect when user change page
         *
         * Use for open/close menu
         */
        this.router.events.subscribe
        (
            res => 
            {
                if( res instanceof RouteConfigLoadStart )
                {
           //         this.index_loading = this.loadingService.open( this._viewContainerRef );
                }

                if( res instanceof NavigationEnd )
                {
              //      this.loadingService.close( this._viewContainerRef, this.index_loading );
                    if( res.urlAfterRedirects == this.appService.DASHBOARD_PAGE || res.urlAfterRedirects == this.appService.LOGIN_PAGE )
                    {
                        this.OPEN_SUB_MENU = false;
                    }
                    else
                    {
                        let tabUrl = res.url.split("/");
                        if( tabUrl.length == 2 )
                        {
                            this.OPEN_SUB_MENU = true;
                        }
                    }
                }
            }
        );
    }

    /**
    * Call when the user want to return to the home
    *
    *
    */
    public goto_home()
    {
        this.router.navigate([this.appService.DEFAULT_PAGE]);
    }

    /**
    * Call when the user select a new language
    *
    * @param {Language} Language selected
    */
    public onChangeLanguage( language:Language )
    {   
        let folder_language = language.value;
        folder_language = folder_language[0] + folder_language[1];
        let new_url = "https://market.kylii-kids.com/" + folder_language + "/#" + this.router.url;
        window.open(new_url,"_self")
    }

    /**
     * Logout
     *
     * @param {void} voids
     *
     * @returns {void}
     */
    public logout():void
    {
        this.OPEN_SUB_MENU = false;
        this.DISPLAY_MENU = false;
        this.userService.disconnect( );
    }

    /**
    * When the user click to acces to his support
    *
    */
    public on_click_support()
    {
        let dialogRef = this.dialog.open
        (
            LicensePopupSupportComponent, 
            {
                width:  '950px',
                panelClass: 'custom-dialog-container'
            }
        );   
    }
}
