<img *ngIf="this.appService.IS_MOBILE == false" class="module-bubble" src="{{module_logo}}">
<h1  *ngIf="this.appService.IS_MOBILE == false" class="title" [ngStyle]="{'color':module_color}">{{license.module.label}} | {{license.label}}</h1>
<h1  *ngIf="this.appService.IS_MOBILE == true"  class="title-title" [ngStyle]="{'color':module_color}">{{license.module.label}} | {{license.label}}</h1>

<mat-tab-group animationDuration="0ms" color="accent" (selectedTabChange)="update_style()" *ngIf="this.appService.IS_MOBILE == false">
	<mat-tab i18n-label label="Votre programme" *ngIf="license.module.id != 9">

		<div *ngIf="license.use_kylii_program == false && license_content.program != null">
			<br>
			<td-message  i18n-sublabel sublabel="Le programme de votre licence n'est plus associé au programme géré par Kylii-Kids" color="warn" icon="warning">
				<button td-message-actions mat-raised-button (click)="link_to_program()" color="warn" i18n>Revenir au programme géré par Kylii-Kids</button>
			</td-message>
		</div>


		<td-message class="message-informations" i18n-label label="Votre programme" i18n-sublabel sublabel="Choisissez l'odre de vos jeux et leur durée" color="accent" icon="play_circle_filled"></td-message>
		<div class="program-list" *ngIf="license.module.id != 4" >	
			<div cdkDropList [cdkDropListData]="license_content.program_applications" (cdkDropListDropped)="drop_applications($event)" class="page-list">
				<div  *ngFor="let application of license_content.program_applications" cdkDrag class="program-application-thumb" [ngClass]="license.custom_class">
					<img class="product-thumb-column product-thumb-logo" src="{{application.thumb}}">
					<div class="product-thumb-column product-thumb-module" >{{application.sort + 1}} | {{application.label}}</div>
					<div class="product-thumb-column product-thumb-select-time" *ngIf="license.module.id != 3">
						<mat-form-field  color="accent" [ngStyle]="{'width':'90%'}">
							<mat-select i18n-placeholder placeholder="Choississez une durée" name="time" [(ngModel)]="application.time" (selectionChange)="change_duration()">
								<mat-option *ngFor="let time of application_times" [value]="time">
									{{time.label}}
								</mat-option>
						    </mat-select>
						</mat-form-field>
					</div>
					<button class="product-thumb-column product-thumb-button-delete" mat-icon-button (click)="remove_application(application)" ><mat-icon class="product-thumb-button-settings-icon">cancel</mat-icon></button>
					<mat-divider class="product-thumb-divider"></mat-divider>
				</div>
			</div>
		</div>
		<div class="program-list" *ngIf="license.module.id == 4" >
			<div cdkDropList [cdkDropListData]="license_content.program_applications_packages" (cdkDropListDropped)="drop_applications_packages($event)" class="page-list">
				<div  *ngFor="let application of license_content.program_applications_packages" cdkDrag class="program-application-thumb" [ngClass]="license.custom_class">
					<img class="product-thumb-column product-thumb-logo" src="{{application.thumb}}">
					<div class="product-thumb-column product-thumb-module">{{application.sort + 1}} | {{application.label}}</div>
					<button class="product-thumb-column product-thumb-button-delete" mat-icon-button (click)="remove_application_package(application)" ><mat-icon class="product-thumb-button-settings-icon">cancel</mat-icon></button>
					<mat-divider class="product-thumb-divider"></mat-divider>
				</div>
			</div>
		</div>
	</mat-tab>
	<mat-tab i18n-label label="Vos jeux" *ngIf="license.module.id == 9">
		<div class="quest_content" (click)="open_quest_link()">
			<img src="https://s3-eu-west-1.amazonaws.com/kyliikids/wp-content/uploads/2018/08/20153314/quest-product-1-parc_jeux_indoor.png">
			<div class="quest_content_label">
				<span>{{quest_text}}</span> 
			</div>
		</div>
	</mat-tab>
	<mat-tab i18n-label label="Vos jeux" *ngIf="license.module.id != 9">
		<td-message class="message-informations" i18n-label label="Vos jeux" i18n-sublabel sublabel="Choisissez les jeux que vous souhaitez installer sur votre dispositif" color="accent" icon="play_circle_filled"></td-message>
		<mat-accordion #catalog_app_1="matAccordion"  class="catalogue-list" *ngIf="license.module.id != 3 && license.module.id != 4" multi="false"  >
			<div  *ngFor="let application of license_content.market_applications">
				<mat-expansion-panel class="catalogue-thumb" (click)="close_catalog_app_2()" *ngIf="application.id_temp % 2 != 0">
					<mat-expansion-panel-header   [collapsedHeight]="'80px'">
						<mat-panel-title>
							<img mat-card-image class="catalogue-thumb-image" src="{{application.thumb}}">
							<div class="catalogue-thumb-title">{{application.id}} | {{application.label}}</div>
							<mat-slide-toggle (click)="$event.stopPropagation();" (dragChange)="on_click_toggle(application);"  (toggleChange)="on_click_toggle(application);" [(ngModel)]="application.installed" class="catalogue-thumb-install" i18n >installer</mat-slide-toggle>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div class="catalogue-thumb-description">
						{{application.description}}
					</div>
					<img class="catalogue-thumb-visual" src="{{application.visual}}">
				</mat-expansion-panel>
			</div>
		</mat-accordion>
		<mat-accordion #catalog_app_2="matAccordion" class="catalogue-list" *ngIf="license.module.id != 3 && license.module.id != 4" multi="false" >
			<div *ngFor="let application of license_content.market_applications | slice:1; let i = index;">
				<mat-expansion-panel class="catalogue-thumb"  (click)="close_catalog_app_1()" *ngIf="application.id_temp % 2 == 0">
					<mat-expansion-panel-header [collapsedHeight]="'80px'">
						<mat-panel-title>
							<img mat-card-image class="catalogue-thumb-image" src="{{application.thumb}}">
							<div class="catalogue-thumb-title">{{application.id}} | {{application.label}}</div>
							<mat-slide-toggle (click)="$event.stopPropagation();" (dragChange)="on_click_toggle(application);" (toggleChange)="on_click_toggle(application);" [(ngModel)]="application.installed" class="catalogue-thumb-install" i18n >installer</mat-slide-toggle>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div class="catalogue-thumb-description">
						{{application.description}}
					</div>
					<img class="catalogue-thumb-visual" src="{{application.visual}}">
				</mat-expansion-panel>
			</div>
		</mat-accordion>
		<mat-accordion #catalog_pkg_1="matAccordion" class="catalogue-list" *ngIf="license.module.id == 3 || license.module.id == 4" multi="false" >
			<div *ngFor="let application of license_content.market_applications_packages">
				<mat-expansion-panel class="catalogue-thumb"  (click)="close_catalog_pkg_2()" *ngIf="application.id_temp % 2 != 0">
					<mat-expansion-panel-header   [collapsedHeight]="'80px'">
						<mat-panel-title>
							<img mat-card-image class="catalogue-thumb-image" src="{{application.thumb}}">
							<div class="catalogue-thumb-title">{{application.id}} | {{application.label}}</div>
							<mat-slide-toggle *ngIf="license.module.id == 4" (click)="$event.stopPropagation();" (dragChange)="on_click_toggle(application);" (toggleChange)="on_click_toggle(application);" [(ngModel)]="application.installed" class="catalogue-thumb-install" i18n >installer</mat-slide-toggle>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<mat-tab-group class="tab-package" animationDuration="0ms" color="warn" (selectedTabChange)="update_style()">
						<mat-tab *ngFor="let app of application.applications" label="{{app.label}}">
							<div *ngIf="license.module.id == 4" class="catalogue-thumb-description">
								{{app.description}}
							</div>
							<mat-slide-toggle *ngIf="license.module.id == 3" (click)="$event.stopPropagation();" (dragChange)="on_click_toggle(application);" (toggleChange)="on_click_toggle(application);"  [(ngModel)]="app.installed" class="catalogue-package-thumb-install" i18n >installer</mat-slide-toggle>
							<img class="catalogue-thumb-visual" src="{{app.visual}}">
						</mat-tab>
					</mat-tab-group>
				</mat-expansion-panel>
			</div>
		</mat-accordion>
		<mat-accordion #catalog_pkg_2="matAccordion" class="catalogue-list" *ngIf="license.module.id == 3 || license.module.id == 4" multi="false" >
			<div *ngFor="let application of license_content.market_applications_packages | slice:1; let i = index;">
				<mat-expansion-panel class="catalogue-thumb"  (click)="close_catalog_pkg_1()" *ngIf="application.id_temp % 2 == 0">
					<mat-expansion-panel-header [collapsedHeight]="'80px'">
						<mat-panel-title>
							<img mat-card-image class="catalogue-thumb-image" src="{{application.thumb}}">
							<div class="catalogue-thumb-title">{{application.id}} | {{application.label}}</div>
							<mat-slide-toggle *ngIf="license.module.id == 4" (click)="$event.stopPropagation();" (dragChange)="on_click_toggle(application);" (toggleChange)="on_click_toggle(application);"  [(ngModel)]="application.installed" class="catalogue-thumb-install" i18n >installer</mat-slide-toggle>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<mat-tab-group class="tab-package" animationDuration="0ms" color="warn" (selectedTabChange)="update_style()">
						<mat-tab *ngFor="let app of application.applications" label="{{app.label}}">
							<div *ngIf="license.module.id == 4" class="catalogue-thumb-description">
								{{app.description}}
							</div>
							<mat-slide-toggle *ngIf="license.module.id == 3" (click)="$event.stopPropagation();" (dragChange)="on_click_toggle(application);" (toggleChange)="on_click_toggle(application);"  [(ngModel)]="app.installed" class="catalogue-package-thumb-install" i18n >installer</mat-slide-toggle>
							<img class="catalogue-thumb-visual" src="{{app.visual}}">
						</mat-tab>
					</mat-tab-group>
				</mat-expansion-panel>
			</div>
		</mat-accordion>
	</mat-tab>
	<mat-tab i18n-label label="Configuration">
		<div *ngIf="license.module.id == 1 || license.module.id == 4 || license.module.id == 9 || license.module.id == 11" >
			<td-message class="message-informations" i18n-label label="Votre logo" i18n-sublabel sublabel="Votre logo doit être en PNG et nous vous conseillons de ne pas dépasser une taille de 300x300 px." color="accent" icon="play_circle_filled"></td-message>
			
			<div class="logo-parent">
				<div class="logo-container" >
					<div *ngIf="license.logo == ''" class="logo-container-no-image" >?</div>
					<div *ngIf="license.logo != ''" >
						<a href="{{license.logo}}" target="_blank">
							<img src="{{license.logo}}" class="logo-container-image">
						</a>
					</div>
				</div>
				<div class="logo-button" >
					<kylii-upload 
						*ngIf="license.logo == ''"
						class="assets-loader"
						description="Import a logo"
						accept=".png,.PNG"
						distFolder="Logos"
						newFileNameIsTimestamp="true"
						(onUploadCompleted)="on_thumb_complete($event)">
					</kylii-upload>
					<button mat-raised-button (click)="delete_thumb()"  *ngIf="license.logo != ''" i18n><mat-icon color="warn">delete</mat-icon>Supprimer / Remplacer votre logo</button>	
				</div>
			</div>
		</div>
		<td-message class="message-informations" i18n-label label="Vos heures d'allumage et d'extinction" i18n-sublabel sublabel="Nous vous conseillons d'allumer votre solution au moins 30 min avant l'ouverture de votre établissement pour lui laisser le temps de se mettre à jour." color="accent" icon="play_circle_filled"></td-message>
		
		<mat-grid-list cols="21" rowHeight="70px" class="hours-container" *ngIf="license.operating_system_version <= 10.0">
			<mat-grid-tile colspan="10" rowspan="1" class="kylii-mat-grid-tile">
				<div flex="5"></div>
				<mat-form-field color="accent" flex="90">
					<input matInput i18n-placeholder placeholder="Heure d'allumage" type="time" [(ngModel)]="license.boot_hour" [ngModelOptions]="{standalone: true}">
				</mat-form-field>
				<div flex></div>
			</mat-grid-tile>
			<mat-grid-tile colspan="1" rowspan="1"></mat-grid-tile>
			<mat-grid-tile colspan="10" rowspan="1" class="kylii-mat-grid-tile">
				<div flex="5"></div>
				<mat-form-field color="accent" flex="90">
					<input matInput i18n-placeholder placeholder="Heure d'extinction" type="time" [(ngModel)]="license.halt_hour" [ngModelOptions]="{standalone: true}">
				</mat-form-field>
				<div flex></div>
			</mat-grid-tile>
		</mat-grid-list>


		<mat-grid-list cols="32" rowHeight="70px" class="hours-container" *ngIf="license.operating_system_version >= 10.1">
			<div *ngFor="let day of schedule_days" >
				<mat-grid-tile colspan="10" rowspan="1" class="kylii-mat-grid-tile">
					<div flex="5"></div>
					<mat-checkbox color="accent" [(ngModel)]="day.enabled" labelPosition="after" class="thumb_actions_enabled">{{day.label}}</mat-checkbox>
					<div flex></div>
				</mat-grid-tile>
				<mat-grid-tile colspan="1" rowspan="1"></mat-grid-tile>
				<mat-grid-tile colspan="10" rowspan="1" class="kylii-mat-grid-tile">
					<div flex="5"></div>
					<mat-form-field color="accent" flex="90">
						<input matInput i18n-placeholder placeholder="Heure d'allumage" type="time" [(ngModel)]="day.boot_hour" [ngModelOptions]="{standalone: true}" [disabled]="day.enabled == false">
					</mat-form-field>
					<div flex></div>
				</mat-grid-tile>
				<mat-grid-tile colspan="1" rowspan="1"></mat-grid-tile>
				<mat-grid-tile colspan="10" rowspan="1" class="kylii-mat-grid-tile">
					<div flex="5"></div>
					<mat-form-field color="accent" flex="90">
						<input matInput i18n-placeholder placeholder="Heure d'extinction" type="time" [(ngModel)]="day.halt_hour" [ngModelOptions]="{standalone: true}" [disabled]="day.enabled == false">
					</mat-form-field>
					<div flex></div>
				</mat-grid-tile>
			</div>
		</mat-grid-list>
	
	</mat-tab>
</mat-tab-group>



<mat-tab-group animationDuration="0ms" color="accent" (selectedTabChange)="update_style()" *ngIf="this.appService.IS_MOBILE == true">
	<mat-tab i18n-label label="Votre programme">

		<div *ngIf="license.use_kylii_program == false && license_content.program != null">
			<br>
			<td-message class="message-informations"  i18n-sublabel sublabel="Le programme de votre licence n'est plus associé au programme géré par Kylii-Kids" color="warn" icon="warning">
			</td-message>
			<button mat-raised-button (click)="link_to_program()" color="warn" i18n>Revenir au programme géré par Kylii-Kids</button>
		</div>

		<td-message class="message-informations" i18n-label label="Votre programme" i18n-sublabel sublabel="Choisissez l'odre de vos jeux et leur durée" color="accent" icon="play_circle_filled"></td-message>
		<div class="program-list" *ngIf="license.module.id != 4" >	
			<div cdkDropList [cdkDropListData]="license_content.program_applications" (cdkDropListDropped)="drop_applications($event)" class="page-list">
				<div  *ngFor="let application of license_content.program_applications" cdkDrag class="program-application-thumb" [ngClass]="license.custom_class">
					<img class="product-thumb-column product-thumb-logo-mobile" src="{{application.thumb}}">
					<div class="product-thumb-column product-thumb-module-mobile" >{{application.sort + 1}} | {{application.label}}</div>
					<button class="product-thumb-column product-thumb-button-delete-mobile" mat-icon-button (click)="remove_application(application)" ><mat-icon class="product-thumb-button-settings-icon">cancel</mat-icon></button>
					<div class="product-thumb-select-time-mobile" *ngIf="license.module.id != 3">
						<mat-form-field  color="accent" [ngStyle]="{'width':'90%'}">
							<mat-select i18n-placeholder placeholder="Choississez une durée" name="time" [(ngModel)]="application.time" (selectionChange)="change_duration()">
								<mat-option *ngFor="let time of application_times" [value]="time">
									{{time.label}}
								</mat-option>
						    </mat-select>
						</mat-form-field>
					</div>
					
					<mat-divider class="product-thumb-divider-mobile"></mat-divider>
				</div>
			</div>
		</div>
		<div class="program-list" *ngIf="license.module.id == 4" >
			<div cdkDropList [cdkDropListData]="license_content.program_applications_packages" (cdkDropListDropped)="drop_applications_packages($event)" class="page-list">
				<div  *ngFor="let application of license_content.program_applications_packages" cdkDrag class="program-application-thumb" [ngClass]="license.custom_class">
					<img class="product-thumb-column product-thumb-logo-mobile" src="{{application.thumb}}">
					<div class="product-thumb-column product-thumb-module-mobile" >{{application.sort + 1}} | {{application.label}}</div>
					<button class="product-thumb-column product-thumb-button-delete-mobile" mat-icon-button (click)="remove_application(application)" ><mat-icon class="product-thumb-button-settings-icon">cancel</mat-icon></button>
					
					<mat-divider class="product-thumb-divider-mobile"></mat-divider>
				</div>
			</div>
		</div>
	</mat-tab>

	<mat-tab i18n-label label="Vos jeux" *ngIf="license.module.id == 9">
		<div class="quest_content_mobile" (click)="open_quest_link()">
			<img class="quest_content_image_mobile" src="https://s3-eu-west-1.amazonaws.com/kyliikids/wp-content/uploads/2018/08/20153314/quest-product-1-parc_jeux_indoor.png">
			<div class="quest_content_label">
				<span>{{quest_text}}</span> 
			</div>
		</div>
	</mat-tab>

	<mat-tab i18n-label label="Vos jeux" *ngIf="license.module.id != 9">
		<td-message class="message-informations" i18n-label label="Vos jeux" i18n-sublabel sublabel="Choisissez les jeux que vous souhaitez installer sur votre dispositif" color="accent" icon="play_circle_filled"></td-message>
		<mat-accordion  class="catalogue-list-mobile" *ngIf="license.module.id != 3 && license.module.id != 4" multi="false"  >
			<div  *ngFor="let application of license_content.market_applications">
				<mat-expansion-panel class="catalogue-thumb-mobile" >
					<mat-expansion-panel-header   [collapsedHeight]="'80px'">
						<mat-panel-title>
							<img mat-card-image class="catalogue-thumb-image-mobile" src="{{application.thumb}}">
							<div class="catalogue-thumb-title">{{application.id}} | {{application.label}}</div>
							
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div class="catalogue-thumb-description">
						{{application.description}}
					</div>
					<mat-slide-toggle (click)="$event.stopPropagation();" (dragChange)="on_click_toggle(application);" (toggleChange)="on_click_toggle(application);"  [(ngModel)]="application.installed" class="catalogue-thumb-install-mobile" i18n >installer</mat-slide-toggle>
					<img class="catalogue-thumb-visual-mobile" src="{{application.visual}}">
				</mat-expansion-panel>
			</div>
		</mat-accordion>
		<mat-accordion class="catalogue-list-mobile" *ngIf="license.module.id == 3 || license.module.id == 4" multi="false" >
			<div *ngFor="let application of license_content.market_applications_packages">
				<mat-expansion-panel class="catalogue-thumb-mobile" >
					<mat-expansion-panel-header   [collapsedHeight]="'80px'">
						<mat-panel-title>
							<img mat-card-image class="catalogue-thumb-image" src="{{application.thumb}}">
							<div class="catalogue-thumb-title">{{application.id}} | {{application.label}}</div>
							
						</mat-panel-title>
					</mat-expansion-panel-header>
					<mat-slide-toggle *ngIf="license.module.id == 4" (click)="$event.stopPropagation();" (dragChange)="on_click_toggle(application);" (toggleChange)="on_click_toggle(application);"  [(ngModel)]="application.installed" class="catalogue-thumb-install-mobile" i18n >installer</mat-slide-toggle>
					<mat-tab-group class="tab-package" animationDuration="0ms" color="warn" (selectedTabChange)="update_style()">
						<mat-tab *ngFor="let app of application.applications" label="{{app.label}}">
							<div *ngIf="license.module.id == 4" class="catalogue-thumb-description">
								{{app.description}}
							</div>
							<mat-slide-toggle *ngIf="license.module.id == 3" (click)="$event.stopPropagation();" (dragChange)="on_click_toggle(application);" (toggleChange)="on_click_toggle(application);"  [(ngModel)]="app.installed" class="catalogue-package-thumb-install" i18n >installer</mat-slide-toggle>
							<img class="catalogue-thumb-visual-mobile" src="{{app.visual}}">
						</mat-tab>
					</mat-tab-group>
				</mat-expansion-panel>
			</div>
		</mat-accordion>
	</mat-tab>
	<mat-tab i18n-label label="Configuration">
		<div *ngIf="license.module.id == 1" >
			<td-message class="message-informations" i18n-label label="Votre logo" i18n-sublabel sublabel="Votre logo doit être en PNG et nous vous conseillons de ne pas dépasser une taille de 300x300 px." color="accent" icon="play_circle_filled"></td-message>
			
			<div class="logo-parent-mobile">
				<div class="logo-container-mobile" >
					<div *ngIf="license.logo == ''" class="logo-container-no-image-mobile" >?</div>
					<div *ngIf="license.logo != ''" >
						<a href="{{license.logo}}" target="_blank">
							<img src="{{license.logo}}" class="logo-container-image-mobile">
						</a>
					</div>
				</div>
				<div class="logo-button" >
					<kylii-upload 
						*ngIf="license.logo == ''"
						class="assets-loader"
						description="Import a logo"
						accept=".png,.PNG"
						distFolder="Logos"
						newFileNameIsTimestamp="true"
						(onUploadCompleted)="on_thumb_complete($event)">
					</kylii-upload>
					<button mat-raised-button (click)="delete_thumb()"  *ngIf="license.logo != ''" i18n><mat-icon color="warn">delete</mat-icon>Supprimer / Remplacer votre logo</button>	
				</div>
			</div>
		</div>
		<td-message class="message-informations" i18n-label label="Vos heures d'allumage et d'extinction" i18n-sublabel sublabel="Nous vous conseillons d'allumer votre solution au moins 30 min avant l'ouverture de votre établissement pour lui laisser le temps de se mettre à jour." color="accent" icon="play_circle_filled"></td-message>
		
		
		<mat-grid-list cols="1" rowHeight="70px" class="hours-container-mobile" *ngIf="license.operating_system_version <= 10.0">
			<mat-grid-tile colspan="1" rowspan="1" class="kylii-mat-grid-tile">
				<div flex="5"></div>
				<mat-form-field color="accent" flex="90">
					<input matInput i18n-placeholder placeholder="Heure d'allumage" type="time" [(ngModel)]="license.boot_hour" [ngModelOptions]="{standalone: true}">
				</mat-form-field>
				<div flex></div>
			</mat-grid-tile>
			<mat-grid-tile colspan="1" rowspan="1" class="kylii-mat-grid-tile">
				<div flex="5"></div>
				<mat-form-field color="accent" flex="90">
					<input matInput i18n-placeholder placeholder="Heure d'extinction" type="time" [(ngModel)]="license.halt_hour" [ngModelOptions]="{standalone: true}">
				</mat-form-field>
				<div flex></div>
			</mat-grid-tile>
		</mat-grid-list>

		<mat-grid-list cols="32" rowHeight="70px" class="hours-container-mobile" *ngIf="license.operating_system_version >= 10.1">
			<div *ngFor="let day of schedule_days" >
				<mat-grid-tile colspan="10" rowspan="1" >
					<div flex="5"></div>
					<mat-checkbox color="accent" [(ngModel)]="day.enabled" labelPosition="after" class="thumb_actions_enabled">{{day.label}}</mat-checkbox>
					<div flex></div>
				</mat-grid-tile>
				<mat-grid-tile colspan="1" rowspan="1"></mat-grid-tile>
				<mat-grid-tile colspan="10" rowspan="1" class="kylii-mat-grid-tile">
					<div flex="5"></div>
					<mat-form-field color="accent" flex="90">
						<input matInput i18n-placeholder placeholder="Heure d'allumage" type="time" [(ngModel)]="day.boot_hour" [ngModelOptions]="{standalone: true}" [disabled]="day.enabled == false">
					</mat-form-field>
					<div flex></div>
				</mat-grid-tile>
				<mat-grid-tile colspan="1" rowspan="1"></mat-grid-tile>
				<mat-grid-tile colspan="10" rowspan="1" class="kylii-mat-grid-tile">
					<div flex="5"></div>
					<mat-form-field color="accent" flex="90">
						<input matInput i18n-placeholder placeholder="Heure d'extinction" type="time" [(ngModel)]="day.halt_hour" [ngModelOptions]="{standalone: true}" [disabled]="day.enabled == false">
					</mat-form-field>
					<div flex></div>
				</mat-grid-tile>
			</div>
		</mat-grid-list>


	</mat-tab>
</mat-tab-group>

<kylii-save i18n-label label="Sauvegarder" icon="save" [display_loading]="false" [disabled]="DISABLE_WHEN_SAVING" (onClick)="save_informations($event)"></kylii-save>