import { Component, OnInit, ViewChild }                  from '@angular/core';
import { Inject}                                         from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA}       from '@angular/material';
import { MatSnackBar }                                   from '@angular/material';
import { TdDialogService }                               from '@covalent/core';
import { ViewContainerRef }                              from '@angular/core';
import { FormBuilder }                                   from '@angular/forms';
import { FormGroup }                                     from '@angular/forms';
import { FormControl }                                   from '@angular/forms';
import { Validators }                                    from '@angular/forms';
import { AbstractControl }                               from '@angular/forms';
import { ValidatorFn }                                   from '@angular/forms';
import { ErrorStateMatcher }                             from '@angular/material/core';
import { FormGroupDirective }                            from '@angular/forms';
import { NgForm }                                        from '@angular/forms';
import { Observable }                                    from 'rxjs';
import { LoadingService }                                from '../../../../commons/loading/service/loading.service';
import { CookieService }                                 from '../../../../core/service/cookie.service';
import { AppService }                                    from '../../../../core/service/app.service';
import { User }                                          from '../../vo/user';
import { UserService }                                   from '../../service/user.service';

/**
* Class used manage miss matching error
*
*/
export class MyErrorStateMatcher implements ErrorStateMatcher 
{

  /**
    * Class used manage miss matching error
    *
    */
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

/**
 * User Popup Change Password Component
 * 
 * This component is used to changed the user password
 */
@Component(
{
    selector: 'user-popup-change-password-component',
    templateUrl: 'user.popup.change.password.component.html',
    styleUrls: [ 'user.popup.change.password.component.css']
})
export class UserPopupChangePasswordComponent implements OnInit
{
    /**
     * Constructor
     * 
     */
	constructor
	(
		private dialogRef: MatDialogRef<UserPopupChangePasswordComponent>,
        private _dialogService: TdDialogService,
        private _viewContainerRef: ViewContainerRef,
        private snackBar: MatSnackBar,
        private cookieService: CookieService,
        private UserService: UserService, 
        /**
        * Access to the Main App Service
        */
        public appService: AppService,
        private loadingService: LoadingService,
        private dialog: MatDialog,
        private fb: FormBuilder,
        /**
        * Datas pass to the component
        */
		@Inject(MAT_DIALOG_DATA) public data: any
	) 
    {
    }

    /**
    * Determin if the component is sening data or not
    */
    public DISABLE_WHEN_SENDING:boolean = false;

    /**
    * New password
    */
    public new_password:string = "";

    /**
    * Confirmation of the new password
    */
    public new_password_confirmation:string = "";


    /**
    * Form control
    */
    public registerForm: FormGroup;

    /**
    * User for error in matching password
    */
    public matcher = new MyErrorStateMatcher();

    /**
    * Determin if the user used the same password
    */
    public same_password:boolean = false;


    /*****************************************************************/
    //
    // Initialisation
    //
    /*****************************************************************/

    /**
     * Initialisation
     * 
     */
    ngOnInit(): void 
    {
        this.registerForm = this.fb.group
        (
            {
                control_new_password: ['', [Validators.required, Validators.minLength(8)]],
                control_new_password_confirmation: ['']
            }, 
            {
                validator: this.matching_password 
            }
        );
    }


    /*****************************************************************/
    //
    // Dialog
    //
    /*****************************************************************/

    /**
     * Open an alert message
     * 
     * @param {string} Content of the message
     * @param {string} Title of the message
     * @param {string} Label of the close button
     */
    public openDialog( content:string, title:string, closeButton:string )
    {
        this._dialogService.openAlert
        (
            {
                message: content,
                title: title, 
                closeButton: closeButton, 
            }
        );
    }

    /*****************************************************************/
    //
    // Event
    //
    /*****************************************************************/

    /**
    * Catch when the user used the Enter touch
    *
    * @param {KeyboardEvent} Key board touch
    */
    public on_keyup( evt:KeyboardEvent )
    {
        if( evt.keyCode == 13 && this.DISABLE_WHEN_SENDING == false && this.registerForm.valid == true && this.new_password != "" && this.new_password_confirmation != "")
        {
            this.update_password();
        }
    }


    /*****************************************************************/
    //
    // Validator
    //
    /*****************************************************************/

    public matching_password(group: FormGroup) 
    {
        let pass = group.controls.control_new_password.value;
        let confirmPass = group.controls.control_new_password_confirmation.value;



        if( pass.length < 8 )
        {
            return { minlength: true };
        }
        else
        {
            if(  confirmPass == "" )
            {
                return null
            }
            else
            {
                if( pass == confirmPass )
                {
                    return null;
                }
                else
                {
                    return { MissMatchingPassword: true };
                }
            }
        }
    }


    /*****************************************************************/
    //
    // Popup
    //
    /*****************************************************************/

    /**
    * Called when the user clik outside the popup
    *
    */
    onNoClick(): void 
    {
        this.dialogRef.close();
    }


    /**
    * Update the password
    *
    */
    public update_password()
    {
        this.DISABLE_WHEN_SENDING = true;

        this.UserService.change_password( this.UserService.getUser().id, this.new_password )
        .then
        (
            res =>
            {
                if( res == "fail" )
                {
                    this.openDialog("Fail to update your password", "Error", "Close");
                }
                else
                {
                    if( res == "ok" )
                    {
                        this.dialogRef.close( {success:true} );
                    }
                    else
                    {
                        this.same_password = true
                        
                    }

                    this.DISABLE_WHEN_SENDING = false;
                }
            }
        );
    }
}

