/**
* Application Time Value Object
*
*/
export class ApplicationTime
{
	/**
	* Application time's id
	*/
	id:number;

	/**
	* Application time's label
	*/
	label:string;

	/**
	* Application time's duration in seconds
	*/
	value:number;
}