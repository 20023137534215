import { LicenseZoneInfo } 			from './license.zoneinfo';
import { Module }					from '../../common/vo/module';
import { Language }                 from '../../common/vo/language';
import { LicenseInternetTimeSlot }  from './license.internet.time.slot'
import { Application }              from '../../software/vo/application';
import { ApplicationPackage }       from '../../software/vo/application.package';
import { LicenseSchedule } from './license.schedule';

/**
* License Value Object
*
*/
export class License
{
	/**
	* License's id
	*/
	id:number;

	/**
	* License's label
	*/
	label:string;

	

	/**
	* License's zone info
	*/
	zoneinfo:LicenseZoneInfo;

	/**
	* License's module
	*/
	module:Module;

	/**
	* License's languages list
	*/
	languages:Language[];

	/**
	* License's Internet time slot
	*/
	internetTimeSlots:LicenseInternetTimeSlot[];

	/**
	* Applications list link to the license
	*/
	applications:Application[];

	/**
	* Application's packages list link to the license
	*/
	packages:ApplicationPackage[];

	/**
	* License number - same as the id
	*/
	license_number:string;

	/**
	* License number customer - used by the configurator to insert a license
	*/
	license_number_customer:string;

	/**
	* Determin if the license is allow to sync his datas
	*/
	can_sync:boolean;

	/**
	* Determin if the license need to sync his OS informatons
	*/
	can_get_infos:boolean;

	/**
	* Determin if the license need to sync his content (applications or application's packages)
	*/
	can_sync_app:boolean;

	/**
	* Determin if the license need to sync the order of his applications or application's apackages 
	*/
	can_sync_switcher:boolean;

	/**
	* Determin if the license is enabled or not
	*/
	enabled:boolean;

	/**
	* Date of the activation of the license
	*/
	activation_date:Date;

	/**
	* Date of the desactivation of the license
	*/
	desactivation_date:Date;

	/**
	* Hour when the System have to wake up
	*/
	boot_hour:string;

	/**
	* Hour when the System have to shut down
	*/
	halt_hour:string;

	/**
	* Last Boot date
	*/
	last_boot:Date;

	/**
	* Last connexion date
	*/
	last_connexion:Date;

	/**
	* Last sync os date
	*/
	last_sync_os:Date;

	/**
	* Last sync app date
	*/
	last_sync_app:Date;

	/**
	* Last share date
	*/
	last_share:Date;

	/**
	* Last share analytics date
	*/
	last_stats:Date;

	/**
	* Result of the system report
	*/
	system_report:string;

	/**
	* Last system report date
	*/
	system_report_last_date:Date;

	/**
	* Time between BoxControl need to be called
	*/
	BoxBrother_control_timing:number;

	/**
	* Determin if the BoxControl is allow to bypass the Internet time slots
	*/
	BoxBrother_control_allow_bypass_internet_control_access:boolean;

	/**
	* Time when the notification popup have to be display before the switch of a game
	*/
	time_notification:number;

	/**
	* Time between two share
	*/
	time_sharing:number;

	/**
	* Time between share
	*/
	time_between_share:number;

	/**
	* Duration of inactivity of an user befre the system go to sleep mode - Used for Touch
	*/
	time_before_sleep_mode:number;

	/**
	* Determin if the game switch automacly or not - default yes
	*/
	enable_switch_app:boolean;

	/**
	* Enable the gallery function - Used for Air
	*/
	enable_gallery:boolean;

	/**
	* If share are enable, determin if we display a QrCode or not in order to retrieve his picture
	*/
	enable_qrcode:boolean;
	
	/**
	* If share are enable, determin if we display a SMS number or not in order to retrieve his picture
	*/
	enable_sms:boolean;

	/**
	* Path of the logo of the license
	*/
	logo:string;

	/**
	* Determin if the license use the program create by Kylii-Kids or determin byt the customer
	*/
	use_kylii_program:boolean;

	/**
	* Determin if the license is up to date
	*/
	have_to_be_upgraded:boolean;

	/**
	* Determin if the license used book device
	*/
	book_devices:boolean;

	/**
	* Get the operating system version of the license
	*/
	operating_system_version:number;

	/**
	* Total license in the list give by the Web-Service
	*/
	licenses_total_list:number;

	/**
	* Product's thumb path
	*/
	thumb:string;

	/**
	* Product's thumb background color
	*/
	background_color:string;

	/**
	* Determin if the license is connected or not
	*/
	connected:boolean;

	/**
	* Product's thumb background class
	*/
	custom_class:string;

	/**
	* id of the command created
	*/
	last_command_id:number;

	/**
	* Token
	*/
	token:string;

	/**
	* Schedule
	*/
	schedule:LicenseSchedule;
}
