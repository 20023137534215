import { Component, OnInit }      from '@angular/core';

/**
 * The Loading Component
 * 
 * Its a component which display a loading bar on the top of HTML's container
 */
@Component({
   	moduleId: module.id,
  	selector: 'kylii-loading',
  	templateUrl: 'loading.component.html',
  	styleUrls: [ 'loading.component.css' ]
})
export class LoadingComponent implements OnInit
{
    /**
    * Constructor
    * 
    */
    constructor
    (

    ) {}

    /**
    * Initialisation
    * 
    */
    ngOnInit(): void 
    {
        
    }
}