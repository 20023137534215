import { catchError, map } from 'rxjs/operators';

import { Injectable } 								from '@angular/core';
import { Observable } 								from 'rxjs';
import { Subject } 									from 'rxjs';
import { HttpClient, HttpHeaders } 					from '@angular/common/http';
import { Router }                					from '@angular/router';

import { AppService }       						from '../../../core/service/app.service';
import { CookieService }    						from '../../../core/service/cookie.service';
import { User } 									from '../vo/user';

/**
 * User Service
 * 
 * Use by the site for all User communication / datas
 */
@Injectable()
export class UserService 
{
	/**
	 * Constructor
	 * 
	 */
	constructor (
		private http: HttpClient,
		private router: Router,
		private appService: AppService,  
		private cookieService: CookieService
		) {}

	/*****************************************************************/
	//
	// Observator to see if User is looged or not
	//
	/*****************************************************************/

	/**
	* Current User
	*/
	public user:User;

	/**
	* Current User used for subject
	*/
	private logged: User;

	/**
	* Current User Subject
	*/
	private subject_isLogged: Subject<User> = new Subject<User>();


	/**
	 * Setter :: Set User Logged
	 * 
	 * @param {User} User
	 */
	public setLogged(user: User): void 
	{
		this.user = user;
		this.subject_isLogged.next(user);
	}
	
	/**
	 * Getter :: Get User Logged
	 * 
	 * @return {User} User
	 */
	public getLogged(): Observable<User> 
	{
	    return this.subject_isLogged.asObservable();
	}

	/**
	 * Get current User
	 * 
	 * @return {User} User
	 */
	public getUser():User
	{
		return this.user;
	}

	/*****************************************************************/
	//
	// User Login ( nickname + password )
	//
	/*****************************************************************/


	/**
	 * Call the Web-Service used for log an User
	 * 
	 * @param {string} Login
	 * @param {string} password
	 * @param {boolean} Is password in sha1
	 * @return {boolean} Success or not
	 */
	public connect( login:string, password:string, password_in_sha1:boolean ):Promise<boolean>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiMarketAPI/user/connect/'; 

		return this.http
		    .post(url, JSON.stringify({login: login, password:password}), {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__connected(response)  )
		    .catch(this.handleError__connected);
	}


	/**
	 * Response of connect method
	 * 
	 * @param {Response} Response of the Web-Service
	 * @return {boolean} Success or not
	 */
	private extractData__connected(res: any) :boolean
	{
		let body = res;

		if( body.success == true )
		{
			let user:User 				= new User();
			user.id 					= body.result.user.id;
			user.forename 				= body.result.user.firstname;
			user.lastname 				= body.result.user.lastname;
			user.mail 					= body.result.user.mail;
			user.customer				= body.result.user.customer;
			user.token 					= body.result.user.token;
			user.password_date_creation = new Date( parseInt(body.result.user.password_date_creation) * 1000 );

			this.setLogged(user);

			this.cookieService.set( 'KyliiMarket_User_id'						, user.id.toString() );
			this.cookieService.set( 'KyliiMarket_User_forename'					, user.forename );
			this.cookieService.set( 'KyliiMarket_User_lastname'					, user.lastname );
			this.cookieService.set( 'KyliiMarket_User_mail'						, user.mail );
			this.cookieService.set( 'KyliiMarket_User_customer'					, user.customer );
			this.cookieService.set( 'KyliiMarket_User_token'					, user.token );
			this.cookieService.set( 'KyliiMarket_User_password_creation_date'	, (parseInt(body.result.user.password_date_creation) * 1000).toString() );

			return true;
		}
		else
		{
			//console.error('An error occurred when called the WS KyliiSAAS/User/connect');
			return false;
		}
	}

	/**
	 * Catch error of connect method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__connected(error: any): Promise<any> 
	{
    	console.error('An error occurred when called the WS user/connect', error); 
    	return Promise.reject(error.message || error);
    }
	

	/*****************************************************************/
	//
	// User Disconnect
	//
	/*****************************************************************/

	/**
	 * Disconnect the current User
	 * 
	 */
	public disconnect( )
	{
		this.cookieService.delete( 'KyliiMarket_User_id'						);
		this.cookieService.delete( 'KyliiMarket_User_forename'					);
		this.cookieService.delete( 'KyliiMarket_User_lastname' 					);
		this.cookieService.delete( 'KyliiMarket_User_mail'						);
		this.cookieService.delete( 'KyliiMarket_User_customer'					);
		this.cookieService.delete( 'KyliiMarket_User_token'						);
		this.cookieService.delete( 'KyliiMarket_User_password_creation_date'	);

		this.setLogged(null);

		this.router.navigate([this.appService.LOGIN_PAGE]);

		location.reload();
	}



	/*****************************************************************/
	//
	// Chesk if user is connected via token stock in localstroage
	//
	/*****************************************************************/

	/**
	 * Call the Web-Service used for check if the User is connected or not
	 * 
	 * @return {boolean} Success or not
	 */
	public isConnected(  ):Promise<boolean>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiMarketAPI/user/is_connect/'; 

		return this.http
		    .post(url, JSON.stringify({id: this.cookieService.get( 'KyliiMarket_User_id' ), token: this.cookieService.get( 'KyliiMarket_User_token' )}), {headers: headers})
		    .toPromise()
		    .then(this.extractData__isConnected)
		    .catch(this.handleError__isConnected);
	}

	/**
	 * Response of connect method
	 * 
	 * @param {Response} Response of the Web-Service
	 * @return {boolean} Success or not
	 */
	private extractData__isConnected(res: any):boolean
	{
		let body = res;
		if( body.status == "ok" || body.success == true)
		{
			return true;
		}
		else
		{
			//console.error('An error occurred when called the WS KyliiSAAS/User/isConnected'); 
			return false;
		}
	}

	/**
	 * Catch error of isConnected method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__isConnected(error: any): Promise<any> 
	{
    	console.error('An error occurred when called the WS user/ask', error); 
    	return Promise.reject(error.message || error);
    }



    /*****************************************************************/
	//
	// Change Password
	//
	/*****************************************************************/


	/**
	 * Changed the password
	 * 
	 * @param {number} User id
	 * @param {string} new password
	 * @return {string} Response
	 */
	public change_password( id:number, password:string ):Promise<string>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiMarketAPI/user/change_password/'; 

		return this.http
		    .post(url, JSON.stringify({id: id, password:password}), {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__change_password(response)  )
		    .catch(this.handleError__change_password);
	}


	/**
	 * Response of change_password method
	 * 
	 * @param {Response} Response of the Web-Service
	 * @return {string} Response
	 */
	private extractData__change_password(res: any) :string
	{
		let body = res;

		if( body.status == "ok" || body.success == true )
		{
			if( body.result == true )
			{
				body.result = "ok";
			}
			return body.result;
		}
		else
		{
			//console.error('An error occurred when called the WS KyliiSAAS/User/connect');
			return "fail";
		}
	}

	/**
	 * Catch error of change_password method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__change_password(error: any): Promise<any> 
	{
    	console.error('An error occurred when called the WS user/change/password', error); 
    	return Promise.reject(error.message || error);
    }



    /*****************************************************************/
	//
	// Send code for Change Mail
	//
	/*****************************************************************/


	/**
	 * Send a code in order to change user mail
	 * 
	 * @param {number} User id
	 * @param {string} mail
	 * @return {boolean} Success or not
	 */
	public send_code_for_change_mail( id:number, mail:string ):Promise<boolean>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiMarketAPI/user/send_mail_code/'; 

		return this.http
		    .post(url, JSON.stringify({id: id, mail:mail}), {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__send_code_for_change_mail(response)  )
		    .catch(this.handleError__send_code_for_change_mail);
	}


	/**
	 * Response of send_code_for_change_mail method
	 * 
	 * @param {Response} Response of the Web-Service
	 * @return {boolean} Success or not
	 */
	private extractData__send_code_for_change_mail(res: any) :boolean
	{
		let body = res;

		if( body.status == "ok" || body.success == true)
		{
			return true;
		}
		else
		{
			//console.error('An error occurred when called the WS KyliiSAAS/User/connect');
			return false;
		}
	}

	/**
	 * Catch error of send_code_for_change_mail method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__send_code_for_change_mail(error: any): Promise<any> 
	{
    	console.error('An error occurred when called the WS user/send/mail/code', error); 
    	return Promise.reject(error.message || error);
    }



    /*****************************************************************/
	//
	// Change Mail
	//
	/*****************************************************************/


	/**
	 * Changed the mail
	 * 
	 * @param {number} User id
	 * @param {string} mail
	 * @param {string} confirmation code
	 * @return {boolean} Success or not
	 */
	public change_mail( id:number, mail:string, confirmation_code:string ):Promise<boolean>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiMarketAPI/user/update_mail/'; 

		return this.http
		    .post(url, JSON.stringify({id: id, mail:mail, confirmation_code:confirmation_code}), {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__change_mail(response)  )
		    .catch(this.handleError__change_mail);
	}


	/**
	 * Response of change_mail method
	 * 
	 * @param {Response} Response of the Web-Service
	 * @return {boolean} Success or not
	 */
	private extractData__change_mail(res: any) :boolean
	{
		let body = res;

		if( body.status == "ok" || body.success == true )
		{
			return true;
		}
		else
		{
			//console.error('An error occurred when called the WS KyliiSAAS/User/connect');
			return false;
		}
	}

	/**
	 * Catch error of change_mail method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__change_mail(error: any): Promise<any> 
	{
    	console.error('An error occurred when called the WS user/change/mail', error); 
    	return Promise.reject(error.message || error);
    }


    /*****************************************************************/
	//
	// Send confirmation code for password
	//
	/*****************************************************************/


	/**
	 * Reset password
	 * 
	 * @param {string} mail
	 * @return {boolean} Success or not
	 */
	public send_code_for_change_password( mail:string ):Promise<boolean>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiMarketAPI/user/send_password_code/'; 

		return this.http
		    .post(url, JSON.stringify({mail:mail}), {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__send_code_for_change_password(response)  )
		    .catch(this.handleError__send_code_for_change_password);
	}


	/**
	 * Response of send_code_for_change_password method
	 * 
	 * @param {Response} Response of the Web-Service
	 * @return {boolean} Success or not
	 */
	private extractData__send_code_for_change_password(res: any) :boolean
	{
		let body = res;

		if( body.status == "ok" || body.success == true )
		{
			return true;
		}
		else
		{
			//console.error('An error occurred when called the WS KyliiSAAS/User/connect');
			return false;
		}
	}

	/**
	 * Catch error of send_code_for_change_password method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__send_code_for_change_password(error: any): Promise<any> 
	{
    	console.error('An error occurred when called the WS user/send/password/code', error); 
    	return Promise.reject(error.message || error);
    }



    /*****************************************************************/
	//
	// Reset
	//
	/*****************************************************************/


	/**
	 * Reset password
	 * 
	 * @param {string} mail
	 * @param {string} confirmation code
	 * @return {boolean} Success or not
	 */
	public reset_password( mail:string, confirmation_code:string ):Promise<boolean>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiMarketAPI/user/reset_password/'; 

		return this.http
		    .post(url, JSON.stringify({mail:mail,confirmation_code:confirmation_code}), {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__reset_password(response)  )
		    .catch(this.handleError__reset_password);
	}


	/**
	 * Response of change_mail method
	 * 
	 * @param {Response} Response of the Web-Service
	 * @return {boolean} Success or not
	 */
	private extractData__reset_password(res: any) :boolean
	{
		let body = res;

		if( body.status == "ok" || body.success == true )
		{
			return true;
		}
		else
		{
			//console.error('An error occurred when called the WS KyliiSAAS/User/connect');
			return false;
		}
	}

	/**
	 * Catch error of change_mail method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__reset_password(error: any): Promise<any> 
	{
    	console.error('An error occurred when called the WS user/reset', error); 
    	return Promise.reject(error.message || error);
    }
}
