import { ApplicationDetails } 	from './application.details';
import { ApplicationTime }		from './application.time';
import { Module } 				from '../../common/vo/module';

/**
* Application Value Object
*
*/
export class Application
{
	/**
	* Application's id
	*/
	id:number;

	/**
	* Application's id temp
	*/
	id_temp:number;

	/**
	* Application's Module
	*/
	module:Module;

	/**
	* Application's Details
	*/
	details:ApplicationDetails[];
	
	/**
	* Current details
	*/
	current_details:ApplicationDetails;

	/**
	* Application's time
	*/
	time:ApplicationTime;

	/**
	* Application's label
	*/
	label:string;

	/**
	* Application's description
	*/
	description:string;

	/**
	* Application's thumb
	*/
	thumb:string;

	/**
	* Application's visual
	*/
	visual:string;

	/**
	* Application's active or not
	*/
	enable:boolean;

	/**
	* Application's sort
	*/
	sort:number;

	/**
	* Total application's count return by the Web-Service used to list applications
	*/
	applications_total_list:number;


	/**
	* Determin if Application is installed or not
	*/
	installed:boolean;
}