import { Component }                                   from '@angular/core';
import { Router, ActivatedRoute, Params }              from '@angular/router';
import { ViewContainerRef }                            from '@angular/core';

import { TdDataTableService }                          from '@covalent/core';
import { TdDataTableSortingOrder }                     from '@covalent/core';
import { ITdDataTableSortChangeEvent }                 from '@covalent/core';
import { ITdDataTableColumn }                          from '@covalent/core';
import { IPageChangeEvent }                            from '@covalent/core';
import { TdDialogService }                             from '@covalent/core';
import { MatDialog }                                   from '@angular/material';
import { MatDialogRef }                                from '@angular/material';
import { MAT_DIALOG_DATA}                              from '@angular/material';

import { AppService }                                  from '../../../../core/service/app.service';
import { User }                                        from '../../vo/user';
import { UserService }                                 from '../../service/user.service';

import { UserPopupChangePasswordComponent }            from '../popup_change_password/user.popup.change.password.component';
import { UserPopupChangeMailComponent }                from '../popup_change_mail/user.popup.change.mail.component';
import { UserPopupResetPasswordComponent }             from '../popup_reset_password/user.popup.reset.password.component';


/**
* User Login Component
*
* Used to log an user
*/
@Component({
    moduleId: module.id,
    selector: 'user-login',
    templateUrl: 'login.component.html',
    styleUrls: [ 'login.component.css' ]
})
export class UserLoginComponent 
{
    /**
    * Constructor
    *
    */
    constructor( 
        private router: Router,
        private userService: UserService, 
        /**
        * Access to the Main App Service
        */
        public appService: AppService,
        private _dialogService: TdDialogService,
        private _viewContainerRef: ViewContainerRef,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog
        ) {}

    /**
    * User login
    */
    public user_login:string = ""; //"bruno@kylii-kids.com";

    /**
    * User password
    */
    public user_password:string = ""; //"kyliikids123";

    /**
    * Determin if the login is in try to connect
    */
    public DISABLE_WHEN_LOADING:boolean = false;

    /**
    * Initialisation
    *
    */
    ngOnInit(): void 
    {

        this.activatedRoute.queryParams.subscribe
        (
            params => 
            {
                if( params )
                {
                    if( params.login && params.password )
                    {
                        this.user_login    = params.login;
                        this.user_password = params.password;
                        this.appService.BACK_DOOR = true;
                        this.login(false);
                    }
                    else
                    {
                        /**
                         * Detect when user logged
                         */
                        this.userService.getLogged().subscribe
                        (
                            (user: User) => 
                            {
                                if( user != null )
                                {
                                    if( this.appService.BACK_DOOR == false )
                                    {
                                        this.check_user_connexion();
                                    }  
                                    else
                                    {
                                        this.router.navigate([this.appService.DASHBOARD_PAGE]);
                                    } 
                                }
                            }
                        );
                    }
                }
                else
                {
                    /**
                     * Detect when user logged
                     */
                    this.userService.getLogged().subscribe
                    (
                        (user: User) => 
                        {
                            if( user != null )
                            {
                                if( this.appService.BACK_DOOR == false )
                                {
                                    this.check_user_connexion();
                                }  
                                else
                                {
                                    this.router.navigate([this.appService.DASHBOARD_PAGE]);
                                } 
                            }
                        }
                    );
                }
            }
        );

        

        
    }

    /**
    * Log an user
    * 
    * @param {boolean} Is passwod in sha1
    */
    public login( password_in_sha1:boolean = false ):void
    {
        if( this.user_login != null && this.user_password != null )
        {
            this.DISABLE_WHEN_LOADING = true;

            this.userService.connect( this.user_login, this.user_password, password_in_sha1 )
            .then
            ( 
                res => 
                { 
                    this.DISABLE_WHEN_LOADING = false;
                    if( res == false )
                    {
                        this.openDialog( "Wrong login and/or password", "Error", "Close" );
                    }
                    else
                    {
                        this.router.navigate([this.appService.DASHBOARD_PAGE]);
                    }

                    /*else
                    {
                        this.check_user_connexion();  
                    }*/
                } 
            );
        }
        else
        {
            this.openDialog( "You have to fill login AND password", "Error", "Close" );
        }
    }


    /**
    * Check User Connexion to be sure that is password is not too old
    *
    */
    public check_user_connexion()
    {
        let d:Date = new Date();

        // 60 days in secondes
        let max_delta_time:number = 60 *60 * 24 * 180; 

        if( Math.round(d.getTime() / 1000) - Math.round( this.userService.getUser().password_date_creation.getTime() / 1000) >= max_delta_time  )
        {
           let dialogRef = this.dialog.open
            (
                UserPopupChangePasswordComponent, 
                {
                    width:  '600px'
                }
            );    

            dialogRef.afterClosed().subscribe
            (
                result => 
                {               
                    if( result )
                    {
                        if( result.success )
                        {
                            if( result.success == true )
                            {
                                this.check_user_mail();
                            }
                            else
                            {
                                this.userService.disconnect();
                            }
                        }
                        else
                        {
                            this.userService.disconnect(); 
                        }
                    }
                    else
                    {
                        this.userService.disconnect();
                    }
                }
            );
        }
        else
        {
            this.check_user_mail();
        }
    }


    /**
    * Check if the user login is a mail
    *
    */
    public check_user_mail()
    {
        if( this.validateEmail( this.userService.getUser().mail ) == true )
        {
            this.user_login = "";
            this.user_password = "";
            this.router.navigate([this.appService.DEFAULT_PAGE]);
        }
        else
        {
            let dialogRef = this.dialog.open
            (
                UserPopupChangeMailComponent, 
                {
                    width:  '600px',
                    disableClose:true
                }
            );    

            dialogRef.afterClosed().subscribe
            (
                result => 
                { 
                    this.user_login = "";
                    this.user_password = "";
                    this.router.navigate([this.appService.DEFAULT_PAGE]);
                }
            );              
        }
    }

    /**
    * Valid an email
    *
    */
    public validateEmail( email:string ) 
    {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }



    /**
    * Called when the user has forgot his password
    *
    */
    public reset_password()
    {
        let dialogRef = this.dialog.open
        (
            UserPopupResetPasswordComponent, 
            {
                width:  '600px',
                disableClose:true
            }
        );   
    }

    /*****************************************************************/
    //
    // Dialog
    //
    /*****************************************************************/

    /**
     * Open an alert message
     * 
     * @param {string} Content of the message
     * @param {string} Title of the message
     * @param {string} Label of the close button
     */
    public openDialog( content:string, title:string, closeButton:string )
    {
        this._dialogService.openAlert
        (
            {
                message: content,
                title: title, 
                closeButton: closeButton, 
            }
        );
    }

    /*****************************************************************/
    //
    // Event
    //
    /*****************************************************************/

    /**
    * Catch when the user used the Enter touch
    *
    * @param {KeyboardEvent} Key board touch
    */
    public on_keyup( evt:KeyboardEvent )
    {
        if( evt.keyCode == 13 && this.user_password != "" && this.user_password != "kylii")
        {
            this.login(true);
        }
    }
}