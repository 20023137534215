import { Component, OnInit, ViewChild }                  from '@angular/core';
import { Inject}                                         from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA}       from '@angular/material';
import { MatSnackBar }                                   from '@angular/material';
import { TdDialogService }                               from '@covalent/core';
import { ViewContainerRef }                              from '@angular/core';
import { FormBuilder }                                   from '@angular/forms';
import { FormGroup }                                     from '@angular/forms';
import { FormControl }                                   from '@angular/forms';
import { Validators }                                    from '@angular/forms';
import { AbstractControl }                               from '@angular/forms';
import { ValidatorFn }                                   from '@angular/forms';
import { ErrorStateMatcher }                             from '@angular/material/core';
import { FormGroupDirective }                            from '@angular/forms';
import { NgForm }                                        from '@angular/forms';
import { Observable }                                    from 'rxjs';
import { LoadingService }                                from '../../../../commons/loading/service/loading.service';
import { CookieService }                                 from '../../../../core/service/cookie.service';
import { AppService }                                    from '../../../../core/service/app.service';
import { User }                                          from '../../vo/user';
import { UserService }                                   from '../../service/user.service';


/**
 * User Popup Change Mail Component
 * 
 * This component is used to changed the user mail
 */
@Component(
{
    selector: 'user-popup-change-mail-component',
    templateUrl: 'user.popup.change.mail.component.html',
    styleUrls: [ 'user.popup.change.mail.component.css']
})
export class UserPopupChangeMailComponent implements OnInit
{
    /**
     * Constructor
     * 
     */
	constructor
	(
		private dialogRef: MatDialogRef<UserPopupChangeMailComponent>,
        private _dialogService: TdDialogService,
        private _viewContainerRef: ViewContainerRef,
        private snackBar: MatSnackBar,
        private cookieService: CookieService,
        private userService: UserService, 
        /**
        * Access to the Main App Service
        */
        public appService: AppService,
        private loadingService: LoadingService,
        private dialog: MatDialog,
        private fb: FormBuilder,
        /**
        * Datas pass to the component
        */
		@Inject(MAT_DIALOG_DATA) public data: any
	) 
    {
    }

    /**
    * Determin if the component is sening data or not
    */
    public DISABLE_WHEN_SENDING:boolean = false;

    /**
    * New mail
    */
    public new_mail:string = "";

    /**
    * Form control
    */
    public registerForm: FormGroup;

    /**
    * Determin if the code is sended or not
    */
    public is_code_is_send:boolean = false;

    /**
    * Code de confirmation
    */
    public confirmation_code:string;




    /*****************************************************************/
    //
    // Initialisation
    //
    /*****************************************************************/

    /**
     * Initialisation
     * 
     */
    ngOnInit(): void 
    {
        this.registerForm = this.fb.group
        (
            {
                control_mail: ['', [Validators.required, Validators.email]]
            }
        );
    }


    /*****************************************************************/
    //
    // Dialog
    //
    /*****************************************************************/

    /**
     * Open an alert message
     * 
     * @param {string} Content of the message
     * @param {string} Title of the message
     * @param {string} Label of the close button
     */
    public openDialog( content:string, title:string, closeButton:string )
    {
        this._dialogService.openAlert
        (
            {
                message: content,
                title: title, 
                closeButton: closeButton, 
            }
        );
    }

    /*****************************************************************/
    //
    // Event
    //
    /*****************************************************************/

    /**
    * Catch when the user used the Enter touch
    *
    * @param {KeyboardEvent} Key board touch
    */
    public on_keyup( evt:KeyboardEvent )
    {
        if( evt.keyCode == 13 && this.DISABLE_WHEN_SENDING == false && this.registerForm.valid == true)
        {
            if( this.is_code_is_send == true && this.confirmation_code != "" )
            {
                this.update_mail();
            }
            else
            {
                if( this.new_mail != "" )
                {
                    this.update_mail();
                }
            }
        }
    }

    /*****************************************************************/
    //
    // Popup
    //
    /*****************************************************************/

    /**
    * Called when the user clik to close the popup
    *
    */
    public close(): void 
    {
        this.dialogRef.close();
    }


    /**
    * Send a code in order to change the mail
    *
    */
    public send_mail_code()
    {
        this.DISABLE_WHEN_SENDING = true;
        
        this.userService.send_code_for_change_mail( this.userService.getUser().id, this.new_mail )
        .then
        (
            res =>
            {
                if( res == true )
                {
                    this.registerForm = this.fb.group
                    (
                        {
                            control_code: ['', [Validators.required]]
                        }
                    );

                    this.is_code_is_send = true;
                }
                else
                {
                    this.openDialog( "Fail to update email", "Error", "Close");
                }
                this.DISABLE_WHEN_SENDING = false;
            }
        );
    }

    /**
    * Update the mail
    *
    */
    public update_mail()
    {

        if( this.is_code_is_send == false )
        {
            this.send_mail_code();
        }
        else
        {
            this.DISABLE_WHEN_SENDING = true;

            this.userService.change_mail( this.userService.getUser().id, this.new_mail, this.confirmation_code )
            .then
            (
                res =>
                {
                    if( res == true )
                    {
                        this.userService.getUser().mail = this.new_mail;
                        this.cookieService.set( 'KyliiMarket_User_mail' , this.new_mail );
                        this.dialogRef.close();
                    }
                    else
                    {
                        this.openDialog( "Fail to update email", "Error", "Close");
                    }

                    this.DISABLE_WHEN_SENDING = false;
                }
            );
        }
    }
}

