import { catchError, map } from 'rxjs/operators';

import { Injectable } 								from '@angular/core';
import { Observable } 								from 'rxjs';
import { Subject } 									from 'rxjs';
import { HttpClient, HttpHeaders } 					from '@angular/common/http';
import { Router }                					from '@angular/router';

import { AppService }       						from '../../../core/service/app.service';
import { CookieService }    						from '../../../core/service/cookie.service';
import { Mail } 								    from '../vo/mail';


/**
 * Mail Service
 * 
 * Used to send mail over Internet
 */
@Injectable()
export class MailService 
{

	/**
     * Constructor
     *
     */
	constructor (
		private http: HttpClient,
		private router: Router,
		private appService: AppService,  
		private cookieService: CookieService
		) {}



	/**
     * Send mail
     *
     * @param {Mail} Value Object of Mail item
     * @return {boolean}
     */
	public send_mail( mail:Mail ):Promise<boolean>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiMarketAPI/user/send_mail/'; 

		let datas:any =
		(
			{
			    token:this.cookieService.get( 'KyliiMarket_User_id' ) + "." + this.cookieService.get( 'KyliiMarket_User_token' ),
			    mail : mail.mails[0],
			    subject : mail.subject,
			    body : mail.body
			}
		);

		return this.http
		    .post(url, JSON.stringify( datas ), {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__send_mail(response)  )
		    .catch(this.handleError__send_mail);
	}

	/**
     * Response of Send mail method
     *
     * @param {Response} Response of Web-Service
     * @return {boolean}
     */
	private extractData__send_mail(res: any) :boolean
	{
		let body = res;

		if( body.status == "ok" || body.success == true )
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	/**
     * Catch error of Send mail method
     *
     * @param {any} Error message
     * @return {any} Error message
     */
	private handleError__send_mail(error: any): Promise<any> 
	{
		console.error('An error occurred when call WS mail/send', error); 
    	return Promise.reject(error.message || error);
	}


}
