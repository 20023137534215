import { Component, OnInit, Input,  Output, EventEmitter  }      from '@angular/core';
import { UploadService }                          from '../service/upload.service';  
import { TdDialogService }                        from '@covalent/core';


/**
 * Upload - Component
 * 
 * Upload component for any datas / type of files
 */
@Component({
   	moduleId: module.id,
  	selector: 'kylii-upload',
  	templateUrl: 'upload.component.html',
  	styleUrls: [ 'upload.component.css' ]
})
export class UploadComponent implements OnInit
{
    /**
     * Event dispatched when upload is completed
     */
    @Output() onUploadCompleted = new EventEmitter();

    /**
     * Get the description from the HTML tag
     */
    @Input() description:string;

    /**
     * Get the extensions allowed from the HTML tag
     */
    @Input() accept:string = "*";

    /**
     * Get the folder where was stock the file from the HTML tag
     */
    @Input() distFolder:string;

    /**
     * Get the new filename of the file from the HTML tag
     */
    @Input() newFileName:string;


    /**
     * Set the new filename as timestamp
     */
    @Input() newFileNameIsTimestamp:boolean;

    /**
     * Get the image width needed from the HTML tag
     */
    @Input() img_width:number;

    /**
     * Get the image height needed from the HTML tag
     */
    @Input() img_height:number;

    /**
     * Link of the uploaded file
     */
    public link_to_uploaded_file:string = null;

    /**
     * Define if the component is disable or not
     */
    public disabled:boolean = false;


    /**
     * Description
     */
    public description_tmp:string;


    /**
     * Constructor
     * 
     */
    constructor
    (
        private uploadService: UploadService,
        private _dialogService: TdDialogService
    ) {}


    /**
     * Initialisation
     * 
     */
    ngOnInit(): void 
    {
        this.description_tmp = this.description;
    }

    /**
     * Open an alert message
     * 
     * @param {string} Content of the message
     * @param {string} Title of the message
     * @param {string} Label of the close button
     */
    public openDialog( content:string, title:string, closeButton:string )
    {
        this._dialogService.openAlert
        (
            {
                message: content,
                title: title, 
                closeButton: closeButton, 
            }
        );
    }


    /**
     * Catch event when a file is selected
     * 
     * @param {File} File selected
     */
    public selectEvent(file: File): void 
    {
        if( this.newFileNameIsTimestamp == true )
        {
            this.newFileName = new Date().getTime().toString();
        }
    }

    /**
     * Catch event when a file is uploaded
     * 
     * @param {File} File selected
     */
    public uploadEvent(file: File): void 
    {
        this.disabled = true;
        this.description = "Loading...";

        let fname:string = this.newFileName;

        if( this.newFileNameIsTimestamp == true )
        {
            fname = new Date().getTime().toString();
        }
        
        this.uploadService.upload( file, this.distFolder,fname, this.img_width, this.img_height ).then
        (
            res =>
            {
                this.description = this.description_tmp;
                this.disabled = false;
                if( res.success == false )
                {
                    this.link_to_uploaded_file = null;
                    this.openDialog( res.message, "Error", "Close" );
                }
                else
                {
                    this.link_to_uploaded_file = res.message;
                    this.onUploadCompleted.emit( this.link_to_uploaded_file );
                }
            }
        );
    }

    /**
     * Catch event when a upload is canceled
     * 
     */
    public cancelEvent():void
    {

    }
}