<div *ngIf="this.appService.IS_MOBILE == false" >
	<div class="kylii-popup-header" >
		<span i18n>Informations</span>
	</div>
	<div class="kylii-popup-content" class="content-list" >
		<p i18n>Votre configuration a bien été prise en compte. Vos modifications s'effectueront lors du prochain démarrage de votre système. 
		<br><br>
		Toutefois vous pouvez forcer cette mise à jour maintenant. Votre jeu sera inaccessibile le temps de la mise à jour.</p>
	</div>
	<mat-divider></mat-divider>
	<div class="kylii-popup-footer" >
		<button mat-button color="accent" (click)="on_tomorrow()" i18n>Garder la mise à jour pour demain</button>
		<button mat-button color="warn" (click)="on_now()" i18n>Forcer la mise à jour immédiatement</button>
	</div>
</div>

<div *ngIf="this.appService.IS_MOBILE == true" class="popup-mobile" >
	<div class="kylii-popup-header-mobile" >
		<span i18n>Informations</span>
	</div>
	<div class="kylii-popup-content" class="content-list" >
		<p i18n>Votre configuration a bien été prise en compte. Vos modifications s'effectueront lors du prochain démarrage de votre système. 
		<br><br>
		Toutefois vous pouvez forcer cette mise à jour maintenant. Votre jeu sera inaccessibile le temps de la mise à jour.</p>
	</div>
	<mat-divider></mat-divider>
	<div class="kylii-popup-footer" >
		<button mat-button color="accent" (click)="on_tomorrow()" i18n>Garder la mise à jour pour demain</button>
		<button mat-button color="warn" (click)="on_now()" i18n>Forcer la mise à jour immédiatement</button>
	</div>
</div>
