import { Component, OnInit, ViewChild }              from '@angular/core';
import { Inject}                                     from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA}   from '@angular/material';
import { MatSnackBar }                               from '@angular/material';

import { TdDialogService }                           from '@covalent/core';
import { ViewContainerRef }                          from '@angular/core';

import {FormControl}                                 from '@angular/forms';
import {Observable}                                  from 'rxjs';

import { AppService }                                from '../../../../../core/service/app.service';

import { License }                                   from '../../../vo/license';
import { LicenseService }                            from '../../../service/license.service';

/**
 * License Popup Add Application Component
 * 
 * This component is used to tell to the user that the application is added
 */
@Component(
{
    selector: 'license-popup-add-application-component',
    templateUrl: 'license.popup.add.application.component.html',
    styleUrls: [ 'license.popup.add.application.component.css']
})
export class LicensePopupAddApplicationComponent implements OnInit
{
    /**
     * Constructor
     * 
     */
	constructor
	(
		private dialogRef: MatDialogRef<LicensePopupAddApplicationComponent>,
        private _dialogService: TdDialogService,
        private licenseService:LicenseService,
        private _viewContainerRef: ViewContainerRef,
        private snackBar: MatSnackBar,
        /**
        * Access to the Main App Service
        */
        public appService: AppService, 
        private dialog: MatDialog,
        /**
        * Datas pass to the component
        */
		@Inject(MAT_DIALOG_DATA) public data: any
	) 
    {
    }

    /**
    * Index for the Loading component
    */
    public index_loading:number;


    /*****************************************************************/
    //
    // Initialisation
    //
    /*****************************************************************/

    /**
     * Initialisation
     * 
     */
    ngOnInit(): void 
    {
       
    }


    /*****************************************************************/
    //
    // Popup
    //
    /*****************************************************************/

    /**
    * Called when the user clik outside the popup
    *
    */
    onNoClick(): void 
    {
        this.dialogRef.close();
    }

    /**
    * Close the popup
    *
    */
    public on_ok():void
    {
        this.dialogRef.close();
    }
}

