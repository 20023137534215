<div *ngIf="this.appService.IS_MOBILE == false" >
	<div class="kylii-popup-header" >
		<span i18n>Changez votre mail</span>
	</div>
	<div *ngIf="is_code_is_send == false" class="kylii-popup-content" class="content-list" >
		<p i18n>
			Nous avons détecté que votre login n'était pas votre mail, voulez-vous changer votre login et le remplacer par votre mail ?
			<br>
			Cela sera utile en cas de perte de mot de passe.
		</p>
		<mat-grid-list cols="1" rowHeight="70px" [formGroup]="registerForm">
			<mat-grid-tile colspan="1" rowspan="1" >
				<mat-form-field color="accent" flex="90">
					<input i18n matInput type="email" i18n-placeholder placeholder="Votre mail"  [(ngModel)]="new_mail" formControlName="control_mail" required (keyup)="on_keyup($event)">
					<mat-error i18n *ngIf="registerForm.hasError('email')">Ceci n'est pas un mail valide</mat-error>
				</mat-form-field>
			</mat-grid-tile>
		</mat-grid-list>
	</div>
	<div *ngIf="is_code_is_send == true" class="kylii-popup-content" class="content-list" >
		<p i18n>
			Nous venons de vous envoyer un code de confirmation à ce mail <span class="important-words">({{new_mail}})</span>. Il se peut que le mail soit arrivé dans votre boîte de spam.
			<br>
			<br>
			Veuillez renseigner le champ suivant avec le code que vous avez reçu.		
		</p>
		<mat-grid-list cols="1" rowHeight="70px" [formGroup]="registerForm">
			<mat-grid-tile colspan="1" rowspan="1" >
				<mat-form-field color="accent" flex="90">
					<input i18n matInput i18n-placeholder placeholder="Votre code de confirmation"  [(ngModel)]="confirmation_code" formControlName="control_code" (keyup)="on_keyup($event)" >
					<mat-error i18n *ngIf="registerForm.hasError('required')">Vous devez renseigner votre code de confirmation</mat-error>
				</mat-form-field>
			</mat-grid-tile>
		</mat-grid-list>
	</div>
	<mat-divider></mat-divider>
	<div class="kylii-popup-footer" >
		<button mat-button color="accent"  [disabled]="DISABLE_WHEN_SENDING || !registerForm.valid " (click)="update_mail()" i18n>Mettre à jour</button>
		<button mat-button  [disabled]="DISABLE_WHEN_SENDING " (click)="close()" i18n>Fermer</button>
	</div>
</div>

<div *ngIf="this.appService.IS_MOBILE == true" class="popup-mobile" >
	<div class="kylii-popup-header-mobile" >
		<span i18n>Changez votre mail</span>
	</div>
	<div *ngIf="is_code_is_send == false" class="kylii-popup-content" class="content-list" >
		<p i18n>
			Nous avons détecté que votre login n'était pas votre mail, voulez vous changer votre login et le remplacer par votre mail ?
			<br>
			Cela sera utile en cas de perte de mot de passe.
		</p>
		<mat-grid-list cols="1" rowHeight="70px" [formGroup]="registerForm">
			<mat-grid-tile colspan="1" rowspan="1" >
				<mat-form-field color="accent" flex="90">
					<input i18n matInput type="email" i18n-placeholder placeholder="Votre mail"  [(ngModel)]="new_mail" formControlName="control_mail" (keyup)="on_keyup($event)">
					<mat-error i18n *ngIf="registerForm.hasError('email')">Ceci n'est pas un mail valide</mat-error>
				</mat-form-field>
			</mat-grid-tile>
		</mat-grid-list>
	</div>
	<div *ngIf="is_code_is_send == true" class="kylii-popup-content" class="content-list" >
		<p i18n>
			Nous venons de vous envoyer un code de confirmation à ce mail <span class="important-words">({{new_mail}})</span>. Il se peut que le mail soit arrivé dans votre boîte de spam.
			<br>
			<br>
			Veuillez renseigner le champ suivant avec le code que vous avez reçu.		
		</p>
		<mat-grid-list cols="1" rowHeight="70px" [formGroup]="registerForm">
			<mat-grid-tile colspan="1" rowspan="1" >
				<mat-form-field color="accent" flex="90">
					<input i18n matInput i18n-placeholder placeholder="Votre code de confirmation"  [(ngModel)]="confirmation_code" formControlName="control_code" (keyup)="on_keyup($event)">
					<mat-error i18n *ngIf="registerForm.hasError('required')">Vous devez renseigner votre code de confirmation</mat-error>
				</mat-form-field>
			</mat-grid-tile>
		</mat-grid-list>
	</div>
	<mat-divider></mat-divider>
	<div class="kylii-popup-footer" >
		<button mat-button color="accent"  [disabled]="DISABLE_WHEN_SENDING || !registerForm.valid " (click)="update_mail()" i18n>Mettre à jour</button>
		<button mat-button  [disabled]="DISABLE_WHEN_SENDING " (click)="close()" i18n>Fermer</button>
	</div>
</div>
