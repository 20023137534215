/**
 * Upload Value Object
 * 
 */
export class Upload
{
	/**
	 * Success or not of the upload
	 */
	success:boolean;

	/**
	 * Message of the upload (link or error message)
	 */
	message:string;
}