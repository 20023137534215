<div *ngIf="this.appService.IS_MOBILE == false" >
	<div class="kylii-popup-header" >
		<span i18n>Changez votre mot de passe</span>
	</div>
	<div class="kylii-popup-content" class="content-list" >
		<td-message *ngIf="same_password == true" i18n-label label="Erreur" i18n-sublabel sublabel="Vous avez déjà utilisé ce mot de passe, veuillez en choisir un nouveau." color="warn" icon="info"></td-message>
		<p i18n>
			Pour des raisons de sécurité, merci de changer de mot de passe.
		</p>
		<mat-grid-list cols="1" rowHeight="70px" [formGroup]="registerForm">
			<mat-grid-tile colspan="1" rowspan="1" >
				<mat-form-field color="accent" flex="90">
					<input i18n matInput type="password" i18n-placeholder placeholder="Nouveau mot de passe"  [(ngModel)]="new_password" formControlName="control_new_password" (keyup)="on_keyup($event)">
					<mat-error i18n *ngIf="registerForm.hasError('minlength')">Le mot de passe doit contenir au minimum 8 charactères</mat-error>
				</mat-form-field>
			</mat-grid-tile>
			<mat-grid-tile colspan="1" rowspan="1" >
				<mat-form-field color="accent" flex="90">
					<input i18n matInput  type="password" i18n-placeholder placeholder="Confirmer votre nouveau mot de passe" [(ngModel)]="new_password_confirmation" formControlName="control_new_password_confirmation" [errorStateMatcher]="matcher" (keyup)="on_keyup($event)">
					<mat-error i18n *ngIf="registerForm.hasError('MissMatchingPassword')">Les deux mots de passe ne sont pas identiques</mat-error>
				</mat-form-field>
			</mat-grid-tile>
		</mat-grid-list>
	</div>
	<mat-divider></mat-divider>
	<div class="kylii-popup-footer" >
		<button mat-button color="accent"  [disabled]="DISABLE_WHEN_SENDING || new_password == '' || new_password_confirmation == '' || !registerForm.valid " (click)="update_password()" i18n>Mettre à jour</button>
	</div>
</div>


<div *ngIf="this.appService.IS_MOBILE == true" class="popup-mobile" >
	<div class="kylii-popup-header-mobile" >
		<span i18n>Changez votre mot de passe</span>
	</div>
	<div class="kylii-popup-content" class="content-list" >
		<td-message *ngIf="same_password == true" i18n-label label="Erreur" i18n-sublabel sublabel="Vous avez déjà utilisé ce mot de passe, veuillez en choisir un nouveau." color="warn" icon="info"></td-message>
		<p i18n>
			Pour des raisons de sécurité, merci de changer de mot de passe.
		</p>
		<mat-grid-list cols="1" rowHeight="70px" [formGroup]="registerForm">
			<mat-grid-tile colspan="1" rowspan="1" >
				<mat-form-field color="accent" flex="90">
					<input i18n matInput type="password" i18n-placeholder placeholder="Nouveau mot de passe"  [(ngModel)]="new_password" formControlName="control_new_password" required (keyup)="on_keyup($event)">
					<mat-error i18n *ngIf="registerForm.hasError('minlength')">Le mot de passe doit contenir au minimum 8 charactères</mat-error>
				</mat-form-field>
			</mat-grid-tile>
			<mat-grid-tile colspan="1" rowspan="1" >
				<mat-form-field color="accent" flex="90">
					<input i18n matInput  type="password" i18n-placeholder placeholder="Confirmer votre nouveau mot de passe" [(ngModel)]="new_password_confirmation" formControlName="control_new_password_confirmation" [errorStateMatcher]="matcher" (keyup)="on_keyup($event)">
					<mat-error i18n *ngIf="registerForm.hasError('MissMatchingPassword')">Les deux mots de passe ne sont pas identiques</mat-error>
				</mat-form-field>
			</mat-grid-tile>
		</mat-grid-list>
	</div>
	<mat-divider></mat-divider>
	<div class="kylii-popup-footer" >
		<button mat-button color="accent"  [disabled]="DISABLE_WHEN_SENDING || new_password == '' || new_password_confirmation == '' || !registerForm.valid " (click)="update_password()" i18n>Mettre à jour</button>
	</div>
</div>