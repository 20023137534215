<div *ngIf="this.appService.IS_MOBILE == false" >
	<div class="kylii-popup-header" >
		<span i18n>Informations</span>
	</div>
	<div class="kylii-popup-content" class="content-list" >
		<p i18n>
			L'application a bien été ajouté dans votre programme.<br>
			Par défaut elle a été mise en fin de programme<span *ngIf="data.license.module.id != 3 && data.license.module.id != 4"> et a une durée de 3 min</span>.
		</p>
	</div>
	<mat-divider></mat-divider>
	<div class="kylii-popup-footer" >
		<button mat-button color="accent" (click)="on_ok()">Ok</button>
	</div>
</div>

<div *ngIf="this.appService.IS_MOBILE == true" class="popup-mobile" >
	<div class="kylii-popup-header-mobile" >
		<span i18n>Informations</span>
	</div>
	<div class="kylii-popup-content" class="content-list" >
		<p i18n>
			L'application a bien été ajouté dans votre programme.<br>
			Par défaut elle a été mise en fin de programme<span *ngIf="data.license.module.id != 3 && data.license.module.id != 4"> et a une durée de 3 min</span>.
		</p>
	</div>
	<mat-divider></mat-divider>
	<div class="kylii-popup-footer" >
		<button mat-button color="accent" (click)="on_ok()">Ok</button>
	</div>
</div>
