/**
* Module Value Object
*
*/
export class Module
{
	/**
	* Module's id
	*/
	id:number;
	
	/**
	* Module's label
	*/
	label:string;

	/**
	* Module's portage
	*/
	portage:string;
}