<div *ngIf="this.appService.IS_MOBILE == false" >
	<div class="kylii-popup-header" >
		<span i18n>Information sur la connexion</span>
	</div>
	<div class="kylii-popup-content" class="content-list" >
		<div *ngIf="data.license.connected == true" >
			<p i18n>Votre produit est bien connecté à Internet.</p>
		</div>
		<div *ngIf="data.license.connected == false" >
			<p i18n>Nous avons détecté que vous n'aviez pas de connexion Internet sur ce produit. Tant que votre produit ne sera pas mis en ligne, les modifications ne pourront pas être installées.
			<br><br>
			La connexion Internet est nécéssaire pour la <span class="important-words">mise à jour de vos contenus</span> et permet d'accélérer les <span class="important-words">processus de  maintenance</span></p>
			<br>
			<div i18n class="explication-title" >Comment configurer votre connexion :</div>
			<ol>
				<li i18n>Branchez un clavier USB au connecteur USB du PC</li>
				<li i18n>Faites « ALT + C » pour ouvrir le configurateur</li>
				<li i18n>Pour une connexion câblée, choisissez « LAN »</li>
				<li i18n>Choisissez votre type de connexion « AUTO » ou « MANUAL »</li>
				<li i18n>Si vous avez choisi « MANUAL », complétez les champs requis</li>
				<li i18n>Puis sélectionnez « EXIT » pour fermer le configurateur</li>
			</ol>
		</div>
	</div>
	<mat-divider></mat-divider>
	<div class="kylii-popup-footer" >
		<button mat-button color="accent" (click)="on_ok()">Ok</button>
	</div>
</div>


<div *ngIf="this.appService.IS_MOBILE == true" class="popup-mobile" >
	<div class="kylii-popup-header-mobile" >
		<span i18n>Information sur la connexion</span>
	</div>
	<div class="kylii-popup-content" class="content-list" >
		<div *ngIf="data.license.connected == true" >
			<p i18n>Votre produit est bien connecté à Internet.</p>
		</div>
		<div *ngIf="data.license.connected == false" >
			<p i18n>Nous avons détecté que vous n'aviez pas de connexion Internet sur ce produit. Tant que votre produit ne sera pas mis en ligne, les modifications ne pourront pas être installées.
			<br><br>
			La connexion Internet est nécéssaire pour la <span class="important-words">mise à jour de vos contenus</span> et permet d'accélérer les <span class="important-words">processus de  maintenance</span></p>
			<br>
			<div i18n class="explication-title-mobile" >Comment configurer votre connexion :</div>
			<ol>
				<li class="li-mobile" i18n>Branchez un clavier USB au connecteur USB du PC</li>
				<li class="li-mobile" i18n>Faites « ALT + C » pour ouvrir le configurateur</li>
				<li class="li-mobile" i18n>Pour une connexion câblée, choisissez « LAN »</li>
				<li class="li-mobile" i18n>Choisissez votre type de connexion « AUTO » ou « MANUAL »</li>
				<li class="li-mobile" i18n>Si vous avez choisi « MANUAL », complétez les champs requis</li>
				<li class="li-mobile" i18n>Puis sélectionnez « EXIT » pour fermer le configurateur</li>
			</ol>
		</div>
	</div>
	<mat-divider></mat-divider>
	<div class="kylii-popup-footer" >
		<button mat-button color="accent" (click)="on_ok()">Ok</button>
	</div>
</div>




