
<img *ngIf="this.appService.IS_DESKTOP == true" class="blue-bubble" src="assets/commons/bulle-bleu-logo.png">
<h1  *ngIf="this.appService.IS_DESKTOP == true" class="list-title" i18n>Bienvenue dans votre espace personnel</h1>
<div *ngIf="this.appService.IS_DESKTOP == true" class="list-subtitle" i18n>Paramétrez vos solutions Kylii Kids</div>

<h1  *ngIf="this.appService.IS_DESKTOP == false" class="list-title-mobile" i18n>Bienvenue dans votre espace personnel</h1>
<div *ngIf="this.appService.IS_DESKTOP == false" class="list-subtitle-mobile" i18n>Paramétrez vos solutions Kylii Kids</div>

<div class="product-list" *ngIf="this.appService.IS_MOBILE == false" >
	<div class="product-thumb" *ngFor="let license of licenses" [ngClass]="license.custom_class">
		<img class="product-thumb-column product-thumb-logo-list" src="{{license.thumb}}">
		<div class="product-thumb-column product-thumb-module-list" [ngStyle]="{'color':license.background_color}">{{license.module.label}}</div>
		<div class="product-thumb-column product-thumb-label-main" routerLink="/license/edit/{{license.id}}">{{license.label}}</div>
		<button *ngIf="license.connected == true" class="product-thumb-column product-thumb-button-connection-ok-main" mat-icon-button (click)="on_click_connection(license)" ><mat-icon>wifi</mat-icon></button>
		<button *ngIf="license.connected == false" class="product-thumb-column product-thumb-button-connection-ko-main" mat-icon-button (click)="on_click_connection(license)" ><mat-icon>perm_scan_wifi</mat-icon></button>
		<button class="product-thumb-column product-thumb-button-settings-main" mat-icon-button routerLink="/license/edit/{{license.id}}" ><mat-icon class="product-thumb-button-settings-icon">settings</mat-icon></button>
		<mat-divider class="product-thumb-divider"></mat-divider>
	</div>
</div>


<div class="product-list-mobile" *ngIf="this.appService.IS_MOBILE == true" >
	<div class="product-thumb" *ngFor="let license of licenses" [ngClass]="license.custom_class">
		<img class="product-thumb-column product-thumb-logo-mobile-list" src="{{license.thumb}}">
		<div class="product-thumb-column product-thumb-module-mobile-list" [ngStyle]="{'color':license.background_color}">{{license.module.label}}</div>
		
		<button *ngIf="license.connected == true" class="product-thumb-column product-thumb-button-connection-ok-mobile" mat-icon-button (click)="on_click_connection(license)" ><mat-icon>wifi</mat-icon></button>
		<button *ngIf="license.connected == false" class="product-thumb-column product-thumb-button-connection-ko-mobile" mat-icon-button (click)="on_click_connection(license)" ><mat-icon>perm_scan_wifi</mat-icon></button>
		<button class="product-thumb-column product-thumb-button-settings-mobile" mat-icon-button routerLink="/license/edit/{{license.id}}" ><mat-icon class="product-thumb-button-settings-icon">settings</mat-icon></button>
		
		<div class="product-thumb-label-mobile">{{license.label}}</div>
		<mat-divider class="product-thumb-divider-mobile"></mat-divider>
	</div>
</div>
