import { NgModule } 				from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA }   from '@angular/core';
import { ModuleWithProviders } 		from '@angular/core';

import { MatButtonModule }                                      from '@angular/material';
import { MatCheckboxModule }                                      from '@angular/material';
import { MatSelectModule }                                         from '@angular/material';
import { MatSnackBarModule }                                     from '@angular/material';
import { MatMenuModule }                                        from '@angular/material';
import { MatInputModule }                                        from '@angular/material';
import { MatIconModule }                                         from '@angular/material';
import { MatDatepickerModule }                                     from '@angular/material';
import { MatFormFieldModule }                                     from '@angular/material';
import { MatRadioModule }                                         from '@angular/material';
import { MatSidenavModule }                                     from '@angular/material';
import { MatToolbarModule }                                     from '@angular/material';
import { MatListModule }                                         from '@angular/material';
import { MatGridListModule }                                     from '@angular/material';
import { MatCardModule }                                         from '@angular/material';
import { MatStepperModule }                                     from '@angular/material';
import { MatTabsModule }                                         from '@angular/material';
import { MatExpansionModule }                                     from '@angular/material';
import { MatButtonToggleModule }                                 from '@angular/material';
import { MatChipsModule }                                         from '@angular/material';
import { MatProgressSpinnerModule }                             from '@angular/material';
import { MatProgressBarModule }                                 from '@angular/material';
import { MatDialogModule }                                         from '@angular/material';
import { MatTooltipModule }                                     from '@angular/material';
import { MatTableModule }                                         from '@angular/material';
import { MatSortModule }                                         from '@angular/material';
import { MatPaginatorModule }                                     from '@angular/material';
import { MatAutocompleteModule }                                 from '@angular/material';
import { MatNativeDateModule }                                     from '@angular/material';

import { ReactiveFormsModule }                                     from '@angular/forms';

import { CovalentLayoutModule }                                    from '@covalent/core';
import { CovalentCommonModule }                                 from '@covalent/core';
import { CovalentStepsModule }                                    from '@covalent/core';
import { CovalentDataTableModule }                                 from '@covalent/core';
import { CovalentDialogsModule }                                 from '@covalent/core';
import { CovalentExpansionPanelModule }                         from '@covalent/core'; 
import { CovalentPagingModule }                                 from '@covalent/core';
import { CovalentNotificationsModule }                             from '@covalent/core';
import { CovalentSearchModule }                                 from '@covalent/core';
import { CovalentMessageModule }                                 from '@covalent/core';
import { CovalentJsonFormatterModule }                             from '@covalent/core';
import { CovalentVirtualScrollModule }                             from '@covalent/core';
import { CovalentFileModule }                                     from '@covalent/core';
import { CovalentHttpModule }                                   from '@covalent/http';
import { CovalentHighlightModule }                              from '@covalent/highlight';
import { CovalentMarkdownModule }                               from '@covalent/markdown';
import { CovalentDynamicFormsModule }                           from '@covalent/dynamic-forms';
import { CovalentCodeEditorModule }                             from '@covalent/code-editor';

import { AppService }               from '../service/app.service';
import { CookieService }            from '../service/cookie.service';
import { UploadComponent }          from '../../commons/upload/component/upload.component';
import { SaveComponent }            from '../../commons/save/component/save.component';

/**
 * Common NgModule
 * 
 */
@NgModule(
    {    
        imports: 
        [
            // Material
            MatButtonModule, 
            MatCheckboxModule,
            MatSelectModule,
            MatSnackBarModule,
            MatMenuModule,
            MatInputModule,
            MatIconModule,
            MatDatepickerModule,
            MatFormFieldModule,
            MatRadioModule,
            MatSidenavModule,
            MatToolbarModule,
            MatListModule,
            MatGridListModule,
            MatCardModule,
            MatStepperModule,
            MatTabsModule,
            MatExpansionModule,
            MatButtonToggleModule,
            MatChipsModule,
            MatProgressSpinnerModule,
            MatProgressBarModule,
            MatDialogModule,
            MatTooltipModule,
            MatTableModule,
            MatSortModule,
            MatPaginatorModule,
            MatAutocompleteModule,
            MatNativeDateModule,
            ReactiveFormsModule,

            // Convalent
            CovalentLayoutModule,
            CovalentCommonModule,
            CovalentStepsModule,
            CovalentHttpModule.forRoot(),
            CovalentHighlightModule,
            CovalentMarkdownModule,
            CovalentDynamicFormsModule,
            CovalentDataTableModule,
            CovalentDialogsModule,
            CovalentExpansionPanelModule,
            CovalentPagingModule,
            CovalentNotificationsModule,
            CovalentSearchModule,
            CovalentMessageModule,
            CovalentCodeEditorModule,
            CovalentJsonFormatterModule,
            CovalentVirtualScrollModule,
            CovalentFileModule
        ],
        declarations: 
        [ 
            UploadComponent,
            SaveComponent
        ],
        exports: 
        [
            UploadComponent,
            SaveComponent
        ],
        schemas: 
        [ 
            CUSTOM_ELEMENTS_SCHEMA 
        ]
    }
)

/**
 * Shared Module - used to share module between Chunk part
 * 
 */
export class SharedModule 
{

    /**
     * Retrieve Modules
     * 
     */
    static forRoot(): ModuleWithProviders 
    {
    	return {
      		ngModule: SharedModule,
      		providers: 
            [
                CookieService,
    			AppService
    		]
    	};
	}
}



