<div *ngIf="this.appService.IS_MOBILE == false" >
	<div class="support-popup" >
		<img class="header-image" src="assets/commons/header-titre-support.png">
		<img class="footer-image" src="assets/commons/footer-envoyer.png">

		<div class="support-popup-title" i18n>Contactez votre support</div>
		

		<div class="kylii-popup-content" class="content-list" >
			<div class="support-popup-subtitle" i18n>Vous rencontrez un problème technique sur l'un de vos dispositif ?<br>Remplissez le formulaire ci-dessus et nous vous répondrons dans les plus bref délais.</div>
			<mat-grid-list cols="4" rowHeight="80px" class="support-form">
				<mat-grid-tile colspan="2" rowspan="1" class="kylii-mat-grid-tile">
					<mat-form-field color="accent" flex="90">
						<input matInput i18n-placeholder  placeholder="Prénom" [(ngModel)]="forename" [formControl]="forenameFormControl" (keyup)="on_keyup($event)">
						<mat-error *ngIf="forenameFormControl.hasError('required')" i18n>
					    	Votre prénom est <strong>obligatoire</strong>
					    </mat-error>
					</mat-form-field>
				</mat-grid-tile>
				<mat-grid-tile colspan="2" rowspan="1" class="kylii-mat-grid-tile" [disabled]="DISABLE_WHEN_SENDING">
					<mat-form-field color="accent" flex="90">
						<input matInput i18n-placeholder  placeholder="Nom" [(ngModel)]="lastname" [formControl]="lastnameFormControl" (keyup)="on_keyup($event)">
						<mat-error *ngIf="lastnameFormControl.hasError('required')" i18n>
					    	Votre nom est <strong>obligatoire</strong>
					    </mat-error>
					</mat-form-field>
				</mat-grid-tile>
				
				<mat-grid-tile colspan="2" rowspan="1" class="kylii-mat-grid-tile">
					<mat-form-field color="accent" flex="90">
						<input type="email" matInput i18n-placeholder placeholder="Mail" [(ngModel)]="email" [formControl]="emailFormControl" (keyup)="on_keyup($event)">
						<mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')" i18n>
							Votre mail n'est pas valide
					    </mat-error>
					    <mat-error *ngIf="emailFormControl.hasError('required')" i18n>
					    	Votre mail est <strong>obligatoire</strong>
					    </mat-error>
					</mat-form-field>
				</mat-grid-tile>
				<mat-grid-tile colspan="2" rowspan="1" class="kylii-mat-grid-tile">
					<mat-form-field color="accent" flex="90">
						<input type="tel" matInput i18n-placeholder  placeholder="Téléphone"  [(ngModel)]="phone_number" [formControl]="phoneNumberFormControl" (keyup)="on_keyup($event)">
						<mat-error *ngIf="phoneNumberFormControl.hasError('pattern')" i18n>
					    	Votre numéro de téléphone n'est pas valide
					    </mat-error>
					    <mat-error *ngIf="phoneNumberFormControl.hasError('required')" i18n>
					    	Votre numéro de téléphone est <strong>obligatoire</strong>
					    </mat-error>
					</mat-form-field>
				</mat-grid-tile>

				<mat-grid-tile colspan="4" rowspan="1" class="kylii-mat-grid-tile">
					<mat-form-field color="accent" flex="95">
						<input matInput i18n-placeholder  placeholder="Objet" [(ngModel)]="subject" [formControl]="subjectFormControl" (keyup)="on_keyup($event)">
						<mat-error *ngIf="subjectFormControl.hasError('required')" i18n>
					    	L'objet du mail est <strong>obligatoire</strong>
					    </mat-error>
					</mat-form-field>
				</mat-grid-tile>

				<mat-grid-tile colspan="4" rowspan="2" class="kylii-mat-grid-tile">
					<mat-form-field color="accent" flex="95">
						<textarea class="mail-content" matInput i18n-placeholder  placeholder="Contenu" [(ngModel)]="content" [formControl]="contentFormControl" (keyup)="on_keyup($event)"></textarea >
						<mat-error *ngIf="contentFormControl.hasError('required')" i18n>
					    	Le contenu du mail est <strong>obligatoire</strong>
					    </mat-error>
					</mat-form-field>
				</mat-grid-tile>
			</mat-grid-list>
		</div>
		<div style="position: absolute; left: 50%; bottom: 30px;">
			<button class="support-button-send" mat-raised-button color="basic"  [disabled]="DISABLE_WHEN_SENDING || !allow_send()" (click)="on_send()" i18n>Envoyer</button>
		</div>
	</div>
</div>



<div *ngIf="this.appService.IS_MOBILE == true" class="popup-mobile" class="popup-support-mobile" >
	<div class="kylii-popup-header-mobile" >
		<span i18n>Contactez votre support</span>
	</div>
	<div class="kylii-popup-content" class="content-list" >
		<mat-grid-list cols="4" rowHeight="90px" class="support-form">
			<mat-grid-tile colspan="2" rowspan="1" class="kylii-mat-grid-tile">
				<mat-form-field color="accent" flex="90">
					<input matInput i18n-placeholder  placeholder="Prénom" [(ngModel)]="forename" [formControl]="forenameFormControl" (keyup)="on_keyup($event)">
					<mat-error *ngIf="forenameFormControl.hasError('required')" i18n>
				    	Votre prénom est <strong>obligatoire</strong>
				    </mat-error>
				</mat-form-field>
			</mat-grid-tile>
			<mat-grid-tile colspan="2" rowspan="1" class="kylii-mat-grid-tile" [disabled]="DISABLE_WHEN_SENDING">
				<mat-form-field color="accent" flex="90">
					<input matInput i18n-placeholder  placeholder="Nom" [(ngModel)]="lastname" [formControl]="lastnameFormControl" (keyup)="on_keyup($event)">
					<mat-error *ngIf="lastnameFormControl.hasError('required')" i18n>
				    	Votre nom est <strong>obligatoire</strong>
				    </mat-error>
				</mat-form-field>
			</mat-grid-tile>
			
			<mat-grid-tile colspan="2" rowspan="1" class="kylii-mat-grid-tile">
				<mat-form-field color="accent" flex="90">
					<input type="email" matInput i18n-placeholder placeholder="Mail" [(ngModel)]="email" [formControl]="emailFormControl" (keyup)="on_keyup($event)">
					<mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')" i18n>
						Votre mail n'est pas valide
				    </mat-error>
				    <mat-error *ngIf="emailFormControl.hasError('required')" i18n>
				    	Votre mail est <strong>obligatoire</strong>
				    </mat-error>
				</mat-form-field>
			</mat-grid-tile>
			<mat-grid-tile colspan="2" rowspan="1" class="kylii-mat-grid-tile">
				<mat-form-field color="accent" flex="90">
					<input type="tel" matInput i18n-placeholder  placeholder="Téléphone"  [(ngModel)]="phone_number" [formControl]="phoneNumberFormControl" (keyup)="on_keyup($event)">
					<mat-error *ngIf="phoneNumberFormControl.hasError('pattern')" i18n>
					    	Votre numéro de téléphone n'est pas valide
					</mat-error>
					<mat-error *ngIf="phoneNumberFormControl.hasError('required')" i18n>
				    	Votre numéro de téléphone est <strong>obligatoire</strong>
				    </mat-error>
				</mat-form-field>
			</mat-grid-tile>

			<mat-grid-tile colspan="4" rowspan="1" class="kylii-mat-grid-tile">
				<mat-form-field color="accent" flex="95">
					<input matInput i18n-placeholder  placeholder="Objet" [(ngModel)]="subject" [formControl]="subjectFormControl" (keyup)="on_keyup($event)">
					<mat-error *ngIf="subjectFormControl.hasError('required')" i18n>
				    	L'objet du mail est <strong>obligatoire</strong>
				    </mat-error>
				</mat-form-field>
			</mat-grid-tile>

			<mat-grid-tile colspan="4" rowspan="2" class="kylii-mat-grid-tile">
				<mat-form-field color="accent" flex="95">
					<textarea class="mail-content" matInput i18n-placeholder  placeholder="Contenu" [(ngModel)]="content" [formControl]="contentFormControl" (keyup)="on_keyup($event)"></textarea >
					<mat-error *ngIf="contentFormControl.hasError('required')" i18n>
				    	Le contenu du mail est <strong>obligatoire</strong>
				    </mat-error>
				</mat-form-field>
			</mat-grid-tile>
		</mat-grid-list>
	</div>
	<mat-divider></mat-divider>
	<div class="kylii-popup-footer" >
		<button mat-raised-button color="basic"  [disabled]="DISABLE_WHEN_SENDING  || !allow_send()" (click)="on_send()" i18n>Envoyer</button>
	</div>
</div>
