/**
* Value Object of Mail
*
*/
export class Mail
{
	/**
	* email(s) of the recipent(s)
	*/
	mails:string[];
	
	/**
	* email of the sender
	*/
	sender:string;

	/**
	* subject of the email
	*/
	subject:string;

	/**
	* body content of the email
	*/
	body:string;

	/**
	* attachmements of the email - optional
	*/
	attachments:string[];
}