import { Language } from '../../common/vo/language';

/**
* Application Details Value Object
*
*/
export class ApplicationDetails
{
	/**
	* Application detail's id
	*/
	id:number;

	/**
	* Application detail's Language
	*/
	language:Language;

	/**
	* Application detail's Label
	*/
	label:string;

	/**
	* Application detail's Description
	*/
	description:string;
}