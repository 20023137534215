import { Application }			from './application';
import { ApplicationPackage }	from './application.package';

/**
*License Content Value Object
*
*/
export class LicenseContent
{
	/**
	* License id
	*/
	license_id:number;

	/**
	* List of applications for the market
	*/
	market_applications:Application[];

	/**
	* List of applications for the program
	*/
	program_applications:Application[];

	/**
	* List of applications packages for the market
	*/
	market_applications_packages:ApplicationPackage[];

	/**
	* List of applications packages for the program
	*/
	program_applications_packages:ApplicationPackage[];


	/**
	* Base Program
	*/
	program:LicenseContent

	/**
	* Token
	*/
	token:string;
}