import { Injectable, Component, ViewContainerRef, ComponentFactoryResolver, ViewChild} from '@angular/core';

import { LoadingComponent } from '../component/loading.component';

/**
 * The Loading Service
 * 
 * Provider methods which can be called all over the application
 */
@Injectable()
export class LoadingService 
{

	/**
	* Constructor
	* 
	*/
	constructor( 
		private resolver: ComponentFactoryResolver
        ) {}

	/**
     * Get the parent which call the loading
     */
	@ViewChild('parent', {static: false})
 	
	/**
     * Store the parent which call the loading
     */
 	private parent: ViewContainerRef;


 	/**
     * Diplay the loading component
     *
     * @param {ViewContainerRef} view parent which call the loading
     *
     * @returns {number} index of the opened loading
     */
	public open( view:ViewContainerRef ):number
	{
		let componentFactory = this.resolver.resolveComponentFactory(LoadingComponent);
		const component = view.createComponent(componentFactory);
		let index:any = component;
		return index._index;
	}

	/**
     * Diplay the loading component
     *
     * @param {ViewContainerRef} view parent which call the loading
     * @param {index} index index of the opened loading
     *
     * @returns {void}
     */
	public close( view:ViewContainerRef, index:number )
	{
		view.remove( index );
	}
}


