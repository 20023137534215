import { Component, OnInit, ViewContainerRef, ViewChild }      from '@angular/core';
import { Router }                                              from '@angular/router';
import { ActivatedRoute }                                      from '@angular/router';
import { TdDialogService }                                     from '@covalent/core';
import { MatDialog }                                           from '@angular/material';
import { ViewEncapsulation }                                   from '@angular/core';
import { Renderer }                                            from '@angular/core';

import {MatAccordion}                                          from '@angular/material';


import {CdkDragDrop, moveItemInArray, transferArrayItem}       from '@angular/cdk/drag-drop';
            
import { AppService }                                          from '../../../../core/service/app.service';
import { LoadingService }                                      from '../../../../commons/loading/service/loading.service';
import { CookieService }                                       from '../../../../core/service/cookie.service';
import { CommonService }                                       from '../../../common/service/common.service';
import { Module }                                              from '../../../common/vo/module';
import { LicenseService }                                      from '../../../license/service/license.service';
import { License }                                             from '../../../license/vo/license';
import { UserService }                                         from '../../../user/service/user.service';
            
import { SoftwareService }                                     from '../../../software/service/software.service';
import { LicenseContent }                                      from '../../../software/vo/license.content';
import { ApplicationPackage }                                  from '../../../software/vo/application.package';
import { ApplicationTime }                                     from '../../../software/vo/application.time'; 
import { Application }                                         from '../../../software/vo/application';

import { LicensePopupSyncNowComponent }                        from '../popup/popup_sync_now/license.popup.sync.now.component';
import { LicensePopupAddApplicationComponent }                 from '../popup/popup_add_application/license.popup.add.application.component';
import { LicensePopupConnexionInformationsComponent }          from '../popup/popup_connexion_informations/license.popup.connexion.informations.component';
import { LicensePopupUnsuscribeKyliiProgramComponent }         from '../popup/popup_unsuscribe_kylii_program/license.popup.unsuscribe.kylii.program.component';
import { LicenseSchedule }                                     from '../../vo/license.schedule';
import { LicenseScheduleDay }                                  from '../../vo/license.schedule.day';



/**
 * License Update Component
 * 
 * Edit a license
 */
@Component({
   	moduleId: module.id,
  	selector: 'license-update',
  	templateUrl: 'license.update.component.html',
  	styleUrls: [ 'license.update.component.css' ],
    encapsulation: ViewEncapsulation.None
})
export class LicenseUpdateComponent implements OnInit {

    /**
    * Constructor
    * 
    */
    constructor
    (
        private loadingService: LoadingService,
        private _dialogService: TdDialogService,
        private _viewContainerRef: ViewContainerRef,
        private router: Router,
        private route: ActivatedRoute,
        /**
        * Access to the Main App Service
        */
        public appService: AppService,
        private commonService:CommonService,
        private licenseService:LicenseService,
        private userService:UserService,
        private cookieService:CookieService,
        private softwareService:SoftwareService,
        private dialog: MatDialog,
        private renderer : Renderer
    ) 
    { }

    /**
    * Index of the loading Component
    */
    private index_loading:number;

    /**
    * Determin if the component is saving his datas or not
    */
    public DISABLE_WHEN_SAVING:boolean = false;

    /**
    * List of the modules
    */
    private modules:Module[] = new Array();

    /**
    * License to edit
    */
    public license:License = new License();

    /**
    * List of application's times
    */
    public application_times:ApplicationTime[] = new Array();

    /**
    * Content of the license
    */
    public license_content:LicenseContent = new LicenseContent();


    /**
    * Module Logo path
    */
    public module_logo:string = "";

    /**
    * Module logo color
    */
    public module_color:string = "rgba(0,0,0,0)"

    /**
    * timestamp
    */
    public timestamp:number = new Date().getTime();

    /**
     * Schedule days
     */
    public schedule_days:LicenseScheduleDay[] = new Array();

    public title_1:string;
    
    public windows_w:number = this.commonService.getWidth();
    public windows_h:number = this.commonService.getHeight();
    public quest_text:string = "Bonnes pratiques Kylii Quest";
    /**
    * Get catalog app left from DOM
    */
    @ViewChild('catalog_app_1', {static: false}) catalog_app_1: MatAccordion;
    
    /**
    * Get catalog app right from DOM
    */
    @ViewChild('catalog_app_2', {static: false}) catalog_app_2: MatAccordion;
    
    /**
    * Get catalog pkg left from DOM
    */
    @ViewChild('catalog_pkg_1', {static: false}) catalog_pkg_1: MatAccordion;
    
    /**
    * Get catalog pkg right from DOM
    */
    @ViewChild('catalog_pkg_2', {static: false}) catalog_pkg_2: MatAccordion;

    /**
    * Initialisation
    * 
    */
    ngOnInit(): void 
    {
        this.quest_text = "Kylii Quest Best Practices";

        if( this.appService.LANGUAGE_ID == 1 )
        {
            this.quest_text = "Bonnes pratiques Kylii Quest";
        }

        if( this.appService.LANGUAGE_ID == 2 )
        {
            this.quest_text = "Kylii Quest Best Practices";
        }

        if( this.appService.LANGUAGE_ID == 7 )
        {
            this.quest_text = "Buenas prácticas de Kylii Quest";
        }

        this.windows_w = this.commonService.getWidth() - 50;
        this.windows_h = this.commonService.getHeight();

        this.license = new License();
        this.license.module = new Module();
        this.license.internetTimeSlots = new Array();

        this.license_content.market_applications             = new Array();
        this.license_content.program_applications            = new Array();
        this.license_content.market_applications_packages    = new Array();
        this.license_content.program_applications_packages   = new Array();

        this.index_loading = this.loadingService.open( this._viewContainerRef );

        this.licenseService.get( this.route.snapshot.params['id'], Number( this.cookieService.get("KyliiMarket_User_id") ) )
        .then
        (
            res =>
            {
                if( res == null )
                {
                    this.router.navigate([this.appService.DEFAULT_PAGE]);
                }

                this.license = res;
                if( this.license.operating_system_version == null )
                {
                    this.license.operating_system_version = 0.0;
                }
                

                console.log("License");
                console.log(this.license);

                this.commonService.module_list()
                .then
                (
                    res =>
                    {
                        this.modules = res;

                        for( let i:number = 0; i < this.modules.length; i++ )
                        {
                            if( this.license.module.id == this.modules[i].id )
                            {
                                this.license.module = this.modules[i];
                                break;
                            }
                        }

                        this.softwareService.list_time()
                        .then
                        (
                            res =>
                            {
                                this.application_times = res;

                                this.softwareService.get( this.license.id )
                                .then
                                (
                                    res =>
                                    {
                                        this.license_content = res;
                                        for( let i:number = 0; i < this.license_content.program_applications.length; i++ )
                                        {
                                            for( let j:number = 0; j < this.application_times.length; j++ )
                                            {
                                                if( this.license_content.program_applications[i].time.value == this.application_times[j].value )
                                                {
                                                    this.license_content.program_applications[i].time = this.application_times[j];
                                                    break;
                                                }
                                            }
                                        }

                                        for( let i:number = 0; i < this.license_content.market_applications_packages.length; i++ )
                                        {
                                            for( let j:number = 0; j < this.license_content.market_applications_packages[i].applications.length; j++ )
                                            {
                                                this.license_content.market_applications_packages[i].applications[j].thumb = this.license_content.market_applications_packages[i].thumb;
                                            }
                                        }

                                        for( let i:number = 0; i < this.license_content.program_applications_packages.length; i++ )
                                        {
                                            for( let j:number = 0; j < this.license_content.program_applications_packages[i].applications.length; j++ )
                                            {
                                                this.license_content.program_applications_packages[i].applications[j].thumb = this.license_content.program_applications_packages[i].thumb;
                                            }
                                        }

                                        switch( this.license.module.id )
                                        {
                                            case 1:
                                                this.module_logo  = "assets/commons/air-header.png";
                                                this.module_color = this.appService.COLOR_KYLII_AIR;
                                                this.license.custom_class = "product-thumb-air";
                                                break;

                                            case 2:
                                                this.module_logo  = "assets/commons/floor-header.png";
                                                this.module_color = this.appService.COLOR_KYLII_FLOOR;
                                                this.license.custom_class = "product-thumb-floor";
                                                break;

                                            case 3:
                                                this.module_logo  = "assets/commons/media-header.png";
                                                this.module_color = this.appService.COLOR_KYLII_MEDIA;
                                                this.license.custom_class = "product-thumb-media";
                                                break;

                                            case 4:
                                                this.module_logo  = "assets/commons/touch-header.png";
                                                this.module_color = this.appService.COLOR_KYLII_TOUCH;
                                                this.license.custom_class = "product-thumb-touch";
                                                break;

                                            case 5:
                                                this.module_logo  = "assets/commons/table-header.png";
                                                this.module_color = this.appService.COLOR_KYLII_TABLE;
                                                this.license.custom_class = "product-thumb-table";
                                                break;

                                            case 8:
                                                this.module_logo  = "assets/commons/view-header.png";
                                                this.module_color = this.appService.COLOR_KYLII_VIEW;
                                                this.license.custom_class = "product-thumb-view";
                                                break;

                                            case 9:
                                                this.module_logo  = "assets/commons/quest-header.png";
                                                this.module_color = this.appService.COLOR_KYLII_QUEST;
                                                this.license.custom_class = "product-thumb-quest";
                                                break;

                                            case 10:
                                                this.module_logo  = "assets/commons/room-header.png";
                                                this.module_color = this.appService.COLOR_KYLII_ROOM;
                                                this.license.custom_class = "product-thumb-room";
                                                break;

                                            case 11:
                                                this.module_logo  = "assets/commons/wall-header.png";
                                                this.module_color = this.appService.COLOR_KYLII_WALL;
                                                this.license.custom_class = "product-thumb-wall";
                                                break;
                                            
                                            case 17:
                                                this.module_logo  = "assets/commons/view-header.png";
                                                this.module_color = this.appService.COLOR_KYLII_VIEW_MONEY;
                                                this.license.custom_class = "product-thumb-view-money";
                                                break;
                                        }
                                        

                                        


                                        this.manage_schedule();
                                    }
                                );
                            }
                        );
                    }
                );
            }
        );
    }

    public open_quest_link()
    {
        let url_site:string = "https://quest.kylii-kids.com/fr/";
        if(  this.appService.LANGUAGE_ID == 1)
        {
            url_site = "https://quest.kylii-kids.com/fr/";
        }
        else
        {
            url_site = "https://quest.kylii-kids.com/en/";
        }
        window.open(url_site, '_blank').focus();

    }


    private manage_schedule()
    {
        if( this.license.schedule )
        {
            this.load_schedule();
        }
        else
        {
            this.license.schedule = new LicenseSchedule();
            this.license.schedule.id = 0;
            
            this.license.schedule.monday                        = new LicenseScheduleDay();
            this.license.schedule.monday.id                     = 0;
            this.license.schedule.monday.day                    = 1;
            this.license.schedule.monday.boot_hour              = this.license.boot_hour;
            this.license.schedule.monday.halt_hour              = this.license.halt_hour;
            this.license.schedule.monday.enabled                = true;
            this.license.schedule.monday.is_schedule_active     = true;

            this.license.schedule.tuesday                       = new LicenseScheduleDay();
            this.license.schedule.tuesday.id                    = 0;
            this.license.schedule.tuesday.day                   = 2;
            this.license.schedule.tuesday.boot_hour             = this.license.boot_hour;
            this.license.schedule.tuesday.halt_hour             = this.license.halt_hour;
            this.license.schedule.tuesday.enabled               = true;
            this.license.schedule.tuesday.is_schedule_active    = true;

            this.license.schedule.wednesday                     = new LicenseScheduleDay();
            this.license.schedule.wednesday.id                  = 0;
            this.license.schedule.wednesday.day                 = 3;
            this.license.schedule.wednesday.boot_hour           = this.license.boot_hour;
            this.license.schedule.wednesday.halt_hour           = this.license.halt_hour;
            this.license.schedule.wednesday.enabled             = true;
            this.license.schedule.wednesday.is_schedule_active  = true;

            this.license.schedule.thursday                      = new LicenseScheduleDay();
            this.license.schedule.thursday.id                   = 0;
            this.license.schedule.thursday.day                  = 4;
            this.license.schedule.thursday.boot_hour            = this.license.boot_hour;
            this.license.schedule.thursday.halt_hour            = this.license.halt_hour;
            this.license.schedule.thursday.enabled              = true;
            this.license.schedule.thursday.is_schedule_active   = true;

            this.license.schedule.friday                        = new LicenseScheduleDay();
            this.license.schedule.friday.id                     = 0;
            this.license.schedule.friday.day                    = 5;
            this.license.schedule.friday.boot_hour              = this.license.boot_hour;
            this.license.schedule.friday.halt_hour              = this.license.halt_hour;
            this.license.schedule.friday.enabled                = true;
            this.license.schedule.friday.is_schedule_active     = true;

            this.license.schedule.saturday                      = new LicenseScheduleDay();
            this.license.schedule.saturday.id                   = 0;
            this.license.schedule.saturday.day                  = 6;
            this.license.schedule.saturday.boot_hour            = this.license.boot_hour;
            this.license.schedule.saturday.halt_hour            = this.license.halt_hour;
            this.license.schedule.saturday.enabled              = true;
            this.license.schedule.saturday.is_schedule_active   = true;

            this.license.schedule.sunday                        = new LicenseScheduleDay();
            this.license.schedule.sunday.id                     = 0;
            this.license.schedule.sunday.day                    = 7;
            this.license.schedule.sunday.boot_hour              = this.license.boot_hour;
            this.license.schedule.sunday.halt_hour              = this.license.halt_hour;
            this.license.schedule.sunday.enabled                = true;
            this.license.schedule.sunday.is_schedule_active     = true;

            this.complete_init();
        }
    }


    /**
     * Load Schedule
     */
    public load_schedule()
    {
        this.licenseService.get_schedule( this.license.schedule.id )
        .then
        (
            res =>
            {
                this.license.schedule = res;
                this.load_schedule_monday();
            }    
        );
    }


    /**
     * Load Schedule Monday
     */
    public load_schedule_monday()
    {
        if( this.license.schedule.monday )
        {
            this.licenseService.get_schedule_day( this.license.schedule.monday.id )
            .then
            (
                res =>
                {
                    this.license.schedule.monday = res;
                    
                    this.load_schedule_tuesday();
                }
            );
        }
        else
        {
            this.license.schedule.monday                        = new LicenseScheduleDay();
            this.license.schedule.monday.id                     = 0;
            this.license.schedule.monday.day                    = 1;
            this.license.schedule.monday.boot_hour              = "07:00";
            this.license.schedule.monday.halt_hour              = "23:59";
            this.license.schedule.monday.enabled                = true;
            this.license.schedule.monday.is_schedule_active     = true;
            this.load_schedule_tuesday();
        }
    }


     /**
     * Load Schedule tuesday
     */
    public load_schedule_tuesday()
    {
        if( this.license.schedule.tuesday )
        {
            this.licenseService.get_schedule_day( this.license.schedule.tuesday.id )
            .then
            (
                res =>
                {
                    this.license.schedule.tuesday = res;
                    this.load_schedule_wednesday();
                }
            );
        }
        else
        {
            this.license.schedule.tuesday                       = new LicenseScheduleDay();
            this.license.schedule.tuesday.id                    = 0;
            this.license.schedule.tuesday.day                   = 2;
            this.license.schedule.tuesday.boot_hour             = "07:00";
            this.license.schedule.tuesday.halt_hour             = "23:59";
            this.license.schedule.tuesday.enabled               = true;
            this.license.schedule.tuesday.is_schedule_active    = true;
            this.load_schedule_wednesday();
        }
    }

    /**
     * Load Schedule wednesday
     */
     public load_schedule_wednesday()
     {
        if( this.license.schedule.wednesday )
        {
            this.licenseService.get_schedule_day( this.license.schedule.wednesday.id )
            .then
            (
                res =>
                {
                    this.license.schedule.wednesday = res;
                    this.load_schedule_thursday();
                }
            );
        }
        else
        {
            this.license.schedule.wednesday                     = new LicenseScheduleDay();
            this.license.schedule.wednesday.id                  = 0;
            this.license.schedule.wednesday.day                 = 3;
            this.license.schedule.wednesday.boot_hour           = "07:00";
            this.license.schedule.wednesday.halt_hour           = "23:59";
            this.license.schedule.wednesday.enabled             = true;
            this.license.schedule.wednesday.is_schedule_active  = true;
            this.load_schedule_thursday();
        }
     }

     /**
     * Load Schedule thursday
     */
    public load_schedule_thursday()
    {
        if( this.license.schedule.thursday )
        {
            this.licenseService.get_schedule_day( this.license.schedule.thursday.id )
            .then
            (
                res =>
                {
                    this.license.schedule.thursday = res;
                    this.load_schedule_friday();
                }
            );
        }
        else
        {
            this.license.schedule.thursday                      = new LicenseScheduleDay();
            this.license.schedule.thursday.id                   = 0;
            this.license.schedule.thursday.day                  = 4;
            this.license.schedule.thursday.boot_hour            = "07:00";
            this.license.schedule.thursday.halt_hour            = "23:59";
            this.license.schedule.thursday.enabled              = true;
            this.license.schedule.thursday.is_schedule_active   = true;
            this.load_schedule_friday();
        }
    }

    /**
     * Load Schedule friday
     */
     public load_schedule_friday()
     {
        if( this.license.schedule.friday )
        {
            this.licenseService.get_schedule_day( this.license.schedule.friday.id )
            .then
            (
                res =>
                {
                    this.license.schedule.friday = res;
                    this.load_schedule_saturday();
                }
            );
        }
        else
        {
            this.license.schedule.friday                        = new LicenseScheduleDay();
            this.license.schedule.friday.id                     = 0;
            this.license.schedule.friday.day                    = 5;
            this.license.schedule.friday.boot_hour              = "07:00";
            this.license.schedule.friday.halt_hour              = "23:59";
            this.license.schedule.friday.enabled                = true;
            this.license.schedule.friday.is_schedule_active     = true;
            this.load_schedule_saturday();
        }
     }

     /**
     * Load Schedule saturday
     */
    public load_schedule_saturday()
    {
        if( this.license.schedule.saturday )
        {
            this.licenseService.get_schedule_day( this.license.schedule.saturday.id )
            .then
            (
                res =>
                {
                    this.license.schedule.saturday = res;
                    this.load_schedule_sunday();
                }
            );
        }
        else
        {
            this.license.schedule.saturday                      = new LicenseScheduleDay();
            this.license.schedule.saturday.id                   = 0;
            this.license.schedule.saturday.day                  = 6;
            this.license.schedule.saturday.boot_hour            = "07:00";
            this.license.schedule.saturday.halt_hour            = "23:59";
            this.license.schedule.saturday.enabled              = true;
            this.license.schedule.saturday.is_schedule_active   = true;
            this.load_schedule_sunday();
        }
    }

    /**
     * Load Schedule 
     */
     public load_schedule_sunday()
     {
        if( this.license.schedule.sunday )
        {
            this.licenseService.get_schedule_day( this.license.schedule.sunday.id )
            .then
            (
                res =>
                {
                    this.license.schedule.sunday = res;
                    this.complete_init();
                }
            );
        }
        else
        {
            this.license.schedule.sunday                        = new LicenseScheduleDay();
            this.license.schedule.sunday.id                     = 0;
            this.license.schedule.sunday.day                    = 7;
            this.license.schedule.sunday.boot_hour              = "07:00";
            this.license.schedule.sunday.halt_hour              = "23:59";
            this.license.schedule.sunday.enabled                = true;
            this.license.schedule.sunday.is_schedule_active     = true;
            this.complete_init();
        }
     }

    private complete_init()
    {
        this.license.schedule.monday.label = "Monday";
        this.license.schedule.tuesday.label = "Tuesday";
        this.license.schedule.wednesday.label = "Wednesday";
        this.license.schedule.thursday.label = "Thursday";
        this.license.schedule.friday.label = "Friday";
        this.license.schedule.saturday.label = "Saturday";
        this.license.schedule.sunday.label = "Sunday";

 
        if( this.appService.LANGUAGE_ID == 1 )
        {
            this.license.schedule.monday.label = "Lundi";
            this.license.schedule.tuesday.label = "Mardi";
            this.license.schedule.wednesday.label = "Mercredi";
            this.license.schedule.thursday.label = "Jeudi";
            this.license.schedule.friday.label = "Vendredi";
            this.license.schedule.saturday.label = "Samedi";
            this.license.schedule.sunday.label = "Dimanche";
        }

        if( this.appService.LANGUAGE_ID == 7 )
        {
            this.license.schedule.monday.label = "Lunes";
            this.license.schedule.tuesday.label = "Martes";
            this.license.schedule.wednesday.label = "Miércoles";
            this.license.schedule.thursday.label = "Jueves";
            this.license.schedule.friday.label = "Viernes";
            this.license.schedule.saturday.label = "Sábado";
            this.license.schedule.sunday.label = "Domingo";
        }



        this.schedule_days.push( this.license.schedule.monday );
        this.schedule_days.push( this.license.schedule.tuesday );
        this.schedule_days.push( this.license.schedule.wednesday );
        this.schedule_days.push( this.license.schedule.thursday );
        this.schedule_days.push( this.license.schedule.friday );
        this.schedule_days.push( this.license.schedule.saturday );
        this.schedule_days.push( this.license.schedule.sunday );

        this.update_style();                         
        this.manage_application_languages();
        this.manage_state_installed();
        this.loadingService.close( this._viewContainerRef, this.index_loading );
    }


    /*****************************************************************/
    //
    // Dialog
    //
    /*****************************************************************/

    /**
     * Open an alert message
     * 
     * @param {string} Content of the message
     * @param {string} Title of the message
     * @param {string} Label of the close button
     */
    public openDialog( content:string, title:string, closeButton:string )
    {
        this._dialogService.openAlert
        (
            {
                message: content,
                title: title, 
                closeButton: closeButton, 
            }
        );
    }

    /**
    * Update style of component
    *
    */
    public update_style()
    {
        let bar = document.querySelector('mat-tab-group.mat-accent mat-ink-bar');
        this.renderer.setElementStyle(bar, 'background-color', this.module_color);

        let alerts = document.querySelectorAll('td-message.mat-accent');
        for( let i:number = 0; i < alerts.length; i++ )
        {
            this.renderer.setElementStyle(alerts[i], 'color', this.module_color);
            this.renderer.setElementStyle(alerts[i], 'background-color', "#FFFFFF" );
        }

        
        let tab_pkg = document.querySelectorAll('.tab-package .mat-tab-label .mat-tab-label-content');
        for( let i:number = 0; i < tab_pkg.length; i++ )
        {
            this.renderer.setElementStyle(tab_pkg[i], 'color', this.module_color);
        }
        
    }

    /**
    * Close Catalog Application accordion Left 
    *
    */
    public close_catalog_app_1()
    {
        this.catalog_app_1.multi = true;
        this.catalog_app_1.closeAll();
        this.catalog_app_1.multi = false;
        this.update_style();
    }

    /**
    * Close Catalog Application accordion Right 
    *
    */
    public close_catalog_app_2()
    {
        this.catalog_app_2.multi = true;
        this.catalog_app_2.closeAll();
        this.catalog_app_2.multi = false;
        this.update_style();
    }

    /**
    * Close Catalog Package accordion Left 
    *
    */
    public close_catalog_pkg_1()
    {
        this.catalog_pkg_1.multi = true;
        this.catalog_pkg_1.closeAll();
        this.catalog_pkg_1.multi = false;
        this.update_style();
    }

    /**
    * Close Catalog Package accordion Right 
    *
    */
    public close_catalog_pkg_2()
    {
        this.catalog_pkg_2.multi = true;
        this.catalog_pkg_2.closeAll();
        this.catalog_pkg_2.multi = false;
        this.update_style();
    }


    /*****************************************************************/
    //
    // Utils
    //
    /*****************************************************************/


    /**
    * Manage applications and application's pacckage to set the fact if they are installed or not
    *
    *
    */
    public manage_state_installed()
    {
        // Applications
        for( let i:number = 0; i < this.license_content.market_applications.length; i++ )
        {   
            this.license_content.market_applications[i].id_temp   = (i+1);
            this.license_content.market_applications[i].installed = false;
            for( let j:number = 0; j < this.license_content.program_applications.length; j++ )
            {
                if( this.license_content.market_applications[i].id == this.license_content.program_applications[j].id )
                {
                    this.license_content.market_applications[i].installed = true;
                    break;
                }
            }
        }

        // Application's Packages
        for( let i:number = 0; i < this.license_content.market_applications_packages.length; i++ )
        {               
            this.license_content.market_applications_packages[i].id_temp   = (i+1);
            this.license_content.market_applications_packages[i].installed = false;
            for( let j:number = 0; j < this.license_content.program_applications_packages.length; j++ )
            {
                if( this.license_content.market_applications_packages[i].id == this.license_content.program_applications_packages[j].id )
                {
                    this.license_content.market_applications_packages[i].installed = true;
                    break;
                }
            }

            // Media 
            if( this.license_content.market_applications_packages[i].module.id == 3 )
            {
                for( let j:number = 0; j < this.license_content.market_applications_packages[i].applications.length; j++ )
                {
                    this.license_content.market_applications_packages[i].applications[j].installed = false;
                    for( let k:number = 0; k < this.license_content.program_applications.length; k++ )
                    {
                        if( this.license_content.market_applications_packages[i].applications[j].id == this.license_content.program_applications[k].id )
                        {
                            this.license_content.market_applications_packages[i].applications[j].installed = true;
                            break;
                        }
                    }
                }
            }
        }
    }

    /**
     * Get the with of the browser to dynamically set width of Popup 
     * 
     * @return {number} Browser Width
     */
    public getWidth():number 
    {
        return Math.max
        (
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    }


    /**
    * Detmin if the license is connected or not
    *
    * @return {boolean} connected or not
    */
    private determin_if_connected():boolean
    {    
        let result:boolean = true;

        if( this.license.last_connexion && this.license.last_connexion.getTime())
        {
            let delay:number = new Date().getTime() -  this.license.last_connexion.getTime();
            delay = delay / 1000 / 60;

            if( delay < 3600 )
            {
                result = true;
            }
            else
            {
                result = false;
            }
        }
        else
        {
            result = false;
        }

        return result;
    }


    /**
    * Call when the user select one of the togfle slider
    *
    * @param {any} Current Application / Package  
    */
    public on_click_toggle( application:any )
    {
        if( this.license.module.id == 4 )
        {
            if( application.installed == false )
            {
                this.add_application_package( application )
            }
            else
            {
                this.remove_application_package( application )
            }
        }
        else
        {
            if( application.installed == false )
            {
                this.add_application( application )
            }
            else
            {
                this.remove_application( application )
            }
        }
    }

    /**
    * Call when the user open / close an application or a package
    *
    */
    public on_click_extension_panel()
    {
        this.update_style();
    }

    /*****************************************************************/
    //
    // Thumb license
    //
    /*****************************************************************/

    /**
    * Call when the license's logo is uploaded
    *
    * @param {any} Link of the license's logo
    */
    public on_thumb_complete( value:any ):void
    {
        this.license.logo = value + "?time="+new Date().getTime();
    }

    /**
    * Delete the license logo
    *
    */
    public delete_thumb():void
    {
        this.license.logo = "";
    }





    /*****************************************************************/
    //
    // Manage Progam
    //
    /*****************************************************************/
   

    /**
    * Call when the user do an action that will have for consequencies tp not use the program
    *
    */
    public manage_stop_use_kylii_progam()
    {
        if( this.license.use_kylii_program == true && this.license_content.program != null )
        {
            let dialogRef = this.dialog.open
            (
                LicensePopupUnsuscribeKyliiProgramComponent, 
                {
                    width:  '550px',
                    data: {}
                }
            );  
        }
        this.license.use_kylii_program = false;
    }


    /**
    * Call when the user wants to link his license to the Kylii program
    *
    */
    public link_to_program()
    {
        this.license_content.program_applications            = new Array();
        this.license_content.program_applications_packages   = new Array();

        for( let i:number = 0; i < this.license_content.program.program_applications.length; i++ )
        {
            this.license_content.program_applications.push( JSON.parse(JSON.stringify( this.license_content.program.program_applications[i] )) );
        }

        for( let i:number = 0; i < this.license_content.program.program_applications_packages.length; i++ )
        {
            this.license_content.program_applications_packages.push( JSON.parse(JSON.stringify( this.license_content.program.program_applications_packages[i] )) );
        }

        for( let i:number = 0; i < this.license_content.program_applications.length; i++ )
        {
            for( let j:number = 0; j < this.application_times.length; j++ )
            {
                if( this.license_content.program_applications[i].time.value == this.application_times[j].value )
                {
                    this.license_content.program_applications[i].time = this.application_times[j];
                    break;
                }
            }
        }

        for( let i:number = 0; i < this.license_content.market_applications_packages.length; i++ )
        {
            for( let j:number = 0; j < this.license_content.market_applications_packages[i].applications.length; j++ )
            {
                this.license_content.market_applications_packages[i].applications[j].thumb = this.license_content.market_applications_packages[i].thumb;
            }
        }

        for( let i:number = 0; i < this.license_content.program_applications_packages.length; i++ )
        {
            for( let j:number = 0; j < this.license_content.program_applications_packages[i].applications.length; j++ )
            {
                this.license_content.program_applications_packages[i].applications[j].thumb = this.license_content.program_applications_packages[i].thumb;
            }
        }

        this.license.use_kylii_program = true;
    }


    /*****************************************************************/
    //
    // Manage Application / Application's Package
    //
    /*****************************************************************/

    /**
    * Manage application's languages
    *
    */
    public manage_application_languages()
    {
        // Program Application
        for( let i:number = 0; i < this.license_content.program_applications.length; i++ )
        {
            for( let j:number = 0; j < this.license_content.program_applications[i].details.length; j++ )
            {
                if( this.license_content.program_applications[i].details[j].language.id == this.appService.LANGUAGE_ID )
                {
                    this.license_content.program_applications[i].label = this.license_content.program_applications[i].details[j].label;
                    this.license_content.program_applications[i].description = this.license_content.program_applications[i].details[j].description;
                    break;
                }
            }
        }

        // Program Application Package
        for( let i:number = 0; i < this.license_content.program_applications_packages.length; i++ )
        {
            for( let j:number = 0; j < this.license_content.program_applications_packages[i].details.length; j++ )
            {
                if( this.license_content.program_applications_packages[i].details[j].language.id == this.appService.LANGUAGE_ID )
                {
                    this.license_content.program_applications_packages[i].label = this.license_content.program_applications_packages[i].details[j].label;
                    this.license_content.program_applications_packages[i].description = this.license_content.program_applications_packages[i].details[j].description;
                    break;
                }
            }
        }

        // Program Application Package Application
        for( let i:number = 0; i < this.license_content.program_applications_packages.length; i++ )
        {
            for( let j:number = 0; j < this.license_content.program_applications_packages[i].applications.length; j++ )
            {
                for( let k:number = 0; k < this.license_content.program_applications_packages[i].applications[j].details.length; k++ )
                {
                    if( this.license_content.program_applications_packages[i].applications[j].details[k].language.id == this.appService.LANGUAGE_ID )
                    {
                        this.license_content.program_applications_packages[i].applications[j].label        =  this.license_content.program_applications_packages[i].applications[j].details[k].label;
                        this.license_content.program_applications_packages[i].applications[j].description  =  this.license_content.program_applications_packages[i].applications[j].details[k].description;
                        break;
                    }
                }
            }
        }

        // Market Application 
        for( let i:number = 0; i < this.license_content.market_applications.length; i++ )
        {
            for( let j:number = 0; j < this.license_content.market_applications[i].details.length; j++ )
            {
                if( this.license_content.market_applications[i].details[j].language.id == this.appService.LANGUAGE_ID )
                {
                    this.license_content.market_applications[i].label = this.license_content.market_applications[i].details[j].label;
                    this.license_content.market_applications[i].description = this.license_content.market_applications[i].details[j].description;
                    break;
                }
            }
        }

        // Market Application Package
        for( let i:number = 0; i < this.license_content.market_applications_packages.length; i++ )
        {
            for( let j:number = 0; j < this.license_content.market_applications_packages[i].details.length; j++ )
            {
                if( this.license_content.market_applications_packages[i].details[j].language.id == this.appService.LANGUAGE_ID )
                {
                    this.license_content.market_applications_packages[i].label = this.license_content.market_applications_packages[i].details[j].label;
                    this.license_content.market_applications_packages[i].description = this.license_content.market_applications_packages[i].details[j].description;
                    break;
                }
            }
        }

        // Market Application Package Application
        for( let i:number = 0; i < this.license_content.market_applications_packages.length; i++ )
        {
            for( let j:number = 0; j < this.license_content.market_applications_packages[i].applications.length; j++ )
            {
                for( let k:number = 0; k < this.license_content.market_applications_packages[i].applications[j].details.length; k++ )
                {
                    if( this.license_content.market_applications_packages[i].applications[j].details[k].language.id == this.appService.LANGUAGE_ID )
                    {
                        this.license_content.market_applications_packages[i].applications[j].label        =  this.license_content.market_applications_packages[i].applications[j].details[k].label;
                        this.license_content.market_applications_packages[i].applications[j].description  =  this.license_content.market_applications_packages[i].applications[j].details[k].description;
                        break;
                    }
                }
            }
        }
    }

    /**
    * Call when user change duration
    *
    */
    public change_duration()
    {
        this.manage_stop_use_kylii_progam();
    }




    /**
    * Add an application to the license's program
    *
    * @param {Application} Application to add
    */
    public add_application( app:Application )
    {
        
        for( let i:number = 0; i < this.application_times.length; i++ )
        {
            if( this.application_times[i].value == 180 )
            {
                app.time = this.application_times[i];
                break;
            }
        }

        this.license_content.program_applications.push( app );

        let dialogRef = this.dialog.open
        (
            LicensePopupAddApplicationComponent, 
            {
                width:  '450px',
                data: { license: this.license }
            }
        );  
        this.manage_stop_use_kylii_progam();
        this.generate_applications_sort(); 
    }

    /**
    * Remove an application from the license's program
    *
    * @param {Application} Application to remove
    */
    public remove_application( app:Application )
    {

        for( let i:number = 0; i < this.license_content.program_applications.length; i++ )
        {
            if( this.license_content.program_applications[i].id == app.id )
            {
                this.license_content.program_applications.splice(i, 1);
                break;
            }    
        }

        for( let i:number = 0; i < this.license_content.market_applications.length; i++ )
        {
            if( this.license_content.market_applications[i].id == app.id )
            {
                this.license_content.market_applications[i].installed = false;
                break;
            }
        }

        if( this.license.module.id == 3 )
        {
            for( let i:number = 0; i < this.license_content.market_applications_packages.length; i++ )
            {
                for( let j:number = 0; j < this.license_content.market_applications_packages[i].applications.length; j++ )
                {
                    if( this.license_content.market_applications_packages[i].applications[j].id == app.id )
                    {
                        this.license_content.market_applications_packages[i].applications[j].installed = false;
                        break;
                    }
                }
            }
        }
        
        this.manage_stop_use_kylii_progam();
        this.generate_applications_sort();
    }


    /**
    * Generate the order of applications program
    *
    */
    public generate_applications_sort()
    {
        for( let i:number = 0; i < this.license_content.program_applications.length; i++ )
        {
            this.license_content.program_applications[i].sort = i;
        }
    }

    /**
    * Manager drag and drop for applications
    *
    * @param {CdkDragDrop<CatalogPage[]>} Event
    */
    public drop_applications(event: CdkDragDrop<Application[]>) 
    {
        moveItemInArray(this.license_content.program_applications, event.previousIndex, event.currentIndex);
        this.manage_stop_use_kylii_progam();
        this.generate_applications_sort();
    }

    


   

    

    /**
    * Add an application's package to the license's program
    *
    * @param {ApplicationPackage} Application's package to add to add
    */
    public add_application_package( pkg:ApplicationPackage )
    {
        this.license_content.program_applications_packages.push( pkg );

        let dialogRef = this.dialog.open
        (
            LicensePopupAddApplicationComponent, 
            {
                width:  '450px',
                data: { license: this.license }
            }
        );
        this.manage_stop_use_kylii_progam();
        this.generate_packages_sort();    
    }

    /**
    * Remove an application's package from the license's program
    *
    * @param {ApplicationPackage} Application's package to remove
    */
    public remove_application_package( pkg:ApplicationPackage )
    {
        for( let i:number = 0; i < this.license_content.program_applications_packages.length; i++ )
        {
            if( this.license_content.program_applications_packages[i].id == pkg.id )
            {
                this.license_content.program_applications_packages.splice(i, 1);
                break;
            }    
        }


        for( let i:number = 0; i < this.license_content.market_applications_packages.length; i++ )
        {
            if(  this.license_content.market_applications_packages[i].id == pkg.id )
            {
                this.license_content.market_applications_packages[i].installed = false;
                break;
            }
        }
        
         this.manage_stop_use_kylii_progam();
        this.generate_packages_sort();
    }


    /**
    * Manager drag and drop for applications
    *
    * @param {CdkDragDrop<CatalogPage[]>} Event
    */
    public drop_applications_packages(event: CdkDragDrop<ApplicationPackage[]>) 
    {
        moveItemInArray(this.license_content.program_applications_packages, event.previousIndex, event.currentIndex);
         this.manage_stop_use_kylii_progam();
        this.generate_packages_sort();
    }

   /**
    * Generate the order of application's package program
    *
    */
    public generate_packages_sort()
    {
        for( let i:number = 0; i < this.license_content.program_applications_packages.length; i++ )
        {
            this.license_content.program_applications_packages[i].sort = i;
        }
    }


    /*****************************************************************/
    //
    // Save
    //
    /*****************************************************************/

    /**
    * Save the license's informatios
    *
    * @param {any} Event used for the save component
    */
    public save_informations( evt:any = null )
    {
        this.DISABLE_WHEN_SAVING = true;


        if( this.license.operating_system_version >= 10.0 )
        {
            this.save_day(0,evt);
        }
        else
        {
            this.save_license_and_applocation_informations(evt);
        }
    }

     /**
     * Link Schedule to license
     */
     public link_schedule_to_license( evt:any = null)
     {
        this.licenseService.update_schedule_license( this.license, this.license.schedule )
        .then
        (
            res =>
            {
                this.save_license_and_applocation_informations(evt);
            }
        );
     }


    /**
     * Save schedule
     */
    public save_schedule( evt:any = null)
    {
        if( this.license.schedule.id == 0 )
        {
            this.licenseService.add_schedule( this.license.schedule )
            .then
            (
                res =>
                {
                    this.license.schedule.id = res;
                    this.link_schedule_to_license(evt);
                }
            );
        }
        else
        {
            this.licenseService.update_schedule( this.license.schedule )
            .then
            (
                res =>
                {
                    this.link_schedule_to_license(evt);
                }
            );
        }
    }


    /**
     * Save Days
     * @param index 
     */
    public save_day( index:number,  evt:any = null )
    {
        if( index < this.schedule_days.length )
        {
            if( this.schedule_days[index].id == 0 )
            {
                this.licenseService.add_schedule_day( this.schedule_days[index] )
                .then
                (
                    res =>
                    {
                        this.schedule_days[index].id = res;

                        switch( this.schedule_days[index].day )
                        {
                            case 1:
                                this.license.schedule.monday.id = this.schedule_days[index].id;
                                break;

                            case 2:
                                this.license.schedule.tuesday.id = this.schedule_days[index].id;
                                break;

                            case 3:
                                this.license.schedule.wednesday.id = this.schedule_days[index].id;
                                break;

                            case 4:
                                this.license.schedule.thursday.id = this.schedule_days[index].id;
                                break;

                            case 5:
                                this.license.schedule.friday.id = this.schedule_days[index].id;
                                break;

                            case 6:
                                this.license.schedule.saturday.id = this.schedule_days[index].id;
                                break;

                            case 7:
                                this.license.schedule.sunday.id = this.schedule_days[index].id;
                                break;
                        }

                        this.save_day( index + 1,  evt );
                    }   
                );
            }
            else
            {
                this.licenseService.update_schedule_day( this.schedule_days[index] )
                .then
                (
                    res =>
                    {
                        this.save_day( index + 1,  evt);
                    }
                );
            }
        }
        else
        {
            this.save_schedule(evt);
        }
    }

    /**
    * Save the license's informatios
    *
    * @param {any} Event used for the save component
    */
    public save_license_and_applocation_informations( evt:any = null )
    {
        this.DISABLE_WHEN_SAVING = true;


        


        this.licenseService.update( this.license )
        .then
        (
            res =>
            {
                if( res == true )
                {
                    this.softwareService.update(this.license.id, this.license_content)
                    .then
                    (
                        res =>
                        {
                            if( res == true )
                            {
                             //   evt.stop_loading(true);

                                if( this.determin_if_connected() == true )
                                {
                                    let dialogRef = this.dialog.open
                                    (
                                        LicensePopupSyncNowComponent, 
                                        {
                                            width:  '450px',
                                            data: { license: this.license }
                                        }
                                    );    
                                    dialogRef.afterClosed().subscribe
                                    (
                                        result => 
                                        {               
                                            if( result )
                                            {
                                                if( result.last_command_id )
                                                {
                                                    this.license.last_command_id = result.last_command_id;
                                                }
                                            }
                                        }
                                    ); 
                                }
                                else
                                {
                                    this.license.connected = false;
                                    let dialogRef = this.dialog.open
                                    (
                                        LicensePopupConnexionInformationsComponent, 
                                        {
                                            width:  '650px',
                                            data: { license: this.license }
                                        }
                                    );     
                                }
                                this.DISABLE_WHEN_SAVING = false;     
                            }
                            else
                            {
                            //    evt.stop_loading(false);
                                this.DISABLE_WHEN_SAVING = false;
                            }
                        }
                    );
                }
                else
                {
                    this.openDialog("Fail to update your license.", "Error", "Close");
                //    evt.stop_loading(false);
                    this.DISABLE_WHEN_SAVING = false;
                }
                
            }
        );
    }
}