import { Component, OnInit, ViewChild }                  from '@angular/core';
import { Inject}                                         from '@angular/core';
import { ViewEncapsulation }                             from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA}       from '@angular/material';
import { MatSnackBar }                                   from '@angular/material';
import { Renderer }                                      from '@angular/core';
import { TdDialogService }                               from '@covalent/core';
import { ViewContainerRef }                              from '@angular/core';
import { FormControl }                                   from '@angular/forms';
import { Validators }                                    from '@angular/forms';
import { FormGroup }                                     from '@angular/forms';
import { Observable }                                    from 'rxjs';
import { AppService }                                    from '../../../../../core/service/app.service';
import { LoadingService }                                from '../../../../../commons/loading/service/loading.service';
import { CookieService }                                 from '../../../../../core/service/cookie.service';
import { License }                                       from '../../../vo/license';
import { LicenseService }                                from '../../../service/license.service';          
import { MailService }                                   from '../../../../../commons/mail/service/mail.service';
import { Mail }                                          from '../../../../../commons/mail/vo/mail';
import { AbstractControl }                               from '@angular/forms';

/**
 * License Popup Support Component
 * 
 * This component is used to send a mail to the support
 */
@Component(
{
    selector: 'license-popup-support-component',
    templateUrl: 'license.popup.support.component.html',
    styleUrls: [ 'license.popup.support.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class LicensePopupSupportComponent implements OnInit
{
    /**
     * Constructor
     * 
     */
	constructor
	(
		private dialogRef: MatDialogRef<LicensePopupSupportComponent>,
        private _dialogService: TdDialogService,
        private licenseService:LicenseService,
        private _viewContainerRef: ViewContainerRef,
        private snackBar: MatSnackBar,
        private lailService:MailService,
        private cookieService: CookieService,
        private mailService:MailService,
        private loadingService: LoadingService,
        /**
        * Access to the Main App Service
        */
        public appService: AppService,
        private dialog: MatDialog,
        private renderer : Renderer,
        /**
        * Datas pass to the component
        */
		@Inject(MAT_DIALOG_DATA) public data: any
	) 
    {
    }

    /**
    * Determin if the component is sening data or not
    */
    public DISABLE_WHEN_SENDING:boolean = false;

    /**
    * Determin if the compnent is allow to send a mail
    */
    public ALLOW_SEND:boolean = false;

    /**
    * Index for the Loading component
    */
    public index_loading:number;

    /**
    * Mail informations
    */
    public mail:Mail;

    /**
    * Forename of the contact
    */
    public forename:string;

    /**
    * Lastname of the contact
    */
    public lastname:string;

    /**
    * Phone number of the contact
    */
    public phone_number:string;

    /**
    * Email of the contact
    */
    public email:string;

    /**
    * Mail object
    */
    public subject:string;

    /**
    * Mail content
    */
    public content:string;

    /**
    * Validator Forename
    */
    public forenameFormControl = new FormControl('', [ Validators.required]);

    /**
    * Validator Lastname
    */
    public lastnameFormControl = new FormControl('', [ Validators.required]);

    /**
    * Validator Mail
    */
    public emailFormControl = new FormControl('', [ Validators.required, Validators.email]);

     /**
    * Validator Phone number
    */
    public phoneNumberFormControl = new FormControl('', [ Validators.required, Validators.pattern("^([\+][0-9]{1,3}([ \.\-])?)?([\(]{1}[0-9]{3}[\)])?([0-9A-Z \.\-]{1,32})((x|ext|extension)?[0-9]{1,4}?)$")]);

     /**
    * Validator Subject
    */
    public subjectFormControl = new FormControl('', [ Validators.required]);

     /**
    * Validator Content
    */
    public contentFormControl = new FormControl('', [ Validators.required]);

    /**
    * List of licenses
    */
    public licenses:License[] = new Array();

    /**
    * List attachments
    */
    public attachments:string[] = new Array();

    /**
    * timestamp
    */
    public timestamp:number = new Date().getTime();

    /*****************************************************************/
    //
    // Initialisation
    //
    /*****************************************************************/

    /**
     * Initialisation
     * 
     */
    ngOnInit(): void 
    {
        this.index_loading = this.loadingService.open( this._viewContainerRef );

        this.licenseService.list( Number( this.cookieService.get("KyliiMarket_User_id") ) )
        .then
        (
            res =>
            {
                this.licenses = res;
                this.loadingService.close( this._viewContainerRef, this.index_loading );
            }
        );

        if( this.appService.IS_MOBILE == false )
        {
            let bar = document.querySelector('.mat-dialog-container');
            this.renderer.setElementStyle(bar, 'padding', '0px !important');
        }
    }


    /*****************************************************************/
    //
    // Dialog
    //
    /*****************************************************************/

    /**
     * Open an alert message
     * 
     * @param {string} Content of the message
     * @param {string} Title of the message
     * @param {string} Label of the close button
     */
    public openDialog( content:string, title:string, closeButton:string )
    {
        this._dialogService.openAlert
        (
            {
                message: content,
                title: title, 
                closeButton: closeButton, 
            }
        );
    }


    /**
    * Call when the license's logo is uploaded
    *
    * @param {any} Link of the license's logo
    */
    public on_attachment_complete( value:any ):void
    {
        this.attachments.push( value );
    }


    /**
    * Delete an attachment
    *
    * @param {string} attachment to delete
    */
    public delete_attachment( attachment:string )
    {
        for( let i:number = 0; i < this.attachments.length; i++ )
        {
            if( this.attachments[i] == attachment ) 
            {
                this.attachments.splice(i,1);
                break;
            }
        }
    }


    /*****************************************************************/
    //
    // Validator
    //
    /*****************************************************************/

    public matching_password(group: FormGroup) 
    {


        /*
        let pass = group.controls.control_new_password.value;
        let confirmPass = group.controls.control_new_password_confirmation.value;

        if( pass.length < 8 )
        {
            return { minlength: true };
        }
        else
        {
            if(  confirmPass == "" )
            {
                return null
            }
            else
            {
                if( pass == confirmPass )
                {
                    return null;
                }
                else
                {
                    return { MissMatchingPassword: true };
                }
            }
        }
        */
    }


    /*****************************************************************/
    //
    // Event
    //
    /*****************************************************************/

    /**
    * Catch when the user used the Enter touch
    *
    * @param {KeyboardEvent} Key board touch
    */
    public on_keyup( evt:KeyboardEvent )
    {
        if( evt.keyCode == 13 && this.DISABLE_WHEN_SENDING == false && this.allow_send ()== true)
        {
            this.on_send();
        }
    }

    /*****************************************************************/
    //
    // Popup
    //
    /*****************************************************************/

    /**
    * Called when the user clik outside the popup
    *
    */
    onNoClick(): void 
    {
        this.dialogRef.close();
    }


    /**
    * Phone number validator
    * 
    * @param {AbstractControl} Controller
    * @return {any} Reponse
    */
    public phoneNumberValidator(control: AbstractControl): { [key: string]: any } | null 
    {
        const valid = /^d+$/.test(control.value);
        return valid ? null : { invalidNumber: { valid: false, value: control.value } };
    }

    /**
    * Display Error
    *
    */
    public display_error()
    {
        this.forenameFormControl.markAsTouched({ onlySelf: true });
        this.lastnameFormControl.markAsTouched({ onlySelf: true });
        this.emailFormControl.markAsTouched({ onlySelf: true });
        this.phoneNumberFormControl.markAsTouched({ onlySelf: true });
        this.subjectFormControl.markAsTouched({ onlySelf: true });
        this.contentFormControl.markAsTouched({ onlySelf: true });
    }


    /**
    * Check if the fact to send a mail is allow or not
    *
    */
    public allow_send():boolean
    {
        let result:boolean = true;

        if( this.forename == "" || this.forenameFormControl.hasError('required') == true )
        {
            result = false;
        }

        if( this.lastname == "" || this.lastnameFormControl.hasError('required') == true )
        {
            result = false;
        }

        if( this.email == "" || this.emailFormControl.hasError('required') == true || this.emailFormControl.hasError('email') == true )
        {
            result = false;
        }

        if( this.phone_number == "" || this.phoneNumberFormControl.hasError('required') == true || this.phoneNumberFormControl.hasError('pattern') == true)
        {
            result = false;
        }

        if( this.subject == "" || this.subjectFormControl.hasError('required') == true )
        {
            result = false;
        }

        if( this.content == ""  || this.contentFormControl.hasError('required') == true )
        {
            result = false;
        }

        return result;
    }


    /**
    * Close the popup
    *
    */
    public on_send():void
    {
        if( this.forenameFormControl.hasError('required') == true )
        {
            this.forenameFormControl.updateValueAndValidity();
            this.display_error();
            return;
        }

        if( this.lastnameFormControl.hasError('required') == true )
        {
            this.lastnameFormControl.updateValueAndValidity();
            this.display_error();
            return;
        }

        if( this.emailFormControl.hasError('required') == true || this.emailFormControl.hasError('email') == true )
        {
            this.display_error();
            return;
        }

        if( this.phoneNumberFormControl.hasError('required') == true || this.phoneNumberFormControl.hasError('pattern') == true)
        {
            this.display_error();
            return;
        }

        if( this.subjectFormControl.hasError('required') == true )
        {
            this.display_error();
            return;
        }

        if( this.contentFormControl.hasError('required') == true )
        {
            this.display_error();
            return;
        }

        this.mail             = new Mail();
        this.mail.attachments = this.attachments;
        this.mail.mails       = ["support@kylii-kids.com"];
        this.mail.sender      = this.email;
        this.mail.subject     = this.subject;
        this.mail.body        = this.content;

        this.mail.body       += "<br><br>Contact : ";
        this.mail.body       += "<br>" + this.cookieService.get("KyliiMarket_User_customer") + " - " + this.forename + " " + this.lastname;
        this.mail.body       += "<br>" + this.email;
        this.mail.body       += "<br>" + this.phone_number;
    
        this.mail.body       += "<br><br>License(s) : ";

        for( let i:number = 0; i < this.licenses.length; i++ )
        {
            this.mail.body += "<br>";
            this.mail.body += this.licenses[i].label;
            this.mail.body += "<br><a href='https://kyliisaas.com/#/license/update/" + this.licenses[i].id + "'>Edit</a>";
            this.mail.body += "<br><a href='https://kyliisaas.com/#/license/command/" + this.licenses[i].id + "'>Commands</a>";
        }

        this.mailService.send_mail( this.mail)
        .then
        (
            res =>
            {
                if( res == true )
                {
                    this.dialogRef.close();
                }
                else
                {
                    this.openDialog( "Fail to send email", "Error", "close" );
                }
            }
        );
    }
}

