<td-layout #KyliiErpSidnav sidenavTitle="Kylii Market">

    <td-navigation-drawer flex sidenavTitle="Kylii Market" >
        <mat-toolbar color="primary">
            <a class="menu-button-user">{{user_name}}</a>
            <span flex></span>
            <mat-icon *ngIf="isUserLogged == true" class="menu-button-icon menu-button-home" td-menu-button tdLayoutToggle [routerLink]="this.appService.DEFAULT_PAGE">home</mat-icon>
            <mat-icon *ngIf="isUserLogged == true" class="menu-button-icon menu-button-logout" (click)="logout()">power_settings_new</mat-icon>
        </mat-toolbar>
        <mat-nav-list menu-items>
            <div mat-list-item  class="kylii-sub-menu">
                <mat-form-field color="accent">
                    <mat-select name="language" [(ngModel)]="language" (ngModelChange)="onChangeLanguage($event)" >
                        <mat-select-trigger class="language-select">
                            <img class="language-icon-select" src="{{language.icon}}" ><span class="language-label-select" >{{language.label}}</span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let language of languages" [value]="language">
                            <img class="language-icon" src="{{language.icon}}" >{{language.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="isUserLogged == true" mat-list-item  class="kylii-sub-menu kylii-sub-menu-support">
                <button mat-raised-button (click)="on_click_support()" i18n-matTooltip matTooltip="Contactez-nous" color="normal" i18n ><mat-icon >phone</mat-icon> Contactez-nous</button> 
            </div>
        </mat-nav-list>
    </td-navigation-drawer>

    <td-layout-nav toolbarTitle="Kylii Market" navigationRoute="/">

        <button *ngIf="this.appService.IS_MOBILE == true" mat-icon-button td-menu-button tdLayoutToggle>
            <mat-icon>menu</mat-icon>
        </button>

        <div td-toolbar-content layout="row" layout-align="start center" flex>
            <button *ngIf="isUserLogged == true && this.appService.IS_MOBILE == false" mat-icon-button (click)="goto_home()" i18n-matTooltip matTooltip="Accueil" matTooltipClass="header-button-tooltip" class="header-button-home"><mat-icon  >home</mat-icon></button>
            <button *ngIf="isUserLogged == true && this.appService.IS_MOBILE == false" mat-icon-button (click)="on_click_support()" i18n-matTooltip matTooltip="Contactez-nous" matTooltipClass="header-button-tooltip"><mat-icon  >phone</mat-icon></button>  
            <button *ngIf="isUserLogged == true && this.appService.IS_MOBILE == false" mat-icon-button (click)="logout()" i18n-matTooltip matTooltip="Déconnexion" matTooltipClass="header-button-tooltip"><mat-icon  >power_settings_new</mat-icon></button>  
            <span flex></span>
            
            <mat-form-field color="accent" *ngIf="this.appService.IS_MOBILE == false">
                <mat-select name="language" [(ngModel)]="language" (ngModelChange)="onChangeLanguage($event)" >
                    <mat-select-trigger class="language-select">
                        <img class="language-icon-select" src="{{language.icon}}" ><span class="language-label-select" >{{language.label}}</span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let language of languages" [value]="language">
                        <img class="language-icon" src="{{language.icon}}" >{{language.label}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            
        </div>
        
        <div  class="kylii-contents">
            <router-outlet></router-outlet> 
            <!--
            <div *ngIf="display_need_to_accept_cookies == true" class="popup-cookie" >
                <div i18n class="popup-cookie-texte">
                    En poursuivant votre navigation sur ce site, vous acceptez l'utilisation de Cookies pour sécuriser votre connexion.
                </div>
                <div class="popup-cookie-button">
                    Accepter
                </div>
            </div>
            -->
        </div>
        <td-layout-footer>
            <div layout="row" layout-align="start center">
                <span class="mat-caption">Copyright &copy; 2018 Kylii-Kids</span>
            </div>
        </td-layout-footer>
    </td-layout-nav>
</td-layout>