import { catchError, map } from 'rxjs/operators';

import { Injectable } 								from '@angular/core';
import { Observable } 								from 'rxjs';
import { Subject } 									from 'rxjs';
import { HttpClient, HttpHeaders } 					from '@angular/common/http';
import { Router }                					from '@angular/router';

import { AppService }       						from '../../../core/service/app.service';
import { CookieService }    						from '../../../core/service/cookie.service';
import { Language } 							    from '../vo/language';
import { Module } 							        from '../vo/module';

/**
 * Common Service
 * 
 * Use by the site for all common's datas
 */
@Injectable()
export class CommonService 
{
	/**
	 * Constructor
	 * 
	 */
	constructor (
		private http: HttpClient,
		private router: Router,
		private appService: AppService,  
		private cookieService: CookieService
		) {}

	
	/*****************************************************************/
    //
    // Get Width
    //
    /*****************************************************************/

    /**
     * Get the with of the browser to dynamically set width of Popup 
     * 
     * @return {number} Browser Width
     */
    public getWidth():number 
    {
        return Math.max
        (
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    }

	/*****************************************************************/
    //
    // Get Height
    //
    /*****************************************************************/

    /**
     * Get the height of the browser to dynamically set width of Popup 
     * 
     * @return {number} Browser Width
     */
    public getHeight():number 
    {
        return Math.max
        (
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.documentElement.clientHeight
        );
    }

	/*****************************************************************/
	//
	// Language List
	//
	/*****************************************************************/

	/**
	 * Get the list of Language
	 *
	 * @return {Language[]} Languages list
	 */
	public language_list( ):Promise<Language[]>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiSAAS/commons/Language/'; 

		return this.http
		    .get(url, {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__language_list(response)  )
		    .catch(this.handleError__language_list);
	}

	/**
	 * Response of language_list method
	 * 
	 * @param {Response} Response of the Web-Service
	 * @return {Language[]} Languages list
	 */
	private extractData__language_list(res: any) :Language[]
	{
		let body = res;

		let listLanguages: Language[] = new Array();

		for( let i:number = 0; i < body.length; i++ )
		{
			let language:Language 			= new Language();
			language.id 					= body[i].id;
			language.label 					= body[i].label;
			language.value 					= body[i].value;
			language.icon 					= body[i].icon;

			if( body[i].enable_for_market == true )
			{
				listLanguages.push( language );
			}
		}

		return listLanguages;
	}

	/**
	 * Catch error of language_list method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__language_list(error: any): Promise<any> 
	{
		console.error('An error occurred when call WS common/language/list', error); 
    	return Promise.reject(error.message || error);
	}



	/*****************************************************************/
	//
	// Module List
	//
	/*****************************************************************/

	/**
	 * Get the list of Module
	 *
	 * @return {Module[]} Modules list
	 */
	public module_list( ):Promise<Module[]>
	{
		let headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Token ' + this.appService.TOKEN});
		let url 	= this.appService.PATH_TO_KYLII_API + 'KyliiSAAS_License/module/'; 

		return this.http
		    .get(url, {headers: headers})
		    .toPromise()
		    .then(response  =>  this.extractData__module_list(response)  )
		    .catch(this.handleError__module_list);
	}

	/**
	 * Response of module_list method
	 * 
	 * @param {Response} Response of the Web-Service
	 * @return {Language[]} Modules list
	 */
	private extractData__module_list(res: any) :Module[]
	{
		let body = res;

		if( body.success == true )
		{
			let listModules: Module[] = new Array();

			for( let i:number = 0; i < body.result.length; i++ )
			{
				let module:Module = new Module();
				module.id 		= body.result[i].id;
				module.label 	= body.result[i].label;
				module.portage 	= body.result[i].portage;  
				listModules.push( module );
			}
			return listModules;
		}
		else
		{
			return new Array();
		}
	}

	/**
	 * Catch error of module_list method
	 * 
	 * @param {any} Error message
     * @return {any} Error message
	 */
	private handleError__module_list(error: any): Promise<any> 
	{
		console.error('An error occurred when call WS common/module/list', error); 
    	return Promise.reject(error.message || error);
	}
}
