import { Injectable } 	from '@angular/core';


/**
 * Cookied Service
 * 
 * Use to stock User connexion informations 
 */
@Injectable()
export class CookieService 
{
	/**
	 * Set a cookie variable
	 * 
	 * @param {string} Variable name
	 * @param {string} Variable value
	 */
	set(sName :string, sValue:string ) 
	{
		/*
	    var today = new Date(), expires = new Date();
	    expires.setTime(today.getTime() + (2*24*60*60*1000));
	    document.cookie = sName + "=" + encodeURIComponent(sValue) + ";expires=" + expires + ";path=/";
	    */
	    sessionStorage.setItem(sName, sValue);
	}

	/**
	 * Get a cookie variable
	 * 
	 * @param {string} Variable name
	 * @return {string} Variable value
	 */
	get(sName:string):string
	{
		/*
	    var cookContent = document.cookie;
	    let cookEnd:number;
	    let i:number;
	    let j:number;
	    let c:number;
	    var sName = sName + "=";

	    let result:string = "null";

	    for (i=0, c=cookContent.length; i<c; i++) 
	    {
	        j = i + sName.length;
	        if (cookContent.substring(i, j) == sName) 
	        {
	            cookEnd = cookContent.indexOf(";", j);
	            if (cookEnd == -1) 
	            {
	            	cookEnd = cookContent.length;
	            }
	            result = decodeURIComponent(cookContent.substring(j, cookEnd));
	        }
	    }       
	    return result;
	    */
	    return sessionStorage.getItem(sName);
	}


	/**
	 * Delete a cookie variable
	 * 
	 * @param {string} Variable name
	 */
	delete(sName :string ) 
	{
		sessionStorage.removeItem(sName);
	    //document.cookie = sName + "=" + "0" + ";expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
	}
}


