/**
 * User Value Object
 * 
 */
export class User 
{
	/**
	 * User id
	 */
	id:number;
	
	/**
	 * User forename
	 */
	forename:string;

	/**
	 * User lastname
	 */
	lastname:string;

	/**
	 * User mail
	 */
	mail:string;

	/**
	 * Customer
	 */
	customer:string;

	/**
	 * User token time
	 */
	token_time:string;

	/**
	 * User token
	 */
	token:string;

	/**
	 * User password
	 */
	password:string;

	/**
	 * User password date creation
	 */
	password_date_creation:Date;
}