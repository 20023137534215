/**
* Language Value Object
*
*/
export class Language
{
	/**
	* Language's id
	*/
	id:number;

	/**
	* Language's label
	*/
	label:string;

	/**
	* Language's value
	*/
	value:string;

	/**
	* Language's path to icon
	*/
	icon:string;
}