import { BrowserModule }                                        from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA }                     from '@angular/core';
import { FormsModule }                                          from '@angular/forms';
import { HttpClientModule }                                     from '@angular/common/http';
import { RouterModule }  										from '@angular/router';

import { BrowserAnimationsModule }                              from '@angular/platform-browser/animations';

import { AppComponent }                                         from './app.component';

import { DragDropModule }                                       from '@angular/cdk/drag-drop';
import { MatButtonModule }  									from '@angular/material';
import { MatCheckboxModule }  									from '@angular/material';
import { MatSelectModule } 										from '@angular/material';
import { MatSnackBarModule } 									from '@angular/material';
import { MatMenuModule }										from '@angular/material';
import { MatInputModule }										from '@angular/material';
import { MatIconModule } 										from '@angular/material';
import { MatDatepickerModule } 									from '@angular/material';
import { MatFormFieldModule } 									from '@angular/material';
import { MatRadioModule } 										from '@angular/material';
import { MatSidenavModule } 									from '@angular/material';
import { MatToolbarModule } 									from '@angular/material';
import { MatListModule } 										from '@angular/material';
import { MatGridListModule } 									from '@angular/material';
import { MatCardModule } 										from '@angular/material';
import { MatStepperModule } 									from '@angular/material';
import { MatTabsModule } 										from '@angular/material';
import { MatExpansionModule } 									from '@angular/material';
import { MatButtonToggleModule } 								from '@angular/material';
import { MatChipsModule } 										from '@angular/material';
import { MatProgressSpinnerModule } 							from '@angular/material';
import { MatProgressBarModule } 								from '@angular/material';
import { MatDialogModule } 										from '@angular/material';
import { MatTooltipModule } 									from '@angular/material';
import { MatTableModule } 										from '@angular/material';
import { MatSortModule } 										from '@angular/material';
import { MatPaginatorModule } 									from '@angular/material';
import { MatAutocompleteModule } 								from '@angular/material';
import { MatNativeDateModule } 									from '@angular/material';
import { MatSlideToggleModule }                                 from '@angular/material/slide-toggle';

import { ReactiveFormsModule } 									from '@angular/forms';

import { CovalentLayoutModule }            						from '@covalent/core';
import { CovalentCommonModule } 								from '@covalent/core';
import { CovalentStepsModule }            						from '@covalent/core';
import { CovalentDataTableModule } 								from '@covalent/core';
import { CovalentDialogsModule } 								from '@covalent/core';
import { CovalentExpansionPanelModule } 						from '@covalent/core'; 
import { CovalentPagingModule } 								from '@covalent/core';
import { CovalentNotificationsModule } 							from '@covalent/core';
import { CovalentSearchModule } 								from '@covalent/core';
import { CovalentMessageModule } 								from '@covalent/core';
import { CovalentJsonFormatterModule } 							from '@covalent/core';
import { CovalentVirtualScrollModule } 							from '@covalent/core';
import { CovalentFileModule } 									from '@covalent/core';
import { CovalentHttpModule }                                   from '@covalent/http';
import { CovalentHighlightModule }                              from '@covalent/highlight';
import { CovalentMarkdownModule }                               from '@covalent/markdown';
import { CovalentDynamicFormsModule }                           from '@covalent/dynamic-forms';
import { CovalentCodeEditorModule } 							from '@covalent/code-editor';

import { DeviceDetectorModule }                                 from 'ngx-device-detector';

import { AppRoutingModule }                                     from './core/routing/app.routing.module';
import { AppRoutingPreloadModule }                              from './core/routing/app.routing.preload.module';
import { SharedModule }                                     	from './core/shared/shared.module';
import { LoadingComponent }                                     from './commons/loading/component/loading.component';
import { LoadingService }                                       from './commons/loading/service/loading.service';
import { MailService }                                       	from './commons/mail/service/mail.service';
import { UploadService }                                        from './commons/upload/service/upload.service';

import { UserService }                                          from './modules/user/service/user.service';
import { AuthGuard }                                            from './modules/user/guard/index';
import { UserLoginComponent }                                   from './modules/user/component/login/login.component';
import { UserPopupChangePasswordComponent }                     from './modules/user/component/popup_change_password/user.popup.change.password.component';
import { UserPopupChangeMailComponent }                         from './modules/user/component/popup_change_mail/user.popup.change.mail.component';
import { UserPopupResetPasswordComponent }                      from './modules/user/component/popup_reset_password/user.popup.reset.password.component';

import { CommonService }                                        from './modules/common/service/common.service';

import { LicenseService }                                       from './modules/license/service/license.service';
import { LicenseListComponent }                                 from './modules/license/component/list/license.list.component';
import { LicenseUpdateComponent }                               from './modules/license/component/update/license.update.component';
import { LicensePopupSyncNowComponent }                         from './modules/license/component/popup/popup_sync_now/license.popup.sync.now.component';
import { LicensePopupConnexionInformationsComponent }           from './modules/license/component/popup/popup_connexion_informations/license.popup.connexion.informations.component';
import { LicensePopupAddApplicationComponent }                  from './modules/license/component/popup/popup_add_application/license.popup.add.application.component';
import { LicensePopupUnsuscribeKyliiProgramComponent }          from './modules/license/component/popup/popup_unsuscribe_kylii_program/license.popup.unsuscribe.kylii.program.component';
import { LicensePopupSupportComponent }                         from './modules/license/component/popup/popup_support/license.popup.support.component';
import { SoftwareService }                                      from './modules/software/service/software.service';

import { Pipe, PipeTransform }                                  from '@angular/core';
import { DomSanitizer }                                         from '@angular/platform-browser';


@Pipe({
    name: 'safe'
  })
  export class SafePipe implements PipeTransform {
  
    constructor(private sanitizer: DomSanitizer) { }
    transform(url) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  
  }

/**
 * Common and Global NgModule
 * 
 */
@NgModule({
    imports: 
    [
        // Angular
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        RouterModule,

        // Material
        DragDropModule,
        MatButtonModule, 
        MatCheckboxModule,
        MatSelectModule,
        MatSnackBarModule,
        MatMenuModule,
        MatInputModule,
        MatIconModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatRadioModule,
        MatSidenavModule,
        MatToolbarModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,
        MatStepperModule,
        MatTabsModule,
        MatExpansionModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatDialogModule,
        MatTooltipModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatAutocompleteModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        MatSlideToggleModule,

        // Convalent
        CovalentLayoutModule,
        CovalentCommonModule,
        CovalentStepsModule,
        CovalentHttpModule.forRoot(),
        CovalentHighlightModule,
        CovalentMarkdownModule,
        CovalentDynamicFormsModule,
        CovalentDataTableModule,
        CovalentDialogsModule,
        CovalentExpansionPanelModule,
        CovalentPagingModule,
        CovalentNotificationsModule,
        CovalentSearchModule,
        CovalentMessageModule,
        CovalentCodeEditorModule,
        CovalentJsonFormatterModule,
        CovalentVirtualScrollModule,
        CovalentFileModule,
        
        // Device Informations
        DeviceDetectorModule.forRoot(),

        // Routes 
        AppRoutingModule,
        SharedModule.forRoot(),
    ],
    declarations: 
    [
        SafePipe,
        // Core
        AppComponent,
        LoadingComponent,

        // User
        UserLoginComponent,
        UserPopupChangePasswordComponent,
        UserPopupChangeMailComponent,
        UserPopupResetPasswordComponent,

        // License
        LicenseListComponent,
        LicenseUpdateComponent,
        LicensePopupSyncNowComponent,
        LicensePopupConnexionInformationsComponent,
        LicensePopupAddApplicationComponent,
        LicensePopupSupportComponent,
        LicensePopupUnsuscribeKyliiProgramComponent
    ],
    providers: 
    [ 
        // Core
        AppRoutingPreloadModule,
        LoadingService,
        MailService,
        UploadService,

        // User 
        UserService,
        AuthGuard,

        // Common 
        CommonService,

        // License
        LicenseService,

        // Software
        SoftwareService
    ],
    entryComponents:
    [
    	// Loading
        LoadingComponent,

        // User 
        UserPopupChangePasswordComponent,
        UserPopupChangeMailComponent,
        UserPopupResetPasswordComponent,
        
        // License
        LicensePopupSyncNowComponent,
        LicensePopupConnexionInformationsComponent,
        LicensePopupAddApplicationComponent,
        LicensePopupSupportComponent,
        LicensePopupUnsuscribeKyliiProgramComponent
    ],
    schemas: 
    [ 
    	CUSTOM_ELEMENTS_SCHEMA 
    ],
    bootstrap: 
    [ 
        AppComponent 
    ]
})

/**
 * Export Common and Global NgModule
 * 
 */
export class AppModule { }
